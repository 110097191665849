import { Typography } from '@hapvida/hapvida-core-components';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';
import { Stack } from '@mui/material';
import styled from 'styled-components';

export const Matrix = styled(Typography)`
  && {
    padding: 2px 4px;
    background-color: ${COLORS.PRIMARY.BLUE.MAIN};
    border-radius: 2px;
    display: inline-block;
    font-weight: 700;
    font-size: 12px;
    color: white;
  }
`;

export const CompanyName = styled(Typography)`
  && {
    font-weight: 700;
    color: ${COLORS.MONOCHROMATIC.GRAY4};
    font-size: 14px;
  }
`;

export const DivIcon = styled(Stack)`
  && {
    padding: 12px;
    background: #0054b80a;
    border-radius: 4px;
  }
`;
