import React, { useEffect, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  DragAndDrop,
  StepCard,
  useConfirmation,
} from '@hapvida/hapvida-core-components';

import IDragAndDropFile from '@hapvida/hapvida-core-components/src/components/DragAndDrop/dtos/IDragDropFile';
import { ButtonDownload } from '@hapvida/hapvida-core-components/src/components/DragAndDrop/styles';

import { usePrompt } from '@hooks';

import info from './assets/info.svg';
import download from './assets/download.svg';
import spreadsheet from './assets/spreadsheet.svg';
import downloadIcon from './assets/download-icon.svg';
import { Layout, UploadDescription } from './styles';
import { useDownloadModelFiles, useSubmitMovimentation } from './hooks';
import { MovementListRouter } from '../../../MovementList/router';
import type { MovimentationFileLayoutProps } from './types';

export const MovimentationFileLayout: React.FC<
  MovimentationFileLayoutProps
> = ({ movimentationFile }) => {
  const navigate = useNavigate();
  const { showConfirmation } = useConfirmation();
  const { handleSubmitMovimentation } = useSubmitMovimentation();
  const { handleDownloadFiles } = useDownloadModelFiles();

  const [file, setFile] = useState<IDragAndDropFile>();

  usePrompt();

  useEffect(() => {
    if (movimentationFile) setFile(movimentationFile);
  }, []);

  /** REMINDER: Ajustar a tipagem da função onDelete */
  const onDelete = async () => {
    setFile(undefined);
  };

  const handleGoBack = () => {
    navigate(MovementListRouter.path);
  };

  const showConfirm = () => {
    showConfirmation({
      title: 'Você está prestes a processar sua movimentação',
      icon: 'alert',
      type: 'default',
      confirmText: 'Enviar movimentação',
      cancelText: 'Fechar',
      onConfirm: () => handleSubmitMovimentation(file),
      content: (
        <Typography variant="text">
          Ao confirmar você estará enviando todas as informações para
          processamento. O processamento pode levar alguns minutos, vamos te
          avisar quando tudo estiver pronto.
        </Typography>
      ),
    });
  };

  const showConfirmReturn = () => {
    showConfirmation({
      title: 'Atenção! Alterações feitas não serão salvas.',
      icon: 'alert',
      type: 'default',
      confirmText: 'Continuar',
      cancelText: 'Fechar',
      onConfirm: handleGoBack,
      content: (
        <Typography variant="text">
          Tem certeza que deseja voltar? Os arquivos enviados não serão
          mantidos.
        </Typography>
      ),
    });
  };

  return (
    <Layout>
      <Typography variant="body1" fontWeight={900}>
        Faça sua movimentação em 3 passos:
      </Typography>
      <DragAndDrop
        showHeader
        acceptedTypes={['txt']}
        maxSize={1024 ** 2 * 8}
        onDelete={onDelete}
        onChange={setFile}
        value={file}
        hideEditButton
      >
        <UploadDescription>
          <StepCard
            steps={[
              {
                id: 1,
                img: download,
                content: (
                  <Typography variant="smallText" fontWeight={600}>
                    <Typography
                      variant="smallText"
                      fontWeight={700}
                      color="primary"
                    >
                      1.
                    </Typography>{' '}
                    Baixe o arquivo, verifique os tipos de operação, e crie seu
                    arquivo de movimentação.
                  </Typography>
                ),
                alt: 'Download do arquivo',
              },
              {
                id: 2,
                img: spreadsheet,
                content: (
                  <Typography variant="smallText" fontWeight={600}>
                    <Typography
                      variant="smallText"
                      fontWeight={700}
                      color="primary"
                    >
                      2.
                    </Typography>{' '}
                    Faça o upload da sua planilha em formato <b>.txt</b> com
                    tamanho máximo do arquivo de <b>8MB.</b>
                  </Typography>
                ),
                alt: 'Planilha de exemplo',
              },
              {
                id: 3,
                img: info,
                content: (
                  <Typography variant="smallText" fontWeight={600}>
                    <Typography
                      variant="smallText"
                      fontWeight={700}
                      color="primary"
                    >
                      3.
                    </Typography>{' '}
                    Verifique as informações enviadas. Caso algo esteja errado,
                    vamos te avisar.
                  </Typography>
                ),
                alt: 'Próximo passo',
              },
            ]}
          />
          <Stack width="100%" mt={1}>
            <ButtonDownload
              onClick={handleDownloadFiles}
              endIcon={<img src={downloadIcon} alt={downloadIcon} />}
            >
              Baixar arquivos templates disponíveis
            </ButtonDownload>
          </Stack>
        </UploadDescription>
      </DragAndDrop>

      <Grid item xs={12} container justifyContent="space-between" mt={1}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={file ? showConfirmReturn : handleGoBack}
        >
          Voltar
        </Button>
        <Button
          disabled={!file}
          variant="contained"
          type="submit"
          color="success"
          onClick={showConfirm}
        >
          Enviar movimentação
        </Button>
      </Grid>
    </Layout>
  );
};
