import {
  GridProps,
  Paper,
  PaperProps,
  Stack,
  styled as muiStyled,
} from '@mui/material';
import { COLORS } from '../../themes/colors';

interface ItemContainerProps extends GridProps {
  alert: boolean | undefined;
}

interface ContainerProps extends PaperProps {
  alert: boolean | undefined;
}

export const ItemContainer = muiStyled(Stack, {
  shouldForwardProp: prop => prop !== 'alert',
})<ItemContainerProps>(({ alert }) => ({
  ...(alert && {
    background: COLORS.SECONDARY.RED['+1'],
    borderRadius: 8,
    paddingTop: 4,
    boxSizing: 'border-box',
  }),
  height: '100%',
}));

export const Container = muiStyled(Paper, {
  shouldForwardProp: prop => prop !== 'alert',
})<ContainerProps>(({ alert }) => ({
  background: alert
    ? 'linear-gradient(0deg, rgba(201, 54, 0, 0.02),rgba(201, 54, 0, 0.02)), #FFFFFF'
    : 'white',
  borderRadius: 8,
  boxShadow: 'none',
  border: `1px solid ${
    !alert ? COLORS.MONOCHROMATIC.GRAY3 : COLORS.SECONDARY.RED['+1']
  }`,
  display: 'flex',
  flexDirection: 'column',
  ...(!alert && {
    justifyContent: 'space-evenly',
  }),
  marginTop: alert ? 4 : 0,
  padding: 16,
  transition: 'all 1s',
  height: '100%',

  ':hover': {
    cursor: 'pointer',
    background:
      'linear-gradient(0deg, rgba(244, 158, 0, 0.04), rgba(244, 158, 0, 0.04)), #FFFFFF',
    borderColor: COLORS.PRIMARY.ORANGE.MAIN,
  },
}));
