import { styled as muiStyled } from '@mui/material/styles';

import TableRow from '@mui/material/TableRow';

import { COLORS } from '../../../../../../themes/colors';

interface MainRowProps {
  collapsed: string;
  ithaserror: string;
  ithaserrormessage: string;
  rowColor?: string;
}

export const MainRow = muiStyled(TableRow)<MainRowProps>(
  ({ ithaserror, ithaserrormessage, collapsed }) => ({
    '&.MuiTableRow-root': {
      background:
        ithaserror === 'true'
          ? 'linear-gradient(0deg, rgba(201, 54, 0, 0.04), rgba(201, 54, 0, 0.04)), #FFFFFF'
          : collapsed === 'true'
          ? 'linear-gradient(0deg, rgba(244, 158, 0, 0.04), rgba(244, 158, 0, 0.04)), #FFFFFF'
          : '',

      borderBottom:
        ithaserror === 'true' && ithaserrormessage === 'true'
          ? '2px solid transparent'
          : ithaserror === 'true' && ithaserrormessage === 'false'
          ? `2px solid ${COLORS.SECONDARY.RED['-1']}`
          : collapsed === 'true'
          ? `2px solid ${COLORS.PRIMARY.ORANGE.MAIN}`
          : `2px solid ${COLORS.MONOCHROMATIC.GRAY2}`,

      '&:hover': {

        background:
          ithaserror === 'false' &&
          'linear-gradient(0deg, rgba(244, 158, 0, 0.04), rgba(244, 158, 0, 0.04)), #FFFFFF',

        borderBottom:
          ithaserror === 'true' && ithaserrormessage === 'true'
            ? '2px solid transparent'
            : ithaserror === 'true' && ithaserrormessage === 'false'
            ? `2px solid ${COLORS.SECONDARY.RED['-1']}`
            : `2px solid ${COLORS.PRIMARY.ORANGE.MAIN}`,
      },
    },
  }),
);
