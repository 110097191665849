import { Box, Stack, styled as muiStyled } from '@mui/material';
import { COLORS } from '../../themes/colors';

import Icon from '../Icon';
import Typography from '../Typography';

export const MessageWrapper = muiStyled(Stack)(() => ({
  width: '76.8vw',
  height: '39px',

  background: 'rgba(255, 255, 255, 0.88)',
  boxShadow:
    '0px 16px 24px rgba(0, 0, 0, 0.06), inset 0px 2px 0px rgba(245, 182, 127, 0.4)',
  borderRadius: '8px',
  padding: '16px',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const IconWrapper = muiStyled(Box)(() => ({
  borderRight: '1px solid #DDE2E5',
  paddingRight: '12px',
}));

export const IconStyled = muiStyled(Icon)(({ name }) => ({
  color:
    name === 'check' ? COLORS.SECONDARY.GREEN.MAIN : COLORS.SECONDARY.RED['+1'],
  fontSize: '30px',
}));

export const AlertText = muiStyled(Typography)(() => ({
  color: '#495057',
  marginLeft: '16px',
  width: '100%',
}));

export const ClosedWrapper = muiStyled(Box)(() => ({
  paddingLeft: '12px',
}));

export const ClosedStyled = muiStyled(Icon)(() => ({
  color: '#495057',
  fontSize: '21.33px',
}));
