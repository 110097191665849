import { Stack, Typography } from '@mui/material';

import { NavigationCard } from '@hapvida/hapvida-core-components';

import { ImagesCardsNavigation } from './assets';
import { ReviewsScreenModal } from './components/ReviewsScreenModal';
import { MovementStatusModal } from './components/MovementStatusModal';
import { useReviewsScreen } from './hooks/useReviewsScreen';
import { NoReviewsScreenModal } from './components/NoReviewsScreenModal';
import { useModalStates } from './hooks/useModalStates';

export function MovementByScreenLayout() {
  const { getReviewsScreen, loadingState } = useReviewsScreen();
  const {
    movementStatusModalState,
    noReviewsScreenModalState,
    reviewsScreenModalState,
    toggleMovementStatusModal,
    toggleNoReviewsScreenModal,
    toggleReviewsScreenModal,
  } = useModalStates();

  const handleReviewsScreenCard = async () => {
    const hasReviews = await getReviewsScreen();

    if (!hasReviews) {
      toggleNoReviewsScreenModal();
      return;
    }

    toggleReviewsScreenModal();
  };

  return (
    <Stack gap={2} padding={8}>
      <NavigationCard
        enableImage={ImagesCardsNavigation.LayoutImage}
        enableImage2x={ImagesCardsNavigation.LayoutImage2x}
        disabledImage={ImagesCardsNavigation.DisabledLayoutImage}
        disabledImage2x={ImagesCardsNavigation.DisabledLayoutImage2x}
        fullWidth
        messageOne={
          <Typography variant="subtitle1">
            Acompanhar status das movimentações
          </Typography>
        }
        messageTwo={
          <Typography variant="text">
            Acompanhar movimentações já realizadas
          </Typography>
        }
        handlerAction={toggleMovementStatusModal}
        showArrow
      />
      <NavigationCard
        enableImage={ImagesCardsNavigation.IndividualImage}
        enableImage2x={ImagesCardsNavigation.IndividualImage2x}
        disabledImage={ImagesCardsNavigation.DisabledIndividualImage}
        disabledImage2x={ImagesCardsNavigation.DisabledIndividualImage2x}
        fullWidth
        messageOne={
          <Typography variant="subtitle1">
            Acompanhar críticas das movimentações
          </Typography>
        }
        messageTwo={
          <Typography variant="text">
            Verificar pendências da movimentações já realizadas
          </Typography>
        }
        handlerAction={handleReviewsScreenCard}
        showArrow
        loading={loadingState}
      />
      <NoReviewsScreenModal
        modalState={noReviewsScreenModalState}
        toggleModal={toggleNoReviewsScreenModal}
      />
      <ReviewsScreenModal
        modalState={reviewsScreenModalState}
        toggleModal={toggleReviewsScreenModal}
      />
      <MovementStatusModal
        modalState={movementStatusModalState}
        toggleModal={toggleMovementStatusModal}
      />
    </Stack>
  );
}
