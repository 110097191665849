import { BeneficiaryDetailRouter } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/router';
import { BeneficiaryListRouter } from '@flows/private/beneficiaries/pages/BeneficiaryList/router';
import { MovimentationStepRouter } from '@flows/private/movements/pages/MovimentationSteps/router';
import { MovementListRouter } from '@flows/private/movements/pages/MovementList/router';
import { UserDetailsRouter } from '@flows/private/users/pages/UserDetails/router';
import { CompanyDetailsRouter } from '@flows/private/companies/pages/CompanyDetails/router';
import { BillDetailRouter } from '@flows/private/financial/pages/Detail/router';
import { CompanyListRouter } from '@flows/private/companies/pages/CompanyList/router';
import { BillListRouter } from '@flows/private/financial/pages/List/router';
import { HomeRouter } from '@flows/private/home/router';
import { IAppRoute } from 'routes/types';
import { MovimentationDetailRouter } from '@flows/private/movements/pages/MovementDetails/router';
import { UserListRouter } from '@flows/private/users/pages/UserList/router';
import { CreateUserRouter } from '@flows/private/users/pages/CreateUser/router';
import { CompanySetupDetailsRouter } from '@flows/private/companySetup/pages/CompanySetupDetails/router';
import { CompanySetupListRouter } from '@flows/private/companySetup/pages/CompanySetupList/router';
import { BackofficeUserListRouter } from '@flows/private/backofficeUser/pages/BackofficeUserList/router';
import { BackofficeUserDetailsRouter } from '@flows/private/backofficeUser/pages/BackofficeUserDetails/router';
import { CreateBackofficeUserRouter } from '@flows/private/backofficeUser/pages/BackofficeUserCreation/router';
import { PartnerCompanyListRouter } from '@flows/private/partnerCompanies/pages/PartnerCompanyList/router';
import { LetterOfStayRouter } from '@flows/private/beneficiaries/pages/LetterOfStay/router';
import { MoreServicesRouter } from '@flows/private/moreServices/router';
import { PrintDiscountRouter } from '@flows/private/financial/pages/PrintDiscount/router';
import { MovementByScreenRouter } from '@flows/private/movements/pages/MovementByScreen/router';

export const privateRoutes: IAppRoute[] = [
  BackofficeUserDetailsRouter,
  MovimentationDetailRouter,
  CompanyDetailsRouter,
  CompanySetupDetailsRouter,
  MovimentationStepRouter,
  BeneficiaryDetailRouter,
  BeneficiaryListRouter,
  MoreServicesRouter,
  MovementListRouter,
  UserDetailsRouter,
  CompanySetupListRouter,
  CompanyListRouter,
  CreateUserRouter,
  BillDetailRouter,
  UserListRouter,
  BillListRouter,
  HomeRouter,
  BackofficeUserListRouter,
  CreateBackofficeUserRouter,
  PartnerCompanyListRouter,
  LetterOfStayRouter,
  PrintDiscountRouter,
  MovementByScreenRouter,
];
