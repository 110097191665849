import { useState } from 'react';

import DependentLine from './DependentLine';
import { RowsProps } from '../../types';
import { MainLine } from './MainLine';

export function Rows({
  item,
  TableHeadColumns,
  onEdit,
  onEditChild,
  hasCellAction,
  rowColor,
}: Readonly<RowsProps>) {
  const [open, setOpen] = useState(false);

  const toggleDependentLine = () => {
    setOpen(!open);
  };

  return (
    <>
      <MainLine
        item={item}
        collapsed={open}
        tableHeadColumns={TableHeadColumns}
        onCollapse={toggleDependentLine}
        onEdit={onEdit}
        hasCellAction={hasCellAction}
        rowColor={rowColor}
      />

      {item.dependents.length > 0 && (
        <>
          {open && (
            <DependentLine
              dependents={item.dependents}
              TableHeadColumns={TableHeadColumns}
              onEditChild={onEditChild}
            />
          )}
        </>
      )}
    </>
  );
}
