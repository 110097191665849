export const DATE_KEYS = {
  ALL: 0,
  LAST_WEEK: 1,
  LAST_MONTH: 2,
  CUSTOM: 3,
};

export const DATE_VALUES = {
  [DATE_KEYS.ALL]: {
    name: 'Todo período',
    value: null,
  },
  [DATE_KEYS.LAST_WEEK]: {
    name: 'Última semana',
    value: 8,
  },
  [DATE_KEYS.LAST_MONTH]: {
    name: 'Último mês',
    value: 31,
  },
  [DATE_KEYS.CUSTOM]: {
    name: 'Personalizado',
    value: null,
  },
};
