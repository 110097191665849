import { styled as muiStyled } from '@mui/material';
import TextInput from '../TextInput';

export const StyledTextArea = muiStyled(TextInput)(() => ({
  '.MuiFilledInput-root': {
    height: 'auto',

    '.MuiFilledInput-input': {
      maxHeight: '100px',
      overflow: 'visible !important',
    },
  },
}));
