import { styled as muiStyled, TableCell } from '@mui/material';

import { COLORS } from '../../../themes/colors';

export const StyledTableCell = muiStyled(TableCell)(() => ({
  borderBottom: `1px solid ${COLORS.MONOCHROMATIC.GRAY3}`,
  borderTop: `1px solid ${COLORS.MONOCHROMATIC.GRAY3}`,

  cursor: 'pointer',
}));
