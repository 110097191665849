import { Stack, Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import { textColorByBackground } from '../../utils/textColorByBackground';

interface StyledProps {
  color?: string;
  rounded?: string;
  fitcontent?: string;
}

export const StyledTypography = styled(Typography)<StyledProps>`
  ${({ color }) =>
    css`
      color: ${textColorByBackground(color)};
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
    `}
`;

export const StyledStack = styled(Stack)<StyledProps>`
  width: ${({ fitcontent }) =>
    fitcontent === 'true' ? 'fit-content' : 'unset'};

  ${({ color, rounded }) =>
    rounded === 'true'
      ? css`
          background-color: ${color};
          border-radius: 50%;
          width: 24px;
          height: 24px;
          text-align: center;
        `
      : css`
          background-color: ${color};
          padding: 4px 12px;
          max-height: 24px;
          border-radius: 20px;
          text-align: center;
        `}
`;
