import { Stack, Button, InputAdornment } from '@mui/material';

import { Icon } from '@hapvida/hapvida-core-components';

import type { SearchFieldLayoutProps } from './types';
import { StyledTextField } from './styles';

export function SearchFieldLayout({
  placeholder,
  onSubmit,
  register,
  formState,
  inputRef,
  handlePressEnter,
  handleClickMagnifier,
  handleClickClose,
  showCloseButton,
  disableSearch,
  disableGutters,
}: SearchFieldLayoutProps) {
  return (
    <Stack
      component="form"
      onSubmit={onSubmit}
      spacing={disableGutters ? 0 : 1}
      direction="row"
    >
      <StyledTextField
        {...register('search', {
          setValueAs: (value: string) => value.trim(),
        })}
        error={Boolean(formState.errors?.search?.message)}
        helperText={formState.errors?.search?.message}
        inputRef={inputRef}
        onKeyDown={handlePressEnter}
        placeholder={placeholder}
        fullWidth
        variant="outlined"
        disableGutters={disableGutters}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon
                onClick={handleClickMagnifier}
                name="magnifier"
                size={24}
                style={{ cursor: 'text' }}
              />
            </InputAdornment>
          ),
          endAdornment: showCloseButton ? (
            <InputAdornment position="end">
              <Icon onClick={handleClickClose} name="close" size={24} />
            </InputAdornment>
          ) : null,
        }}
      />
      <Button
        variant="contained"
        type="submit"
        color="secondary"
        disableRipple
        disabled={disableSearch}
      >
        Buscar
      </Button>
    </Stack>
  );
}
