import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { useDrawerFilter } from '@hapvida/hapvida-core-components';

import type {
  MovementListProps,
  MovementsSummaryProps,
} from '@services/MovimentationService';
import MovimentationService from '@services/MovimentationService';

import { toastifyApiErrors } from '@utils';
import { useFilterParams, useAuth } from '@hooks';

import {
  movementListFilterLabels,
  movementListFilterValues,
} from '../../constants/filterValues';
import type { MovementsStateContextData } from './types';

const MovementsStateContext = createContext<MovementsStateContextData>(
  {} as MovementsStateContextData,
);

function MovementsStateProvider({ children }: Readonly<PropsWithChildren<{}>>) {
  const { currentPage, searchParam, tableOrder, mappedFilters } =
    useFilterParams();
  const { getInitialFilter, setActiveFilters } = useDrawerFilter();
  const { enqueueSnackbar } = useSnackbar();
  const initialRender = useRef(true);
  const { selectedCompany, selectedPartnerCompany } = useAuth();

  const movementService = useMemo(() => new MovimentationService(), []);

  const companyId = selectedCompany?.id ?? '';
  const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';

  const isEnabled = Boolean(companyId) && Boolean(partnerCompany);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      const initialFinancialFilters = getInitialFilter({
        filtersConfig: movementListFilterValues,
        filtersLabel: movementListFilterLabels,
      });

      setActiveFilters(initialFinancialFilters);
    }
  }, []);

  const movementsState = useQuery<MovementListProps>({
    queryKey: [
      'movementsState',
      mappedFilters,
      searchParam,
      currentPage,
      tableOrder,
      companyId,
      partnerCompany,
    ],
    queryFn: () =>
      movementService.fetchMovements({
        filters: {
          ...mappedFilters,
          ...searchParam,
          ...tableOrder,
          offset: 10 * (currentPage - 1),
          limit: 10,
        },
        companyId,
      }),
    enabled: isEnabled,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const movementsSummaryState = useQuery<MovementsSummaryProps[]>({
    queryKey: [
      'movementsSummaryState',
      mappedFilters,
      searchParam,
      companyId,
      partnerCompany,
    ],
    queryFn: () =>
      movementService.fetchMovementsSummary({
        companyId,
        ...mappedFilters,
        ...searchParam,
      }),
    enabled: isEnabled,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshMovementsStates = () => {
    movementsSummaryState.remove();
    movementsState.remove();

    movementsSummaryState.refetch();
    movementsState.refetch();
  };

  const valueContextProvider = useMemo(
    () => ({
      refreshMovementsStates,
      movementsSummaryState,
      movementsState,
    }),
    [movementsSummaryState, refreshMovementsStates, movementsState],
  );

  return (
    <MovementsStateContext.Provider value={valueContextProvider}>
      {children}
    </MovementsStateContext.Provider>
  );
}

function useMovementsState() {
  const context = useContext(MovementsStateContext);

  if (!context) {
    throw new Error(
      'useMovementsState must be used within MovementsStateProvider',
    );
  }

  return context;
}

export { useMovementsState, MovementsStateProvider };
