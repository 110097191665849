import { styled as muiStyled } from '@mui/material';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';

import { COLORS } from '../../../themes/colors';

export const StyledTable = muiStyled(Table)(() => ({}));

export const StyledTableHead = muiStyled(TableHead)(() => ({
  borderBottom: `2px solid ${COLORS.MONOCHROMATIC.GRAY2}`,
}));

export const StyledTableRow = muiStyled(TableRow)(() => ({
  td: {
    border: 'none',
    cursor: 'default',
  },
}));
