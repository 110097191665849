import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMemo, useState, useEffect } from 'react';

import MovimentationService from '@services/MovimentationService';

import { base64ToDownloadFile, toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';
import { ReportStatusEnum } from '@flows/private/movements/pages/MovementDetails/components/MovementDetailsCard/constants/ReportStatusEnum';

export function useExportMovementFile(initialReportStatus?: ReportStatusEnum) {
  const { enqueueSnackbar } = useSnackbar();
  const { movementId = '' } = useParams();
  const { selectedCompany } = useAuth();
  const [isReadyToDownloadState, setIsReadyToDownloadState] = useState<boolean>(
    initialReportStatus === ReportStatusEnum?.PROCESSED,
  );
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const companyId = selectedCompany?.id ?? '';
  const movementService = useMemo(() => new MovimentationService(), []);

  const fetchMovementFile = async () => {
    try {
      return await movementService?.getExportedMovementFileStatus({
        movementId,
      });
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
      throw error;
    }
  };

  const {
    isFetching: isExportingMovement,
    refetch,
    data: movementExportFileStatus,
  } = useQuery(
    ['movementFileState', companyId, movementId],
    fetchMovementFile,
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: movementData => {
        if (movementData?.reportStatus === ReportStatusEnum?.PROCESSED) {
          setIsReadyToDownloadState(true);
        }
      },
      onSettled: settledData => {
        if (settledData?.reportStatus === ReportStatusEnum.PROCESSING) {
          setTimeout(() => {
            refetch();
          }, 5000);
        }
      },
      onError: error => {
        toastifyApiErrors(error, enqueueSnackbar);
      },
    },
  );

  useEffect(() => {
    const reportStatus = initialReportStatus;
    if (reportStatus === ReportStatusEnum?.PROCESSING) {
      refetch();
    }
  }, [initialReportStatus, refetch]);

  const handleExportMovementFile = async () => {
    try {
      setLoadingState(true);
      const movementFileResponse =
        await movementService?.getExportedMovementFile({
          movementId,
        });

      if (
        isReadyToDownloadState ||
        movementFileResponse?.reportStatus === ReportStatusEnum.PROCESSED
      ) {
        base64ToDownloadFile({
          base64String: movementFileResponse?.data,
          fileName: `${movementId}`,
          fileType: movementFileResponse?.mimeType,
        });

        setIsReadyToDownloadState(false);
      } else {
        await refetch();
      }
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    } finally {
      setLoadingState(false);
    }
  };

  const reportStatusState = movementExportFileStatus?.reportStatus;

  return {
    isExportingMovement:
      isExportingMovement || reportStatusState === ReportStatusEnum.PROCESSING,
    reportStatusState,
    isReadyToDownloadState,
    handleExportMovementFile,
    loadingState,
  };
}
