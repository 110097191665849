import { Stack } from '@mui/material';
import { matchPath, useLocation } from 'react-router-dom';

import { Sidebar as SidebarCore } from '@hapvida/hapvida-core-components';

import { MovimentationStepRouter } from '@flows/private/movements/pages/MovimentationSteps/router';
import {
  MOVIMENTATION_STEPS_KEYS,
  MOVIMENTATION_STEPS_ORDER,
} from 'constants/private/movements/newMovementSteps';

import { StepIcon } from '../ContractSelectionSteps/components';

export function MovementFlowSteps() {
  const location = useLocation();

  const currentPath = location.pathname;

  const currentStepIndex = MOVIMENTATION_STEPS_ORDER.findIndex(stepKey =>
    matchPath(
      MovimentationStepRouter.dynamicPath({ movimentationStep: stepKey }),
      currentPath,
    ),
  );

  const steps = [
    {
      path: MovimentationStepRouter.dynamicPath({
        movimentationStep: MOVIMENTATION_STEPS_KEYS.MOVIMENTATION_FILE,
      }),
      label: 'Envio do arquivo',
      stepNumber: 1,
      completed: currentStepIndex > 1,
    },
    {
      path: MovimentationStepRouter.dynamicPath({
        movimentationStep: MOVIMENTATION_STEPS_KEYS.CONFIRMATION,
      }),
      label: 'Conclusão',
      stepNumber: 2,
      completed: currentStepIndex > 2,
      disabled: true,
    },
  ];

  return (
    <Stack gap={1}>
      {steps.map(({ path, label, stepNumber, completed }) => {
        const isSelected = stepNumber - 1 === currentStepIndex;
        return (
          <SidebarCore.Item
            key={path}
            iconComponent={
              <StepIcon
                isSelected={isSelected}
                stepNumber={stepNumber}
                isCompleted={Boolean(completed)}
              />
            }
            isSelected={isSelected}
            label={label}
            noInteraction
          />
        );
      })}
    </Stack>
  );
}
