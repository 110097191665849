import { Tooltip, Typography } from '@mui/material';

import { Icon } from '@hapvida/hapvida-core-components';

import { useGetFile as getFile } from '@hooks';

import { IconDiv } from '../TableRows/styles';
import type { UploadNameProps } from './types';

export const UploadName = ({
  uploadName,
  fileName,
  selectedCompany,
}: Readonly<UploadNameProps>) => {
  if (!uploadName || !selectedCompany) {
    return null;
  }

  const name = fileName ?? uploadName;

  return (
    <Tooltip title={name} arrow>
      <IconDiv
        onClick={() => getFile(`companies/${selectedCompany?.id}`, uploadName)}
      >
        <Icon name="file-check" size={18} />
        <Typography>{name}</Typography>
      </IconDiv>
    </Tooltip>
  );
};
