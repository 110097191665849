import styled from 'styled-components';
import { Grid, Stack } from '@mui/material';

export const Container = styled(Grid)`
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Trigger = styled(Stack)`
  text-align: center;
  width: 40px;
  align-items: center;
  cursor: pointer;
`;
