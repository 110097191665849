import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Button,
  Divider,
  Stack,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { COLORS } from '../../themes/colors';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerOpenWidth?: number;
  drawerCloseWidth?: number;
  innerRef?: React.MutableRefObject<HTMLDivElement | null>;
}
export const AppBar = muiStyled(
  ({ drawerOpenWidth, drawerCloseWidth, innerRef, ...props }: AppBarProps) => (
    <MuiAppBar ref={innerRef} {...props} />
  ),
  {
    shouldForwardProp: prop => prop !== 'open',
  },
)<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1,
  background: COLORS.WHITE,
  padding: '16px 0',
  boxShadow: 'none',
}));

export const MenuButton = muiStyled(Button)(() => ({
  color: COLORS.MONOCHROMATIC.GRAY4,
  fontWeight: 400,
  fontSize: 14,
  '& .MuiButton-startIcon': {
    color: COLORS.PRIMARY.ORANGE.MAIN,
  },
}));

export const PageTitleContainer = muiStyled(Stack)(() => ({
  svg: {
    color: COLORS.PRIMARY.ORANGE.MAIN,
    fontSize: 32,
  },

  '.icon-chevron-right': {
    color: COLORS.MONOCHROMATIC.GRAY4,
  },

  height: '100%',
  alignItems: 'center',
}));

export const MobileDivider = muiStyled(Divider)(({ theme }) => ({
  width: 'calc(100% + 32px)',
  margin: '20px 0 16px -16px',
  backgroundColor: COLORS.MONOCHROMATIC.GRAY3,
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));
