import { Autocomplete, Stack, Paper, styled as muiStyled } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from '../../../themes/colors';
import Typography from '../../Typography';

interface LabelProps {
  required: boolean;
}

interface MessageProps {}

export const StyledAutoComplete = muiStyled(Autocomplete)(({ inputValue }) => ({
  '.MuiInputBase-root': {
    border: inputValue && `1px solid ${COLORS.PRIMARY.BLUE.MAIN}`,
  },
}));

export const OptionsContainer = styled(Paper)`
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid ${COLORS.PRIMARY.BLUE['-1']};
`;

export const PopperLabel = styled(Typography)`
  padding: 14px 14px 0px 14px;

  font-size: 14px;
`;

export const Option = styled(Stack)``;

export const StyledLabel = muiStyled(Typography)<LabelProps>(
  ({ required }) => ({
    '&.MuiTypography-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      color: COLORS.PRIMARY.BLUE['-1'],

      marginBottom: '0.5rem',

      '&::after': {
        content: `"${required ? ' *' : ''}"`,
        color: COLORS.PRIMARY.BLUE['-1'],
        fontSize: '1rem',
      },
    },
  }),
);

export const StyledMessage = muiStyled(Typography)<MessageProps>(() => ({
  '&.MuiTypography-root': {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '0.938rem',
    color: COLORS.MONOCHROMATIC.GRAY4,

    margin: '0.5rem 0 0.5rem 0',
  },
}));
