import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Sidebar as SidebarCore } from '@hapvida/hapvida-core-components';

import { useAuth } from '@hooks';
import { UserType } from '@contexts';
import { HomeRouter } from '@flows/private/home/router';

import { BackofficeManagement, SidebarContent } from './components';

export function Sidebar() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const isBackoffice = user?.companyType === UserType.Backoffice;

  const navigateToHome = () => {
    navigate(HomeRouter.path);
  };

  return (
    <SidebarCore.Root>
      <SidebarCore.Header
        onClickLogo={navigateToHome}
        isColorful={!isBackoffice}
      />
      <SidebarCore.Content flex={1} gap={5}>
        <Stack flex={1} gap={3}>
          <SidebarContent />
        </Stack>
        {isBackoffice && <BackofficeManagement />}
      </SidebarCore.Content>
    </SidebarCore.Root>
  );
}
