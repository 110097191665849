import React, { ReactNode } from 'react';

import { Controller } from 'react-hook-form';

import { Radio, Grid, RadioGroup, FormControlLabel } from '@mui/material';

import Typography from '../Typography';

interface RadioInputProps {
  options: Array<{
    key: string;
    value: string | number;
    element?: ReactNode;
    disabled?: boolean;
  }>;
  label?: string;
  onChange?(value: string): void;
  value?: string;
  name?: string;
  control: any;
  spacedOptions?: boolean;
  attentionMessage?: ReactNode;
  showAttentionMessage?: boolean;
  centerItens?: boolean;
  isColumn?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioInput: React.FC<RadioInputProps> = ({
  label,
  options,
  control,
  spacedOptions,
  name = 'Radio',
  attentionMessage,
  showAttentionMessage = false,
  centerItens = false,
  isColumn = false,
  handleChange,
}) => {
  return (
    <Grid container>
      {label && (
        <Grid item xs={12}>
          <Typography variant="text">{label}</Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, ...fieldProps } }) => {
            const beforeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value;

              handleChange?.(e);
              onChange(value);
            };

            return (
              <RadioGroup
                {...fieldProps}
                row
                onChange={beforeChange}
                sx={{
                  flexDirection: isColumn ? 'column' : 'row',
                  justifyContent: centerItens ? 'center' : 'flex-start',
                  gap: isColumn ? '24px' : '0',
                }}
              >
                {options.map(option => (
                  <Grid
                    key={option.key}
                    item
                    xs={12}
                    sm={isColumn ? undefined : 6}
                    md={isColumn ? undefined : 6}
                  >
                    <FormControlLabel
                      value={option.value}
                      label={option.element ?? option.key}
                      control={<Radio />}
                      disabled={option.disabled ?? false}
                    />
                  </Grid>
                ))}
              </RadioGroup>
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {showAttentionMessage && attentionMessage && attentionMessage}
      </Grid>
    </Grid>
  );
};

export default RadioInput;
