import { useMemo } from 'react';

import { StartPasswordRecoveryFields } from './components/StartPasswordRecoveryFields';
import { StartPasswordRecoverySteps } from './components/StartPasswordRecoverySteps';
import { useStartPasswordRecovery } from './hooks/useStartPasswordRecovery';
import { MainContent, LeftPanel, RightPanel } from './styles';
import { StartPasswordRecoveryStepsEnum } from './constants/steps';

export function StartPasswordRecoveryPage() {
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    handleStartPasswordRecovery,
    loadingState,
    successState,
  } = useStartPasswordRecovery();

  const currentStep = useMemo(() => {
    if (successState) {
      return StartPasswordRecoveryStepsEnum.SENDED_EMAIL;
    }

    if (isValid) {
      return StartPasswordRecoveryStepsEnum.SEND_EMAIL;
    }

    return StartPasswordRecoveryStepsEnum.TYPE_EMAIL;
  }, [successState, isValid]);

  return (
    <MainContent>
      <LeftPanel>
        <StartPasswordRecoverySteps currentStep={currentStep} />
      </LeftPanel>
      <RightPanel>
        <StartPasswordRecoveryFields
          control={control}
          errors={errors}
          handleStartPasswordRecovery={handleStartPasswordRecovery}
          handleSubmit={handleSubmit}
          loadingState={loadingState}
          successState={successState}
        />
      </RightPanel>
    </MainContent>
  );
}
