import { Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { StepLayout } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { toastifyApiErrors } from '@utils';

import { useScreenLoading } from '../../../../../hooks/useScreenLoading';
import AuthService from '../../../../../infra/services/AuthService';
import { steps } from './passwordRecoverySteps';

export function OldPassworRecoveryPage() {
  const currentStep = 0;

  const stepList = [
    {
      label: 'Recuperação de senha',
      completed: false,
      data: {},
    },
  ];

  const { onScreenLoading } = useScreenLoading();
  const { enqueueSnackbar } = useSnackbar();

  const handleSendRecovery = async (email: string) => {
    try {
      onScreenLoading(true);
      const authService = new AuthService();
      await authService.requestPasswordRecovery({ email });
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    } finally {
      onScreenLoading(false);
    }
  };

  return (
    <StepLayout
      currentStep={currentStep}
      onStepChange={step => step}
      steps={stepList}
      hasHeader={false}
      hasFirstStep={false}
      showDrawer
    >
      <Stack
        gap={1}
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <Typography variant="text">Já tem cadastro?</Typography>
        <a href={process.env.REACT_APP_LOGIN_URL}>
          <Typography variant="link" color={COLORS.PRIMARY.BLUE.MAIN}>
            Efetue o login
          </Typography>
        </a>
      </Stack>

      {steps[currentStep].step({ onSendRecovery: handleSendRecovery })}
    </StepLayout>
  );
}
