import { Stack } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from '../../themes/colors';

export const Container = styled(Stack)`
  && {
    background-color: transparent;
    border-radius: 8px;
    padding: 14px;

    .react-datepicker {
      width: 100%;
      border: 0;
      font-family: 'Source Sans Pro';
      background-color: transparent;

      .react-datepicker__day {
        border-radius: 50%;
        border: 1px solid transparent;
        margin: 1px;
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--keyboard-selected {
        color: ${COLORS.FONT_BLACK};
        background: none;
      }

      .react-datepicker__day--in-range {
        background-color: rgba(0, 84, 184, 0.04) !important;
        color: ${COLORS.MONOCHROMATIC.GRAY4} !important;
        border: 1px solid ${COLORS.PRIMARY.BLUE['-1']};
      }

      .react-datepicker__day--range-start,
      .react-datepicker__day--range-end {
        background-color: ${COLORS.PRIMARY.BLUE.MAIN} !important;
        color: ${COLORS.WHITE} !important;
        border: 0;
      }

      .react-datepicker__month-container {
        background-color: transparent !important;
        width: 49%;
        &:nth-last-child(2) {
          border-right: 1px solid rgba(0, 0, 0, 0.12);
        }
      }

      .react-datepicker__navigation *::before {
        border-color: ${COLORS.PRIMARY.BLUE['-1']} !important;
      }

      .react-datepicker__navigation:hover *::before {
        border-color: ${COLORS.PRIMARY.BLUE.MAIN} !important;
      }

      .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
      .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
      .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
      .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
        background-color: transparent !important;
        border: 1px dashed ${COLORS.PRIMARY.BLUE['-1']} !important;
        color: ${COLORS.FONT_BLACK};
        border-radius: 50%;
      }

      .react-datepicker__month {
        background: transparent;
        margin: 0;
      }

      .react-datepicker__current-month {
        color: ${COLORS.PRIMARY.BLUE.MAIN};
        font-weight: 900;
        text-transform: capitalize;
      }

      .react-datepicker__month-read-view--selected-month,
      .react-datepicker__month-read-view--selected-month {
        margin: 0 8px;
      }

      .react-datepicker__year-dropdown,
      .react-datepicker__month-dropdown {
        background: white;
      }

      .react-datepicker__day-name {
        margin: 2px;
        text-transform: capitalize;
      }

      .react-datepicker__day--outside-month {
        visibility: hidden;
      }

      .react-datepicker__header {
        border: 0;
        background-color: transparent;
      }
    }
  }
`;

export const Select = styled.select`
  && {
    padding: 8px;
    border: 0;
    width: 80px;
    color: ${COLORS.PRIMARY.BLUE.MAIN};
    font-weight: 900;
    cursor: pointer;
    background-color: transparent;
    outline: 0;
    font-size: 16px;
    align-self: center;
  }
`;
