import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';

import { UserService } from '@services/UserService';

import { toastifyApiErrors } from '@utils';
import { useBackofficeUserState } from '@flows/private/backofficeUser/pages/BackofficeUserList/hooks';

export function useReprocessUserBackoffice() {
  const [loadingState, setLoadingState] = useState(false);
  const { refreshBackofficeUserList } = useBackofficeUserState();
  const { enqueueSnackbar } = useSnackbar();

  const handleReprocessUser = useCallback(
    async (userId: string) => {
      try {
        setLoadingState(true);
        const userService = new UserService();
        await userService.reprocessUser(userId);

        await new Promise(resolve => {
          setTimeout(resolve, 1500);
        });

        return true;
      } catch (error) {
        toastifyApiErrors(error, enqueueSnackbar);
        return false;
      } finally {
        refreshBackofficeUserList();
        setLoadingState(false);
      }
    },
    [enqueueSnackbar],
  );

  return { loadingState, handleReprocessUser };
}
