import { SimpleFile } from '@hapvida/hapvida-core-components';

import type { DataGridItem } from '@hapvida/hapvida-core-components/src/components/DataGrid';

import type { BillDetails } from '@services/FinancialService';
import { UserRedirectsEnum } from '@services/UserService';

import { useRedirectUser } from '@hooks';

import { InvoiceButton, DiscountLink } from '../components';

export const getGridRows = (
  bill: BillDetails,
  reportFilesRows: DataGridItem[],
): DataGridItem[] => {
  const { redirectUser } = useRedirectUser();

  const handleRedirectBillLegacyPortal = () => {
    redirectUser(
      UserRedirectsEnum.BILLING,
      `?billing-number=${bill.billingNumber}`,
    );
  };

  return [
    {
      label: 'Boleto',
      xs: 3,
      value: (
        <SimpleFile
          onDownload={handleRedirectBillLegacyPortal}
          filename="Boleto"
        />
      ),
    },
    {
      label: 'Nota Fiscal',
      xs: 3,
      value: <InvoiceButton bill={bill} />,
    },
    {
      label: 'Carta de desconto',
      xs: 6,
      value: <DiscountLink />,
    },
    ...reportFilesRows,
  ];
};
