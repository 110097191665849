import { Box, TableCell, styled as muiStyled } from '@mui/material';

const Container = muiStyled(TableCell)(({}) => ({
  position: 'sticky',
  right: 0,
  padding: 0,
}));

const ActionBox = muiStyled(Box)(() => ({
  '&.MuiBox-root': {
    paddingRight: '20px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

export { ActionBox, Container };
