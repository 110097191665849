import Typography from '../../../../Typography';
import { TableCell, TableRow, Box, styled as muiStyled } from '@mui/material';
import { COLORS } from '../../../../../themes';

const ErrorRow = muiStyled(TableRow)(() => ({
  '&.MuiTableRow-root': {
    background:
      'linear-gradient(0deg, rgba(201, 54, 0, 0.04), rgba(201, 54, 0, 0.04)), #FFFFFF',

    borderBottom: `1px solid ${COLORS.SECONDARY.RED['-1']}`,
  },
}));

const TableCellNew = muiStyled(TableCell)(() => ({
  padding: 0,
  paddingBottom: '8px',
  borderBottom: 'none',
}));

const ErrorMessageBox = muiStyled(Box)(() => ({
  '&.MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',

    padding: '8px',
    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${COLORS.ERROR.MAIN}`,
  },
}));

const Message = muiStyled(Typography)(() => ({
  '&.MuiTypography-root': {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    textTransform: 'uppercase',
  },
}));

export { Message, ErrorMessageBox, TableCellNew, ErrorRow };
