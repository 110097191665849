import Typography from '@mui/material/Typography';

import { styled as muiStyled } from '@mui/material/styles';

import { COLORS } from '../../themes/colors';

export const Filename = muiStyled(Typography)({
  cursor: 'pointer',
  display: 'inlineBlock',

  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '0.75rem',
  lineHeight: '0.9375rem',

  color: COLORS.PRIMARY.BLUE.MAIN,
});
