import { SummaryCardNew } from '@hapvida/hapvida-core-components';

import { UserProfileEnum } from '@constants';
import { useAuth } from '@hooks';

import type { ProfileCardProps } from './types';
import { ProfileCardProvider } from './hooks';
import { ProfileSelection } from './components/ProfileSelection';
import { PartnerCompaniesSelection } from './components/PartnerCompaniesSelection';
import { SummarySelectedPartnerCompanies } from './components/SummarySelectedPartnerCompanies';
import { CantEditMasterAlert } from './components/CantEditMasterAlert';
import { RemoveProfileButton } from './components/RemoveProfileButton';

export function ProfileCard({
  profile,
  handleRemoveProfile,
  profileCardId,
}: Readonly<ProfileCardProps>) {
  const { selectedPartnerCompany } = useAuth();
  const { name } = selectedPartnerCompany?.profile ?? {};
  const isUserMaster = name === UserProfileEnum.MASTER;
  const isCardMaster = profile?.name === UserProfileEnum.MASTER;

  const isReadonly = isCardMaster && !isUserMaster;
  return (
    <ProfileCardProvider initialSelectedProfile={profile}>
      <SummaryCardNew.Container>
        <ProfileSelection isReadonly={isReadonly} />
        {!isReadonly && <PartnerCompaniesSelection />}
        <SummarySelectedPartnerCompanies />
        {isReadonly ? (
          <CantEditMasterAlert />
        ) : (
          <RemoveProfileButton
            handleRemoveProfile={handleRemoveProfile}
            profileCardId={profileCardId}
          />
        )}
      </SummaryCardNew.Container>
    </ProfileCardProvider>
  );
}
