import { Grid, Stack } from '@mui/material';
import React from 'react';

import Typography from '../Typography';
import { TooltipText, TooltipTitle, StyledHelperTooltip } from './styles';

interface FormGroupsProps {
  title?: string;
  tooltip?: React.ReactNode;
}

const FormGroup: React.FC<FormGroupsProps> = ({ children, title, tooltip }) => {
  return (
    <Stack gap={1}>
      <Grid gap={1} display="flex" alignItems="center">
        {title && <Typography variant="subtitle1">{title}</Typography>}
        {tooltip && (
          <StyledHelperTooltip>
            <TooltipTitle>{title}</TooltipTitle>
            <TooltipText>{tooltip}</TooltipText>
          </StyledHelperTooltip>
        )}
      </Grid>
      {children}
    </Stack>
  );
};

export default FormGroup;
