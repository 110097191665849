import styled from 'styled-components';
import ReactCrop from 'react-image-crop';

export const CanvasPreview = styled.canvas`
  width: 284px;
  height: 284px;
  border-radius: 800px;
`;

export const CropContainer = styled.div`
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 293px;
`;

export const CropImage = styled(ReactCrop)`
  max-width: 428px;
  max-height: 293px;
  margin: 0 auto;
`;
