import { HealthOperatorEnum } from '@constants';
import { CITY_HALL_LINK } from '@flows/private/financial/pages/Detail/components/BillDetailsCard/components/InvoiceButton/constants';

export const downloadInvoice = (
  healthOperator: HealthOperatorEnum,
  pathRedirect?: string,
) => {
  const isHapvidaOperator = healthOperator === HealthOperatorEnum.HAPVIDA;

  if (pathRedirect) {
    window.open(pathRedirect, '_blank');
  } else if (isHapvidaOperator) {
    window.open(CITY_HALL_LINK[healthOperator], '_blank');
  }
};
