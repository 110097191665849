import { DrawerFilterProvider } from '@hapvida/hapvida-core-components';

import { FinancialListSubHeader } from './components';
import { FinancialListLayout } from './layout';
import { BillsListStateProvider } from './hooks/useBillsState';

export function BillList() {
  return (
    <DrawerFilterProvider>
      <BillsListStateProvider>
        <FinancialListSubHeader />
        <FinancialListLayout />
      </BillsListStateProvider>
    </DrawerFilterProvider>
  );
}
