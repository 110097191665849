import { styled as muiStyled } from '@mui/material';
import { COLORS } from '../../themes/colors';
import Typography from '../Typography';
import HelperTooltip from '../HelperTooltip';

export const TooltipTitle = muiStyled(Typography)(() => ({
  color: COLORS.WHITE,
  fontSize: 14,
  fontWeight: 400,
}));

export const TooltipText = muiStyled(Typography)(() => ({
  color: COLORS.WHITE,
  fontSize: 12,
  fontWeight: 400,
}));

export const StyledHelperTooltip = muiStyled(HelperTooltip)(() => ({
  '&.MuiTooltip-popper': {
    width: 50,
  },
}));
