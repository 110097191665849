import { Stack, Typography } from '@mui/material';
import { memo } from 'react';

export const Empty = memo(() => (
  <Stack my={2} justifyContent="center" alignItems="center">
    <Typography variant="text">
      Não foram encontrados contratos para essa busca.
    </Typography>
  </Stack>
));
