import React from 'react';
import { Typography } from '@mui/material';
import Icon from '../Icon';

import { Container } from './styles';
import { QuotationStatusEnum, quotationEnumKeys } from '../../constants/enums';
interface QuotationStatusProps {
  status: QuotationStatusEnum;
  statusColor?: string;
  variant?: 'standart' | 'filled';
  statusStyle?: React.CSSProperties;
}

const QuotationStatusInline: React.FC<QuotationStatusProps> = ({
  status,
  variant = 'standart',
  statusColor,
  statusStyle,
}) => {
  return (
    <Container
      direction="row"
      alignItems="center"
      gap={1}
      statusColor={statusColor ? statusColor : quotationEnumKeys[status]?.color}
    >
      <Icon
        name={quotationEnumKeys[status]?.icon}
        size={24}
        variant={variant}
        color={statusColor ? statusColor : quotationEnumKeys[status]?.color}
      />
      <Typography sx={statusStyle}>
        {quotationEnumKeys[status]?.name}
      </Typography>
    </Container>
  );
};

export default QuotationStatusInline;
