import Typography from '@mui/material/Typography';

import { styled as muiStyled } from '@mui/material/styles';

export const Image = muiStyled('img')(() => ({
  maxWidth: '4.438rem',
  objectFit: 'contain',
  objectPosition: 'center',
}));

export const CompanyLabel = muiStyled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '0.875rem',
  lineHeight: '1.125rem',
  color: '#000000',
}));
