import { COLORS } from '@hapvida/hapvida-core-components';

import { FileData } from '@services/FinancialService';

enum ReportMonthlyMessageEnum {
  PROCESSING = 'Processing',
  ERROR = 'Error',
}

interface CarnetComplementRowProps {
  pdfFiles: FileData[];
  csvFiles: FileData[];
  txtFiles: FileData[];
  handleOpenFiles: (file?: FileData[]) => void;
}

const REPORT_MONTHLY_MESSAGE: Record<ReportMonthlyMessageEnum, string> = {
  [ReportMonthlyMessageEnum.PROCESSING]:
    'Pedido aceito e aguardando geração do relatório',
  [ReportMonthlyMessageEnum.ERROR]:
    'Não foi possível gerar o relatório. Remessa não encontrada.',
};

const REPORT_MONTHLY_TYPE: Record<ReportMonthlyMessageEnum, string> = {
  [ReportMonthlyMessageEnum.PROCESSING]: 'Success',
  [ReportMonthlyMessageEnum.ERROR]: 'ValidationError',
};

const REPORT_MONTHLY_MESSAGE_COLOR: Record<ReportMonthlyMessageEnum, string> = {
  [ReportMonthlyMessageEnum.ERROR]: COLORS.SECONDARY.RED.MAIN,
  [ReportMonthlyMessageEnum.PROCESSING]: COLORS.PRIMARY.ORANGE['+1'],
};

export {
  REPORT_MONTHLY_MESSAGE_COLOR,
  REPORT_MONTHLY_MESSAGE,
  REPORT_MONTHLY_TYPE,
  CarnetComplementRowProps,
  ReportMonthlyMessageEnum,
};
