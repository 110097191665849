import { CircularProgressProps as muiCircularProgressProps } from '@mui/material';
import { COLORS } from '../../themes/colors';
import { StyledCircularProgress } from './styles';

interface CircularProgressProps extends muiCircularProgressProps {
  progressColor?: string;
}

export const CircularProgress = ({
  progressColor = COLORS.MONOCHROMATIC.GRAY3,
  ...props
}: CircularProgressProps) => {
  return <StyledCircularProgress {...props} progressColor={progressColor} />;
};
