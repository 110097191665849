import {
  FilterKeyEnum,
  FiltersConfigProps,
} from '@hapvida/hapvida-core-components';

import {
  PartnerCompaniesTypeEnum,
  PARTNER_COMPANIES_TYPE_NAME,
} from '@constants';

import { PLAN_TYPE_NAME, PlanTypeEnum } from './contractTypeEnum';

export const partnerCompanyDrawerFilterValues: FiltersConfigProps = {
  [FilterKeyEnum.CONTRACT_TYPE]: Object.values(PartnerCompaniesTypeEnum).map(
    (partnerCompanyType: PartnerCompaniesTypeEnum) => {
      return {
        label: PARTNER_COMPANIES_TYPE_NAME[partnerCompanyType],
        value: partnerCompanyType,
      };
    },
  ),
  [FilterKeyEnum.PLAN_TYPE]: Object.values(PlanTypeEnum).map(
    (planTypeEnum: PlanTypeEnum) => {
      return {
        label: PLAN_TYPE_NAME[planTypeEnum],
        value: planTypeEnum,
      };
    },
  ),
};
