import type { TableHeadParams } from '@hapvida/hapvida-core-components/src/components/BaseTable/types';

enum BackofficeUserListColumnNameEnum {
  PERSONAL_DATA = 'personalData',
  COMPANY_TYPE = 'companyType',
  EMAIL = 'email',
  STATUS = 'status',
}

const backofficeUserListTableHeadColumns: TableHeadParams[] = [
  {
    id: 1,
    name: BackofficeUserListColumnNameEnum.PERSONAL_DATA,
    label: 'Nome e CPF',
    sortable: false,
  },
  {
    id: 2,
    name: BackofficeUserListColumnNameEnum.COMPANY_TYPE,
    label: 'Tipo de gestão',
    sortable: false,
  },
  {
    id: 3,
    name: BackofficeUserListColumnNameEnum.EMAIL,
    label: 'E-mail',
    sortable: false,
  },
  {
    id: 4,
    name: BackofficeUserListColumnNameEnum.STATUS,
    label: 'Status',
    sortable: false,
  },
];

export { backofficeUserListTableHeadColumns, BackofficeUserListColumnNameEnum };
