import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Grid } from '@mui/material';

import { StepLayout } from '@hapvida/hapvida-core-components';

import { HomeRouter } from '@flows/private/home/router';
import { toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';
import {
  MOVIMENTATION_STEPS_KEYS,
  MOVIMENTATION_STEPS_ORDER,
  MOVIMENTATION_STEPS_VALUES,
} from 'constants/private/movements/newMovementSteps';

import startMovimentationImage from './assets/background.png';
import { MovementListRouter } from '../MovementList/router';
import { steps } from './movimentationSteps';
import { MovimentationStepRouter } from './router';
import type { MovimentationData } from './types';

const StepMovimentationPage = () => {
  const routeParams = useParams<{ movimentationStep: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { logOut, user } = useAuth();
  const navigateTo = useNavigate();

  const [newMovementData, setNewMovementData] = useState<MovimentationData>();

  const [stepList, setStepList] = useState([
    {
      label: 'Envio do arquivo',
      completed: false,
    },
    {
      label: 'Conclusão',
      completed: false,
    },
  ]);

  const [currentStep, setCurrentStep] = useState(
    MOVIMENTATION_STEPS_VALUES[
      routeParams.movimentationStep ||
        MOVIMENTATION_STEPS_KEYS.MOVIMENTATION_FILE
    ],
  );

  useEffect(() => {
    if (routeParams.movimentationStep) {
      setCurrentStep(MOVIMENTATION_STEPS_VALUES[routeParams.movimentationStep]);
    }
  }, [routeParams]);

  const handleStepChange = useCallback(
    (step: number) => {
      if (step === 0) {
        return;
      }

      setCurrentStep(step);
      navigateTo(
        MovimentationStepRouter.dynamicPath({
          movimentationStep: MOVIMENTATION_STEPS_ORDER[step],
        }),
      );
    },
    [navigateTo],
  );

  const handleStepUpdate = useCallback(
    async (step: number, data: MovimentationData) => {
      try {
        const updatedStepList: any = [...stepList];

        updatedStepList[step] = {
          ...stepList[step],
          completed: true,
        };

        setNewMovementData(prev => ({ ...prev, ...data }));

        setCurrentStep(step + 1);
        setStepList(updatedStepList);
        navigateTo(
          MovimentationStepRouter.dynamicPath({
            movimentationStep: MOVIMENTATION_STEPS_ORDER[step + 1],
          }),
        );
      } catch (error) {
        toastifyApiErrors(error, enqueueSnackbar);
      }
    },
    [navigateTo, stepList, enqueueSnackbar],
  );

  return (
    <StepLayout
      user={user}
      steps={stepList}
      currentStep={currentStep}
      hasAnimation={currentStep === 0}
      startImage={startMovimentationImage}
      subRoute={MovementListRouter.name}
      sideBarTitle="Pronto para movimentar?"
      sideBarSubtitle="Faça agora toda a gestão de beneficiários do contrato."
      route={{
        icon: MovementListRouter.Icon,
        name: MovimentationStepRouter.name,
        navigateBackPath: MovementListRouter.path,
      }}
      onStepChange={(step: number) => handleStepChange(step)}
      onLogOut={() => logOut()}
      enableOnClickLogo
      customRedirectRoute={HomeRouter.path}
      showDrawer={false}
    >
      <Grid justifyContent="center" container>
        <Grid item xs={7}>
          {steps[currentStep].step({
            onStepUpdate: handleStepUpdate,
            movimentationFile: newMovementData?.file,
            companyId: newMovementData?.companyId,
          })}
        </Grid>
      </Grid>
    </StepLayout>
  );
};

export default StepMovimentationPage;
