import { Divider, Stack } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Loading } from '@hapvida/hapvida-core-components';

import { ANIMATION_PROPS } from '../../constants';
import { PartnerCompanyItem } from '../PartnerCompanyItem';
import type { PartnerCompaniesListProps } from './types';

export function PartnerCompaniesList({
  fetchNextPage,
  hasNextPage = false,
  isFetching = false,
  partnerCompanies = [],
  selectedProfile,
}: Readonly<PartnerCompaniesListProps>) {
  const showDivider = (index: number) => {
    return index < partnerCompanies.length - 1 || isFetching;
  };

  return (
    <InfiniteScroll
      scrollableTarget={selectedProfile.name}
      dataLength={partnerCompanies.length}
      hasMore={Boolean(hasNextPage)}
      next={fetchNextPage}
      loader={
        <Stack my={2} justifyContent="center" alignItems="center">
          <Loading animationProps={ANIMATION_PROPS} />
        </Stack>
      }
    >
      {partnerCompanies.map((item, index) => (
        <div key={item.partnerCompany}>
          <PartnerCompanyItem
            partnerCompany={item}
            selectedProfile={selectedProfile}
          />
          {showDivider(index) && <Divider />}
        </div>
      ))}
    </InfiniteScroll>
  );
}
