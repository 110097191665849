import { Box } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

import { COLORS } from '../../themes/colors';

export const QuestionMarkContainer = muiStyled(Box)(() => ({
  width: '16px',
  height: '16px',

  borderRadius: '50%',
  border: `1px solid ${COLORS.PRIMARY.BLUE.MAIN}`,

  fontSize: '12px',
  color: COLORS.PRIMARY.BLUE.MAIN,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
