import type { Base64ToDownloadFileProps } from './types';

export function base64ToDownloadFile({
  base64String,
  fileName,
  fileType,
}: Base64ToDownloadFileProps) {
  const decodedData = atob(base64String);

  const blob = new Blob([decodedData], { type: fileType });

  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);

  a.click();
  document.body.removeChild(a);

  window.URL.revokeObjectURL(url);
}
