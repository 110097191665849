import { COLORS } from '@hapvida/hapvida-core-components';

const BillStatusColors: Record<string, string> = {
  'em aberto': COLORS.PRIMARY.ORANGE.MAIN,
  'liquidado parcial': COLORS.PRIMARY.ORANGE.MAIN,
  liquidado: COLORS.SECONDARY.GREEN.MAIN,
  'saldo cancelado': COLORS.SECONDARY.RED.MAIN,
  protesto: COLORS.SECONDARY.RED.MAIN,
  'liquidado protesto': COLORS.SECONDARY.RED.MAIN,
  'liquid parc/protesto': COLORS.SECONDARY.RED.MAIN,
  cancelado: COLORS.SECONDARY.RED.MAIN,
  'liquidacao informada': COLORS.SECONDARY.GREEN.MAIN,
  'enviado cobranca': COLORS.PRIMARY.ORANGE.MAIN,
  'solic baixa cobr': COLORS.PRIMARY.ORANGE.MAIN,
  'recusado cobranca': COLORS.SECONDARY.RED.MAIN,
  'em aberto/cobranca': COLORS.PRIMARY.ORANGE.MAIN,
  'protesto p/cob': COLORS.SECONDARY.RED.MAIN,
  'liquidado n/cob': COLORS.SECONDARY.GREEN.MAIN,
  'baixa pedido': COLORS.SECONDARY.RED.MAIN,
  'baixa parcial pedido': COLORS.SECONDARY.RED.MAIN,
  'pedido cancelado': COLORS.SECONDARY.RED.MAIN,
  'deposito em conta': COLORS.PRIMARY.ORANGE.MAIN,
  'baixado para perda': COLORS.PRIMARY.ORANGE.MAIN,
  'liquidado/perda': COLORS.SECONDARY.GREEN.MAIN,
  'liq/perda c/desconto': COLORS.SECONDARY.GREEN.MAIN,
};

export { BillStatusColors };
