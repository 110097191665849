import { styled as muiStyled } from '@mui/material';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Button from '../Button';

import { COLORS } from '../../themes/colors';

interface StyledDrawerProps {
  anchor: 'top' | 'left' | 'bottom' | 'right';
}

export const StyledDrawer = muiStyled(Drawer)<StyledDrawerProps>(
  ({ anchor }) => ({
    // borderTopRightLeft: anchor === 'right' ? 30 : '',
    '& .MuiDrawer-paper': {
      borderTopLeftRadius: anchor === 'right' ? 16 : '',
      borderTopRightRadius: anchor === 'left' ? 16 : '',
    },
  }),
);

export const DrawerHeader = muiStyled(Box)<StyledDrawerProps>(({ anchor }) => ({
  padding: '16px',
  height: '30px',

  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  flexDirection: anchor === 'right' ? 'row' : 'row-reverse',
  justifyContent: anchor === 'right' ? 'flex-start' : 'space-between',

  boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.16)',
  backgroundColor: COLORS.WHITE,
}));

export const StyledButton = muiStyled(Button)(() => ({
  padding: 0,
  margin: 0,
  minWidth: '35px',
  width: '35px',
  height: '30px',
}));

export const Title = muiStyled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: '16px',
  lineHeight: '20px',
}));
