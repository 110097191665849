import { COLORS } from '@hapvida/hapvida-core-components';

import {
  PARTNER_COMPANIES_TYPE_NAME,
  PartnerCompaniesTypeEnum,
} from '@constants';

import { StyledChip } from './styles';
import type { PartnerCompanyTypeChipProps } from './types';

export function PartnerCompanyTypeChip({
  isMainPartnerCompany,
  text,
  title,
  fontSize,
  size = 'medium',
}: Readonly<PartnerCompanyTypeChipProps>) {
  const label = isMainPartnerCompany
    ? PARTNER_COMPANIES_TYPE_NAME[PartnerCompaniesTypeEnum.MOTHER]
    : PARTNER_COMPANIES_TYPE_NAME[PartnerCompaniesTypeEnum.CHILD];

  const bgColor = isMainPartnerCompany
    ? COLORS.SECONDARY.YELLOW.MAIN
    : COLORS.SECONDARY.BLUE['+1'];

  const textColor = isMainPartnerCompany
    ? COLORS.MONOCHROMATIC.GRAY4
    : COLORS.WHITE;
  return (
    <StyledChip
      textColor={textColor}
      bgColor={bgColor}
      label={text ?? label}
      title={title}
      fontSize={fontSize}
      size={size}
    />
  );
}
