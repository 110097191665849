import { Typography } from '@mui/material';

import { COLORS, Icon } from '@hapvida/hapvida-core-components';

import { StyledBox, StyledIcon } from './styles';
import type { StepIconProps } from './types';

export function StepIcon({
  isSelected,
  isCompleted,
  stepNumber,
}: Readonly<StepIconProps>) {
  if (isCompleted) {
    return (
      <StyledBox>
        <Icon name="check" size={24} />
      </StyledBox>
    );
  }

  return (
    <StyledIcon active={isSelected}>
      <Typography color={COLORS.WHITE}>{stepNumber}</Typography>
    </StyledIcon>
  );
}
