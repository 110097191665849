import { FC, useState } from 'react';

import { DialogProps } from '@mui/material/Dialog';

import {
  ModalWrapper,
  StyledBadge,
  StyledAvatar,
  PropLabel,
  PropValue,
  Title,
} from './styles';
import { Box, Stack } from '@mui/material';
import Icon from '../Icon';
import DragAndDrop from '../DragAndDrop';
import IDragAndDropFile from '../DragAndDrop/dtos/IDragDropFile';
import { IconName } from '../Icon/types';
import { formatBytes } from '../../utils/formatBytes';

interface ModalProps extends DialogProps {
  type?: 'default' | 'alert' | 'success' | 'gray';
  icon?: IconName;
  open: boolean;
  mainButtonText?: string;
  secondaryButtonText?: string;
  mainAction?: () => void;
  handleCloseModal: () => void;
  maxSize?: number;
  acceptedTypes?: string | string[];
}

const AvatarPicker: FC<ModalProps> = ({
  icon,
  open,
  mainButtonText,
  secondaryButtonText,
  handleCloseModal,
  mainAction,
  children,
  type = 'default',
  maxSize = 8 * 1.049e6,
  acceptedTypes = ['jpg', 'png'],
  ...rest
}) => {
  const [image, setImage] = useState<IDragAndDropFile>();

  return (
    <ModalWrapper
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      {...rest}
    >
      <Title id="dialog-title">Imagem de perfil</Title>

      <Stack gap={3}>
        <Stack alignItems="center" justifyContent="center">
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={<Icon name="camera" size={12} color="white" />}
          >
            {image ? (
              <StyledAvatar alt="logo" src={image.path} variant="rounded" />
            ) : (
              <StyledAvatar alt="logo" variant="rounded">
                <Icon name={icon ?? 'building'} size={32} />
              </StyledAvatar>
            )}
          </StyledBadge>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={4}>
        <Stack spacing={1}>
          <PropLabel>Formato</PropLabel>
          <PropValue>
            {Array.from(acceptedTypes).join(', ').toLocaleUpperCase()}
          </PropValue>
        </Stack>
        <Stack spacing={1}>
          <PropLabel>Tamanho</PropLabel>
          <PropValue>{formatBytes(maxSize)}</PropValue>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: '-16px' }}>
        <DragAndDrop
          value={image}
          onChange={file => {
            setImage(file);
            handleCloseModal();
          }}
          acceptedTypes={acceptedTypes}
          maxSize={maxSize}
          title=""
          isAvatar
        />
      </Box>
    </ModalWrapper>
  );
};

export default AvatarPicker;
