import { DrawerFilterProvider } from '@hapvida/hapvida-core-components';

import { MovementsStateProvider } from './hooks';
import { MovementListLayout } from './layout';
import { MovementsSubHeader } from './components/MovementsSubHeader';

export function MovementListPage() {
  return (
    <DrawerFilterProvider>
      <MovementsStateProvider>
        <MovementsSubHeader />
        <MovementListLayout />
      </MovementsStateProvider>
    </DrawerFilterProvider>
  );
}
