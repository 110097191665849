import { BaseProfileResourceEnum, ExtraProfileResourceEnum } from '@constants';
import { BeneficiaryGroupRouter } from '@flows/private/beneficiaries/router';
import { CompanyListRouter } from '@flows/private/companies/pages/CompanyList/router';
import { FinancialGroupRouter } from '@flows/private/financial/router';
import { HomeRouter } from '@flows/private/home/router';
import { MoreServicesRouter } from '@flows/private/moreServices/router';
import { MovementGroupRouter } from '@flows/private/movements/router';
import { UserListRouter } from '@flows/private/users/pages/UserList/router';
import { IAppGroupRoute } from 'routes/types';

export const MENU_LIST: Array<IAppGroupRoute> = [
  {
    id: 1,
    route: HomeRouter,
    profileResourceEnum: ExtraProfileResourceEnum.HOME,
  },
  {
    id: 2,
    route: BeneficiaryGroupRouter,
    profileResourceEnum: BaseProfileResourceEnum.BENEFICIARIES,
  },
  {
    id: 3,
    route: FinancialGroupRouter,
    profileResourceEnum: BaseProfileResourceEnum.FINANCIAL,
  },
  {
    id: 4,
    route: MovementGroupRouter,
    profileResourceEnum: BaseProfileResourceEnum.MOVEMENT,
  },
  {
    id: 5,
    route: UserListRouter,
    profileResourceEnum: BaseProfileResourceEnum.USERS,
  },
  {
    id: 6,
    route: CompanyListRouter,
    profileResourceEnum: BaseProfileResourceEnum.COMPANIES,
  },
  {
    id: 7,
    route: MoreServicesRouter,
    profileResourceEnum: BaseProfileResourceEnum.MORE_SERVICES,
  },
];
