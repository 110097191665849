import { styled as muiStyled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';

interface Props extends ButtonProps {}

export const MuiButton = muiStyled(({ ...props }: Props) => (
  <Button {...props} />
))<Props>(({ fullWidth }) => ({
  width: fullWidth ? '100%' : 'fit-content',
}));
