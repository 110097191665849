import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { useMemo } from 'react';

import MovimentationService, {
  MovimentationDetailRequest,
} from '@services/MovimentationService';

import { MovementStatusEnum } from '@constants';
import { toastifyApiErrors } from '@utils';
import { useFilterParams, useAuth } from '@hooks';

export function useMovementDetailsState() {
  const { currentPage, searchParam, tableOrder, mappedFilters } =
    useFilterParams();
  const { enqueueSnackbar } = useSnackbar();
  const { movementId = '' } = useParams();
  const { selectedCompany, selectedPartnerCompany } = useAuth();

  const movementService = useMemo(() => new MovimentationService(), []);

  const companyId = selectedCompany?.id ?? '';
  const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';

  const movementInfoState = useQuery<MovimentationDetailRequest, AxiosError>({
    queryKey: ['movementInfoState', movementId],
    queryFn: () => movementService.fetchMovimentationById(movementId),
    enabled: Boolean(movementId),
    cacheTime: 0,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const isMovementSummaryStateEnabled =
    Boolean(companyId) &&
    Boolean(movementId) &&
    movementInfoState.isSuccess &&
    Boolean(movementInfoState.data.content.summaryLives?.totalLives);

  const movementSummaryState = useQuery({
    queryKey: ['movementSummaryState', movementId, partnerCompany],
    queryFn: () =>
      movementService.fetchSummaryCardInfo({
        movementId,
        companyId,
      }),
    enabled: isMovementSummaryStateEnabled,
    cacheTime: 0,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const isMovementLivesStateEnabled =
    Boolean(companyId) &&
    Boolean(movementId) &&
    movementInfoState.isSuccess &&
    movementInfoState.data?.content.movementDetails.status !==
      MovementStatusEnum.UNABLE_TO_START;

  const movementLivesState = useQuery({
    queryKey: [
      'movementLivesState',
      mappedFilters,
      currentPage,
      searchParam,
      movementId,
      tableOrder,
    ],
    queryFn: () =>
      movementService.getMovementLives({
        filters: {
          offset: 10 * (currentPage - 1),
          limit: 10,
          ...mappedFilters,
          ...searchParam,
          ...tableOrder,
        },
        movementId,
        companyId,
      }),
    enabled: isMovementLivesStateEnabled,
    cacheTime: 0,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshMovementDetailsStates = () => {
    movementSummaryState.remove();
    movementLivesState.remove();
    movementInfoState.remove();

    if (isMovementSummaryStateEnabled) movementSummaryState.refetch();
    if (isMovementLivesStateEnabled) movementLivesState.refetch();
    movementInfoState.refetch();
  };

  return {
    refreshMovementDetailsStates,
    movementSummaryState,
    movementLivesState,
    movementInfoState,
  };
}
