import { Stack, Typography } from '@mui/material';
import { memo } from 'react';

import { COLORS } from '@hapvida/hapvida-core-components';

import { useProfileCard } from 'components/ProfileSelectionCard/components/ProfileCardList/components/ProfileCard/hooks/useProfileCard';

export const Subtitle = memo(() => {
  const { currentSelectedPartnerCompanies } = useProfileCard();

  return (
    <Stack flexDirection="row" gap="4px" mt={0.5}>
      <Typography
        variant="smallText"
        color={COLORS.PRIMARY.ORANGE.MAIN}
        fontWeight={700}
      >
        {currentSelectedPartnerCompanies.length}
      </Typography>
      <Typography variant="smallText" fontWeight={700}>
        contratos selecionados
      </Typography>
    </Stack>
  );
});
