import { Stack, styled } from '@mui/material';

export const Container = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 0.9,
  flexDirection: 'row',
  padding: '0px 32px',
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    padding: '0px 64px',
  },
  [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
    padding: '0px 116px',
  },
}));
