import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { styled as muiStyled } from '@mui/material/styles';

import { COLORS } from '../../themes/colors';

export const Wrapper = muiStyled(Stack)({
  cursor: 'pointer',
  padding: '1.5rem',

  borderRadius: '8px',
  border: `1px solid ${COLORS.MONOCHROMATIC.GRAY1}`,

  '&:hover': {
    borderColor: COLORS.PRIMARY.ORANGE.MAIN,
  },
});

export const IconContainer = muiStyled(Box)({
  width: '2.5rem',
  height: '2.5rem',

  borderRadius: '8px',

  background: 'rgba(244, 159, 0, 0.175)',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Text = muiStyled(Typography)({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.125rem',

  color: COLORS.MONOCHROMATIC.GRAY4,
});
