import { FilterKeyEnum, DrawerFilter } from '@hapvida/hapvida-core-components';

import { PlanTypeEnum } from '@flows/private/partnerCompanies/pages/PartnerCompanyList/constants/contractTypeEnum';

import { mappedPlanType } from './components/mappedPlanTypes';

export function PlanTypeDrawerFilter() {
  const partnerList = Object.values(PlanTypeEnum);
  const typeOfPartnerCompaniesFilters = partnerList.map(mappedPlanType);

  return (
    <DrawerFilter
      filterKey={FilterKeyEnum.PLAN_TYPE}
      filters={typeOfPartnerCompaniesFilters}
      title="Tipo de plano"
      label="tipos de planos"
      disabledSelectAll
    />
  );
}
