import { Stack } from '@mui/material';
import { CalendarPickerView } from '@mui/x-date-pickers';
import styled from '@mui/material/styles/styled';
import { COLORS } from '../../themes/colors';

export const StyledDate = styled(Stack)`
  flex-direction: row;
  padding: 12px 8px;
  border: 1px solid ${COLORS.PRIMARY.BLUE['-1']};
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
`;

export const StyledStack = styled(Stack, {
  shouldForwardProp: prop => prop !== 'view',
})<{ view: CalendarPickerView }>(
  ({ view }) => `
  && {
    border: 1px solid ${COLORS.PRIMARY.BLUE['-1']};
    border-top: 0;
    border-radius: 0px 0px 4px 4px;

    .MuiPickersToolbar-root {
      display: none;
    }

    .MuiPickersArrowSwitcher-root {
      display: none;
    }

    .MuiPickerStaticWrapper-root {
      width: 90% !important;
      display: flex;
      justify-content: center;
      align-self: center;

      ${
        view !== 'day' &&
        `div:last-child {
        width: 100% !important;
      }`
      }
    }
    .PrivatePickersToolbar-root {
      display: none;
    }

    .PrivatePickersFadeTransitionGroup-root {
      text-transform: capitalize;
      color: ${COLORS.PRIMARY.BLUE.MAIN};
      font-weight: 900;
      overflow-x: hidden;
      width: 100%;
    }

    .MuiCalendarPicker-root {
      background-color: ${COLORS.WHITE};
      width: auto;

      div[role='presentation'] {
        overflow: unset;
        div {
          width: auto;
        }
      }
    }

    .MuiSvgIcon-root {
      color: ${COLORS.PRIMARY.BLUE.MAIN};
    }

    .MuiYearPicker-root,
    .MuiMonthPicker-root {
      margin: 0;
    }

    .PrivatePickersMonth-root,
    .PrivatePickersYear-yearButton {
      border-radius: 4px;
      text-transform: capitalize;
      width: 120px;

      &.Mui-selected {
        background-color: ${COLORS.PRIMARY.BLUE.MAIN};
      }
    }

    .MuiYearPicker-root {      
      .PrivatePickersYear-root {
        flex-basis: 28%;

        button { 
          margin: 4px 0;
        }
      }

      margin-bottom: 1rem;
      justify-content: center;
    }

    .MuiMonthPicker-root {
      button {
        margin: 4px;
        flex: 1 0 30%;
      }

      margin-bottom: 1rem;
      justify-content: center;
    }
  }
`,
);
