import LayoutIcon from './layout.png';
import LayoutIcon2x from './layout@2x.png';
import DisabledLayoutIcon from './disabledLayout.png';
import DisabledLayoutIcon2x from './disabledLayout@2x.png';
import IndividualIcon from './individual.png';
import IndividualIcon2x from './individual@2x.png';
import DisabledIndividualIcon from './disabledIndividual.png';
import DisabledIndividualIcon2x from './disabledIndividual@2x.png';

export const ImagesCardsNavigation = {
  LayoutImage: LayoutIcon,
  LayoutImage2x: LayoutIcon2x,
  DisabledLayoutImage: DisabledLayoutIcon,
  DisabledLayoutImage2x: DisabledLayoutIcon2x,
  IndividualImage: IndividualIcon,
  IndividualImage2x: IndividualIcon2x,
  DisabledIndividualImage: DisabledIndividualIcon,
  DisabledIndividualImage2x: DisabledIndividualIcon2x,
};
