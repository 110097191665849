import type { IconName } from '@hapvida/hapvida-core-components/src/components/Icon/types';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { BeneficiaryMovementTypeEnum } from '@constants';

export const MovementTypeValues: Record<
  BeneficiaryMovementTypeEnum,
  {
    icon: IconName;
    value: string;
    color: string;
  }
> = {
  HolderInclusion: {
    icon: 'account-plus',
    value: 'Inclusão de titular',
    color: COLORS.SECONDARY.GREEN['+1'],
  },
  DependentInclusion: {
    icon: 'account-multiple-plus',
    value: 'Inclusão de dependente',
    color: COLORS.SECONDARY.GREEN['+1'],
  },
  Exclusion: {
    icon: 'delete-filled',
    value: 'Exclusão',
    color: COLORS.SECONDARY.RED['+1'],
  },
  CadastralChange: {
    icon: 'edit-circle-outline',
    value: 'Alteração',
    color: COLORS.SECONDARY.BLUE['+1'],
  },
  ContractTransfer: {
    icon: 'file-arrow-left-right-outline',
    value: 'Transferência',
    color: COLORS.PRIMARY.ORANGE['+2'],
  },
  PlanChange: {
    icon: 'swap-horizontal',
    value: 'Troca de plano',
    color: COLORS.PRIMARY.ORANGE['+1'],
  },
  Reactivation: {
    icon: 'refresh',
    value: 'Reativação',
    color: COLORS.PRIMARY.ORANGE['+1'],
  },
  ExclusionCancelation: {
    icon: 'delete-scratched',
    value: 'Cancelar Exclusão',
    color: COLORS.SECONDARY.RED['+1'],
  },
  Retired: {
    icon: 'account-multiple-plus',
    value: 'Aposentado e/ou demitido',
    color: COLORS.MONOCHROMATIC.GRAY4,
  },
};
