import { useCallback, useRef, useState } from 'react';
import Icon from '../Icon';
import { COLORS } from '../../themes/colors';
import formatDateISO from '../../utils/formatDateISO';
import { formatBytes } from '../../utils/formatBytes';
import { Stack, Tooltip, Typography } from '@mui/material';
import { Filename } from './styles';
import { Base64File } from '../../utils/base64File';
import IDragAndDropFile from '../DragAndDrop/dtos/IDragDropFile';

type PositionTooltip =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'right-end'
  | 'right-start'
  | 'top-end'
  | 'top-start'
  | undefined;

interface FileProps {
  filename: string;
  date?: string;
  size?: string | number;
  lines?: number;
  totalCompany?: number;
  positionTooltip: PositionTooltip;
  maxLabelLength?: number;
  accept?: string;
  onDownload?: () => void;
  onEdit?: (file: IDragAndDropFile) => void;
  onDelete?: () => void;
}

const File = ({
  filename,
  date,
  size,
  lines,
  totalCompany,
  positionTooltip,
  accept,
  maxLabelLength = 15,
  onDownload,
  onEdit,
  onDelete,
}: FileProps) => {
  const [_, setFile] = useState<IDragAndDropFile | null>(null);
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [currentFileName, setCurrentFileName] = useState('');
  const [currentFileModifiedDate, setCurrentFileModifiedDate] =
    useState<Date | null>(null);

  const substringFilename = useCallback(
    (filename: string) => {
      if (filename.length <= maxLabelLength) return filename;
      const aux = filename.split('.');
      const ext = aux.length > 1 ? `.${aux[aux.length - 1]}` : '';
      const extPos = filename.indexOf('.');

      const subname =
        filename.substring(
          0,
          extPos !== -1
            ? Math.min(maxLabelLength - ext.length, extPos - 1)
            : maxLabelLength,
        ) + '(...)';

      return subname + ext;
    },
    [maxLabelLength],
  );
  const onFileChange = async (e: any) => {
    e.preventDefault();
    const newFile = e.target.files[0];

    const base64 = await Base64File.fromFile(newFile);
    if (newFile) {
      setFile(base64);
      if (onEdit) onEdit(base64);
    }
  };

  const handleEditClick = () => {
    if (!fileRef) return;

    fileRef?.current?.click();
  };

  const handleInputClick = () => {
    if (fileRef.current) {
      fileRef.current.value = '';
    }

    if (currentFileName !== filename || !currentFileModifiedDate) {
      setCurrentFileName(filename);
      setCurrentFileModifiedDate(date ? new Date(date) : new Date());
    } else {
      if (fileRef.current !== null && fileRef.current.files !== null) {
        onFileChange({ target: { files: [fileRef.current.files[0]] } });
      }
    }
  };

  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Stack alignItems="center" direction="row" gap={2}>
        <Icon name="file-check" size={24} color={COLORS.PRIMARY.BLUE.MAIN} />
        <span>
          <Tooltip title={filename} placement={positionTooltip} arrow>
            <Filename
              color={COLORS.PRIMARY.BLUE.MAIN}
              fontWeight={900}
              onClick={onDownload && onDownload}
            >
              {substringFilename(filename)}
            </Filename>
          </Tooltip>
          <Typography fontSize={12}>
            {date && (
              <>
                Realizado{' '}
                <strong>
                  {date && formatDateISO(date, "dd 'de' MMMM 'de' yyyy")}{' '}
                  {size && `• (${formatBytes(size) || size})`}
                  {lines && ` • ${lines} linhas`}
                  {totalCompany && ` • ${totalCompany} empresas identificadas`}
                </strong>
              </>
            )}
          </Typography>
        </span>
      </Stack>

      <Stack gap={1} direction="row">
        {onDownload && (
          <Icon
            name="download"
            variant="outlined"
            size={22}
            color={COLORS.MONOCHROMATIC.GRAY4}
            onClick={onDownload}
          />
        )}
        {onEdit && (
          <Icon
            name="edit"
            variant="outlined"
            size={22}
            color={COLORS.MONOCHROMATIC.GRAY4}
            onClick={handleEditClick}
          />
        )}
        {onDelete && (
          <Icon
            name="delete"
            variant="outlined"
            size={22}
            color={COLORS.MONOCHROMATIC.GRAY4}
            onClick={onDelete}
          />
        )}
      </Stack>
      <input
        type="file"
        accept={accept}
        id="fileInput"
        ref={fileRef}
        onClick={handleInputClick}
        onChange={onFileChange}
        style={{ display: 'none' }}
      />
    </Stack>
  );
};

export default File;
