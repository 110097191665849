import { styled as muiStyled } from '@mui/material/styles';

import { COLORS } from '../../themes/colors';
import Typography from '../Typography';
import Icon from '../Icon';
import { Button, Grid, Stack } from '@mui/material';

export const Container = muiStyled(Grid)`
  &:hover {
    cursor: pointer;
  }
`;

export const Username = muiStyled(Typography)(({ theme }) => ({
  color: COLORS.PRIMARY.BLUE.MAIN,
  
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: "40ch",

  [theme.breakpoints.down('xl')]: {
    maxWidth: "18ch",
  },

  [theme.breakpoints.down('lg')]: {
    maxWidth: "5ch",
  },

  [theme.breakpoints.down(830)]: {
    display: 'none',
  }
}));

export const ChevronDown = muiStyled(Icon)`
  color: ${COLORS.PRIMARY.BLUE.MAIN};
`;

export const MobileHiddenStack = muiStyled(Stack)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down(830)]: {
    display: 'none',
  },
}));

export const LogoutButton = muiStyled(Button)(() => ({
  color: COLORS.MONOCHROMATIC.GRAY4,
  fontWeight: 400,
  fontSize: 14,
  '& .MuiButton-startIcon': {
    color: COLORS.PRIMARY.ORANGE.MAIN,
  },
}));
