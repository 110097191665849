import { Stack, StackProps, styled as muiStyled } from '@mui/material';

import Box from '@mui/material/Box';

import styled, { css } from 'styled-components';

import { COLORS } from '../../../../../themes/colors';

interface DrawerStepItemProps extends StackProps {
  activate: boolean;
  open?: boolean;
  component?: React.ElementType<any> | any;
}

interface StepProps {
  active: boolean;
  stepFinished: boolean;
}

export const StepItem = styled(({ activate, ...rest }: DrawerStepItemProps) => (
  <Stack {...rest} />
))<DrawerStepItemProps>`
  width: 100%;
  border-radius: 8px;
  ${({ open }) =>
    open
      ? css`
          padding: 16px;
        `
      : css`
          padding: 16px;
        `}

  i {
    font-size: 24px;
  }

  &:hover {
    cursor: pointer;
  }

  ${({ activate }) =>
    activate &&
    css`
      background: rgba(237, 171, 56, 0.08);

      color: ${COLORS.PRIMARY.ORANGE.MAIN};
      font-size: 24px;

      .MuiTypography-root {
        font-weight: 600;
      }

      .MuiListItemIcon-root {
        color: ${COLORS.PRIMARY.ORANGE.MAIN};
        font-size: 24px;
      }
    `}
`;

export const StyledStep = muiStyled(Box)<StepProps>(
  ({ active, stepFinished }) => ({
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '50%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    fontSize: '14px',
    fontWeight: 'bold',

    border: stepFinished
      ? '1px solid transparent'
      : active && !stepFinished
      ? '1px solid transparent'
      : `1px solid ${COLORS.MONOCHROMATIC.GRAY4}`,

    background: stepFinished
      ? COLORS.SECONDARY.GREEN['+1']
      : active && !stepFinished
      ? COLORS.PRIMARY.ORANGE.MAIN
      : 'transparent',

    color: stepFinished
      ? COLORS.WHITE
      : active && !stepFinished
      ? COLORS.WHITE
      : COLORS.MONOCHROMATIC.GRAY4,
  }),
);
