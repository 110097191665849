import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSnackbar } from 'notistack';

import CompanySetupService from '@services/CompanySetupService';
import type { NewCompanyContentProps } from '@services/CompanySetupService/dtos/ListNewCompanyDTO';

import { toastifyApiErrors } from '@utils';
import { ITEMS_LIMIT_PER_PAGE } from '@constants';

export function useNewCompanyListState() {
  const { enqueueSnackbar } = useSnackbar();

  const companySetupService = useMemo(() => new CompanySetupService(), []);

  const {
    hasNextPage,
    fetchNextPage,
    data: newCompanyListPages,
    isLoading,
    isError,
  } = useInfiniteQuery<NewCompanyContentProps>({
    queryKey: ['newCompanyListState'],
    queryFn: ({ pageParam = 1 }) =>
      companySetupService.getNewCompanies({
        offset: ITEMS_LIMIT_PER_PAGE * (pageParam - 1),
        limit: ITEMS_LIMIT_PER_PAGE,
      }),
    getNextPageParam: (lastPage, allPages) => {
      const newCompaniesHasNextPage =
        lastPage.total > allPages.length * ITEMS_LIMIT_PER_PAGE;

      if (newCompaniesHasNextPage) {
        return allPages.length + 1;
      }

      return undefined;
    },
    onError: (error: unknown) => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  return {
    hasNextPage,
    fetchNextPage,
    newCompanyListPages,
    isLoading,
    isError,
  };
}
