import { Typography } from '@mui/material';

import { Modal } from '@hapvida/hapvida-core-components';

import type { NoReviewsScreenModalProps } from './types';

export function NoReviewsScreenModal({
  modalState,
  toggleModal,
}: Readonly<NoReviewsScreenModalProps>) {
  return (
    <Modal
      open={modalState}
      icon="alert"
      type="default"
      handleCloseModal={toggleModal}
      title="Não encontramos nenhuma crítica de movimentação"
      secondaryButtonText="Fechar"
    >
      <Typography variant="text">
        Verificamos que atualmente não existe nenhuma crítica de movimentação.
      </Typography>
    </Modal>
  );
}
