import Icon from '../../../../Icon';
import { COLORS } from '../../../../../themes/colors';

import {
  StyledActionCell,
  ActionBox,
  CollapsedButton,
  EditButton,
} from './styles';
import type { ActionCellProps } from '../../types';

export function ActionCell({
  showCollapseButton,
  showEditButton = true,
  collapsed = false,
  itHasError,
  itHasErrorMessage,
  onEdit,
  onCollapse,
}: Readonly<ActionCellProps>) {
  const showEdit = showEditButton && !itHasError;

  return (
    <StyledActionCell
      collapsed={collapsed ? collapsed.toString() : 'false'}
      ithaserror={itHasError ? itHasError.toString() : 'false'}
      showEditButton={showEditButton}
      ithaserrormessage={
        itHasErrorMessage ? itHasErrorMessage.toString() : 'false'
      }
    >
      <ActionBox>
        {showCollapseButton && (
          <CollapsedButton onClick={onCollapse} disableRipple>
            <Icon
              name={collapsed ? 'chevron-up' : 'chevron-down'}
              color={COLORS.MONOCHROMATIC.GRAY4}
              size={24}
            />
          </CollapsedButton>
        )}
        {showEdit && (
          <EditButton onClick={onEdit}>
            <Icon
              name="edit"
              size={24}
              style={{
                minWidth: '24px',
              }}
            />
          </EditButton>
        )}
      </ActionBox>
    </StyledActionCell>
  );
}
