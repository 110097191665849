import { Box, Divider, RadioGroup } from '@mui/material';

import { AccordionNew } from '@hapvida/hapvida-core-components';

import { useAccessManagement } from 'components/ProfileSelectionCard/hooks';

import { ProfileTypeItem, SelectedProfile } from './components';
import { useProfileCard } from '../../hooks';
import type { ProfileSelectionProps } from './types';

export function ProfileSelection({
  isReadonly,
}: Readonly<ProfileSelectionProps>) {
  const { selectedProfiles, availableProfilesState } = useAccessManagement();
  const { selectedProfile, handleSelectProfile } = useProfileCard();

  const availableProfiles = availableProfilesState?.data ?? [];

  const currentProfiles = availableProfiles.filter(
    item =>
      !selectedProfiles.some(({ id }) => id === item.id) ||
      item.id === selectedProfile?.id,
  );

  const showDivider = (index: number) => {
    return index < currentProfiles.length - 1;
  };

  return (
    <Box>
      <AccordionNew isReadonly={isReadonly} title="Tipo de perfil">
        <RadioGroup
          aria-labelledby="radio-buttons-group-label"
          name="radio-buttons-group"
          value={selectedProfile?.name ?? ''}
        >
          {currentProfiles.map((profile, index) => (
            <div key={profile?.id || index}>
              <ProfileTypeItem
                onSelectProfile={handleSelectProfile}
                profile={profile}
              />
              {showDivider(index) && <Divider />}
            </div>
          ))}
        </RadioGroup>
      </AccordionNew>
      {selectedProfile && <SelectedProfile profile={selectedProfile} />}
    </Box>
  );
}
