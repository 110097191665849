import React from 'react';

import { PopperProps, Tooltip } from '@mui/material';

import { QuestionMarkContainer } from './styles';

interface HelperTooltipProps {
  children: React.ReactNode;
  popperProps?: Partial<PopperProps>;
}

const HelperTooltip: React.FC<HelperTooltipProps> = ({
  children,
  popperProps,
  ...props
}) => {
  return (
    <Tooltip
      title={children || ''}
      PopperProps={{
        ...popperProps,
        style: {
          ...popperProps?.style,
          maxWidth: 250,
        },
      }}
      {...props}
    >
      <QuestionMarkContainer>?</QuestionMarkContainer>
    </Tooltip>
  );
};

export default HelperTooltip;
