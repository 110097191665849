import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';

import MovimentationService from '@services/MovimentationService';

import { toastifyApiErrors } from '@utils';

export function useReviewsScreen() {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const movementService = useMemo(() => new MovimentationService(), []);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const getReviewsScreen = useCallback(async () => {
    try {
      setLoadingState(true);

      const movementFileResponse = await queryClient.fetchQuery({
        queryKey: ['reviewsScreenMovementState'],
        queryFn: () => movementService.getReviewsScreenMovement(),

        cacheTime: 0,
      });

      return movementFileResponse.existsReviewsScreenMovement;
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);

      return false;
    } finally {
      setLoadingState(false);
    }
  }, [movementService, queryClient]);

  return { getReviewsScreen, loadingState };
}
