import { useCallback, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Loading, Modal } from '@hapvida/hapvida-core-components';

import BuildingSvg from '@assets/images/building.svg';
import { useNewCompanyModal } from '@flows/private/home/hooks';

import { Circle, StyledStack } from './styles';
import type { NewCompanyModalLayoutProps } from './types';

export function NewCompanyModalLayout({
  newCompanies,
  fetchNextPage,
  hasNextPage,
}: Readonly<NewCompanyModalLayoutProps>) {
  const { markAsSeen, modalHasBeenSeen } = useNewCompanyModal();
  const showModal = !modalHasBeenSeen && newCompanies?.length > 0;
  const [isModalOpenState, setIsModalOpenState] = useState<boolean>(showModal);

  const toggleModal = useCallback(() => {
    setIsModalOpenState(!isModalOpenState);
  }, [isModalOpenState, setIsModalOpenState]);

  const isMultipleCompanies = newCompanies?.length > 1;

  const title = useMemo(() => {
    if (isMultipleCompanies) return 'Novas empresas disponíveis!';
    return 'Nova empresa disponível!';
  }, [isMultipleCompanies]);

  const description = useMemo(() => {
    if (isMultipleCompanies) {
      return (
        <Typography variant="body1" fontSize={14}>
          <strong>Novas empresas</strong> foram disponibilizadas para seu
          usuário. Para gerenciá-las, basta selecionar através da{' '}
          <strong>lista de empresas.</strong>
        </Typography>
      );
    }
    return (
      <Typography variant="body1" fontSize={14}>
        <strong>Uma nova empresa</strong> foi disponibilizada para seu usuário.
        Para gerenciá-la, basta selecionar através da{' '}
        <strong>lista de empresas.</strong>
      </Typography>
    );
  }, [isMultipleCompanies]);

  const handleCloseModal = useCallback(() => {
    toggleModal();
    markAsSeen();
  }, [toggleModal, markAsSeen]);

  return (
    <Modal
      handleCloseModal={handleCloseModal}
      mainAction={handleCloseModal}
      mainButtonText="Fechar"
      open={isModalOpenState}
      title={title}
      icon="alert"
    >
      <Stack gap={3}>
        {description}
        <StyledStack id="scrollableDiv">
          <InfiniteScroll
            dataLength={newCompanies?.length}
            hasMore={hasNextPage}
            next={fetchNextPage}
            loader={
              <Stack my={2} justifyContent="center" alignItems="center">
                <Loading animationProps={{ height: 20, width: 20 }} />
              </Stack>
            }
            scrollableTarget="scrollableDiv"
          >
            <Stack gap={2}>
              {newCompanies?.map(company => (
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="center"
                  key={company?.id}
                  maxWidth="420px"
                >
                  <Circle />
                  <img
                    width={32}
                    height={32}
                    src={BuildingSvg}
                    alt="new-company"
                  />
                  <Typography
                    id="dialog-title"
                    variant="subtitle1"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    title={company?.name}
                  >
                    {company?.name}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </InfiniteScroll>
        </StyledStack>
      </Stack>
    </Modal>
  );
}
