import React from 'react';
import { Stack, StackProps } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { COLORS } from '../../themes/colors';

interface WrapperProps {
  isBackoffice: boolean;
}

interface AvatarDefaultProps {
  isBackoffice: boolean;
  isDrawerOpened: boolean;
}

export const Wrapper = muiStyled(({ isBackoffice, ...props }: WrapperProps) => (
  <div {...props} />
))(({ isBackoffice }) => ({
  width: '100%',
  position: 'relative',
  borderRadius: '.5rem',
  backgroundColor: isBackoffice ? 'rgba(255, 255, 255, 0.08)' : COLORS.WHITE,
  color: isBackoffice ? COLORS.WHITE : '#000',
  zIndex: 999,
}));

export const TextWrapper = muiStyled(Stack)(() => ({
  paddingLeft: '20px',
}));

interface AvatarProps {
  isBackoffice?: boolean;
  src?: string;
  alt?: string;
}

export const Avatar = muiStyled(
  ({ isBackoffice, alt, src, ...props }: AvatarProps) => (
    <img src={src} alt={alt} {...props} />
  ),
)(({ isBackoffice }) => ({
  borderRadius: '.5rem',
  border: isBackoffice
    ? `1px solid ${COLORS.PRIMARY.ORANGE['-1']}`
    : `1px solid ${COLORS.PRIMARY.BLUE['-2']}`,
  background: COLORS.WHITE,
}));

interface ContainerProps {
  showMenu?: boolean;
  isBackoffice?: boolean;
  onClick?: () => void;
  isDrawerOpened?: boolean;
}

export const Container = muiStyled(
  ({ showMenu, isBackoffice, isDrawerOpened, ...props }: ContainerProps) => (
    <div {...props} />
  ),
)(({ showMenu, isBackoffice, isDrawerOpened }) => ({
  padding: '1rem',
  borderRadius: showMenu ? '0.5rem 0.5rem 0 0' : '0.5rem',
  backgroundColor: showMenu
    ? COLORS.WHITE
    : isBackoffice
    ? 'rgba(255, 255, 255, 0.08)'
    : 'rgba(244, 158, 0, 0.08)',
  display: 'flex',
  alignItems: 'center',
  flexDirection: isDrawerOpened ? 'row' : 'column',
  justifyContent: isDrawerOpened ? 'space-between' : 'center',
  boxShadow: !showMenu ? '0' : '0px 10px 20px 2px rgba(0,0,0,0.1)',
  cursor: 'pointer',
}));

export const MenuTitle = muiStyled('span')(() => ({
  fontWeight: 900,
  fontSize: '1rem',
  color: COLORS.MONOCHROMATIC.GRAY4,
  padding: '.5rem 1rem 0 1rem',
}));

export const AvatarDefault = muiStyled(
  ({ isBackoffice, isDrawerOpened, ...props }: AvatarDefaultProps) => (
    <div {...props} />
  ),
)(({ isBackoffice, isDrawerOpened }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '.5rem',
  border: isBackoffice
    ? `1px solid ${COLORS.PRIMARY.ORANGE['-1']}`
    : `1px solid ${COLORS.PRIMARY.BLUE['-2']}`,
  background: COLORS.WHITE,
}));

interface CompanyNameProps {
  isBackoffice?: boolean;
  showMenu: boolean;
  isDrawerOpened?: boolean;
}

export const CompanyName = muiStyled(
  ({ isBackoffice, showMenu, isDrawerOpened, ...props }: CompanyNameProps) => (
    <span {...props} />
  ),
)(({ isBackoffice, showMenu }) => ({
  fontWeight: 400,
  fontSize: '0.875rem',
  color: isBackoffice && !showMenu ? COLORS.WHITE : COLORS.MONOCHROMATIC.GRAY4,
}));

export const CompanyNameMain = muiStyled(
  ({ isBackoffice, showMenu, isDrawerOpened, ...props }: CompanyNameProps) => (
    <span {...props} />
  ),
)(({ isBackoffice, showMenu, isDrawerOpened }) => ({
  display: isDrawerOpened ? 'flex' : 'none',
  fontWeight: 900,
  fontSize: '0.875rem',
  color: isBackoffice && !showMenu ? COLORS.WHITE : COLORS.PRIMARY.BLUE.MAIN,
}));

export const ToggleIcon = muiStyled(
  ({ isBackoffice, showMenu, isDrawerOpened, ...props }: CompanyNameProps) => (
    <div {...props} />
  ),
)(({ isDrawerOpened }) => ({
  display: isDrawerOpened ? 'flex' : 'none',
  width: 25,
}));

interface CompanyDocumentProps extends CompanyNameProps {}

export const CompanyDocument = muiStyled(
  ({
    isBackoffice,
    showMenu,
    isDrawerOpened,
    ...props
  }: CompanyDocumentProps) => <span {...props} />,
)(({ isBackoffice, isDrawerOpened, showMenu }) => ({
  display: isDrawerOpened ? 'flex' : 'none',
  fontSize: '0.75rem',
  color: isBackoffice && !showMenu ? COLORS.WHITE : COLORS.MONOCHROMATIC.GRAY4,
}));

export const FloatMenu = muiStyled('div')(() => ({
  width: '100%',
  position: 'absolute',
  backgroundColor: COLORS.WHITE,
  boxShadow: '0px 25px 20px 2px rgba(0,0,0,0.1)',
  borderRadius: '0 0 0.5rem 0.5rem',
  whiteSpace: 'normal',
}));

export const LinkCompany = muiStyled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: COLORS.MONOCHROMATIC.GRAY2,
  borderRadius: '0 0 0.5rem 0.5rem',
}));

export const LinkCompanyText = muiStyled('p')(() => ({
  color: COLORS.PRIMARY.BLUE.MAIN,
  fontWeight: 600,
  fontSize: '14px',
  '&:hover': {
    cursor: 'pointer',
  },
}));

interface MenuItemProps extends StackProps {
  isSelected?: boolean;
  onClick: () => void;
}

export const MenuItem = muiStyled(({ isSelected, ...props }: MenuItemProps) => (
  <Stack {...props} />
))(({ isSelected }) => ({
  padding: '1rem',
  backgroundColor: isSelected ? 'rgba(244, 158, 0, 0.08)' : COLORS.WHITE,
  cursor: 'pointer',
  margin: '.5rem 0',

  '&:last-child': {
    borderRadius: '0 0 0.5rem 0.5rem',
  },

  '&:hover': {
    backgroundColor: 'rgba(244, 158, 0, 0.12)',
  },
}));

export const ContentHeader = muiStyled('div')(() => ({
  width: '100%',
  whiteSpace: 'pre-wrap',
  paddingLeft: '0.9rem',
}));

export const AvatarDefaultList = muiStyled('div')(() => ({
  width: 32,
  height: 32,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '.5rem',
  border: `1px solid ${COLORS.PRIMARY.BLUE['-2']}`,
  background: COLORS.WHITE,
}));
