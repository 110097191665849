import { useCallback, useMemo } from 'react';

import { CompanyStatusEnum } from '@constants';
import { useAuth } from '@hooks';
import { IAppGroupRoute, RouteProps, SubRouteProps } from 'routes/types';
import { MENU_LIST } from 'templates/private/components/Sidebar/components/SidebarRouteList/constants';

import { ROUTES } from './constants';

export function useRoutes() {
  const { selectedCompany, user, selectedPartnerCompany, optionalRedirects } =
    useAuth();

  const disabledRoutes = useMemo(() => {
    const isCompanyActive =
      selectedCompany?.status === CompanyStatusEnum.ACTIVE;
    if (isCompanyActive) {
      return [];
    }

    const isCompanyPendingAccess =
      selectedCompany?.status === CompanyStatusEnum.PENDING_ACCESS;
    if (isCompanyPendingAccess) {
      return ROUTES.companyPendingAccessDisabled;
    }

    return ROUTES.allMenu;
  }, [selectedCompany?.status]);

  const handleRouteDisabled = useCallback(
    (route?: RouteProps | SubRouteProps): boolean => {
      if (!route?.path) return false;

      const { path, disabled = false } = route;
      const hasDisabledRoutes = disabledRoutes?.some(item => item === path);
      if (hasDisabledRoutes || disabled) {
        return true;
      }

      return false;
    },
    [disabledRoutes],
  );

  const handleMapGroupRoutes = useCallback(
    (groupRoute: IAppGroupRoute) => {
      const { route } = groupRoute;
      const { subRoutes } = route;
      if (!subRoutes || !optionalRedirects) return groupRoute;

      const updatedSubRoutes = subRoutes.filter(subRoute => {
        const { optionalRedirect } = subRoute;

        if (!optionalRedirect) return true;

        const isOptionalRedirect = Object.keys(optionalRedirects).find(
          key => key === optionalRedirect,
        );

        if (!isOptionalRedirect) return true;

        return optionalRedirects[
          optionalRedirect as keyof typeof optionalRedirects
        ];
      });

      return {
        ...groupRoute,
        route: {
          ...route,
          subRoutes: updatedSubRoutes,
        },
      };
    },
    [handleRouteDisabled],
  );

  const filteredGroupRoutes = useMemo(() => {
    if (!selectedCompany || !selectedPartnerCompany) {
      return [];
    }

    const availableResources = selectedPartnerCompany.profile?.resources ?? {};

    const permittedRoutes = MENU_LIST.filter(
      ({ profileResourceEnum }) => availableResources[profileResourceEnum],
    ).map(handleMapGroupRoutes);

    return permittedRoutes;
  }, [
    user?.companyType,
    selectedCompany,
    selectedPartnerCompany,
    optionalRedirects,
  ]);

  return { handleRouteDisabled, filteredGroupRoutes };
}
