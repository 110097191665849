import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";

import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";

import { COLORS } from "../../themes/colors";

export const StyledPaper = muiStyled(Paper)(({ theme }) => ({
  padding: "3px 0 3px",
  borderRadius: "8px",
  width: "100%",

  [theme.breakpoints.down("sm")]: {
    overflow: "hidden",
  },
}));

export const StyledTab = styled(Tab)`
  && {
    text-transform: none;
    color: ${COLORS.MONOCHROMATIC.GRAY4};
    align-self: flex-end;

    &.Mui-selected {
      p {
        color: ${COLORS.PRIMARY.BLUE.MAIN};
        font-weight: 700;
        align-self: flex-end;
      }
    }
  }
`;
