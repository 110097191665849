import { DataGrid, Mask } from '@hapvida/hapvida-core-components';

import { DescriptionSummary } from './styles';
import type { CompanyDetailsSummaryCardProps } from './types';

export function CompanyDetailsSummaryCard({
  companyDetailsSummaryData,
}: CompanyDetailsSummaryCardProps) {
  function companyDetailsFormatData(data: string | undefined) {
    if (!data) return '-';

    return data;
  }

  const companyDetailsSummaryRows = [
    {
      label: 'Razão Social',
      xs: 12,
      sm: 6,
      md: 3,
      value: (
        <DescriptionSummary fontSize={14}>
          <b>
            {companyDetailsFormatData(companyDetailsSummaryData?.name?.legal)}
          </b>
        </DescriptionSummary>
      ),
    },
    {
      label: 'CNPJ',
      xs: 12,
      sm: 6,
      md: 2.25,
      value: (
        <DescriptionSummary>
          {Mask.cnpj(
            companyDetailsFormatData(companyDetailsSummaryData?.document),
          )}
        </DescriptionSummary>
      ),
    },

    {
      label: 'Contrato',
      xs: 12,
      sm: 6,
      md: 2.25,
      value: (
        <DescriptionSummary>
          {companyDetailsFormatData(companyDetailsSummaryData?.partnerCompany)}
        </DescriptionSummary>
      ),
    },
    {
      label: 'Cód. Unidade',
      xs: 12,
      sm: 6,
      md: 2.25,
      value: (
        <DescriptionSummary>
          {companyDetailsSummaryData?.unitCode}
        </DescriptionSummary>
      ),
    },
    {
      label: 'Data Corte',
      xs: 12,
      sm: 6,
      md: 2.25,
      value: (
        <DescriptionSummary>
          {companyDetailsSummaryData?.cutoffDate ?? '-'}
        </DescriptionSummary>
      ),
    },
  ];

  return (
    <DataGrid
      containerStyles={{
        boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06);',
      }}
      title="Resumo da empresa"
      customSpacing={3}
      rows={companyDetailsSummaryRows}
    />
  );
}
