export const truncateString = (input: string, maxLength: number) => {
  if (input.length <= maxLength) return input;
  const aux = input.split('.');
  const ext = aux.length > 1 ? `.${aux[aux.length - 1]}` : '';
  const extPos = input.indexOf('.');

  const subname =
    input.substring(
      0,
      extPos !== -1
        ? Math.min(maxLength - ext.length, extPos - 1)
        : maxLength,
    ) + '(...)';

  return subname + ext;
};
