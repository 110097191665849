import { COLORS } from '../themes/colors';
import chroma from 'chroma-js';

export const textColorByBackground = (color: any) => {
  const background = chroma(color);
  const MIN_CONTRAST_RATIO = 2.1;
  const WHITE = chroma('white');
  const contrastWithWhite = chroma.contrast(background, WHITE);

  let textColor;

  if (contrastWithWhite >= MIN_CONTRAST_RATIO) textColor = COLORS.WHITE;
  else textColor = COLORS.FONT_BLACK;

  return textColor;
};
