import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';
import { Typography } from '@mui/material';
import styled from 'styled-components';

export const Field = styled(Typography)`
  && {
    color: ${COLORS.PRIMARY.BLUE['-1']};
    font-weight: 600;
    font-size: 14px;
  }
`;

export const Value = styled(({ capitalize, ...props }) => (
  <Typography {...props} />
))<{
  capitalize?: boolean;
}>`
  && {
    font-size: 14px;
    ${props => props.capitalize && 'text-transform: capitalize;'}
  }
`;

export const Filename = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: 700;
    color: ${COLORS.PRIMARY.BLUE['-1']};
  }
`;

export const StyledTypography = styled(Typography)`
  white-space: pre-line;
`;
