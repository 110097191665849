import { Stack, styled as muiStyled } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from '../../themes/colors';

// export const Container = muiStyled()(() => ({
//   padding: "8px 16px",
//   borderBottom: `1px solid ${COLORS.MONOCHROMATIC.GRAY3}`,
//   ":hover": {
//     backgroundColor: "rgba(0,0,0,0.08)",
//   },
//   alignItems: "flex-start",
//   justifyContent: "flex-start",
// }));

export const Container = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding: 8px 16px;
  border-bottom: 1px solid ${COLORS.MONOCHROMATIC.GRAY3};
  :hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;
