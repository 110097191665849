import Grid from '@mui/material/Grid';

import { useDrawerFilter } from '@hapvida/hapvida-core-components';

import { useFilterParams } from '@hooks';
import {
  FiltersDrawerButton,
  SearchField,
  SubHeader,
  UserStatusDrawerFilter,
} from '@components';

import { useBackofficeUserState } from '../../hooks';

export function UserBackofficeListSubHeader() {
  const { hasFilters } = useFilterParams();
  const { filterCount } = useDrawerFilter();
  const { backofficeUserState } = useBackofficeUserState();

  const { isError, isLoading } = backofficeUserState;

  const totalUsersListLength = backofficeUserState?.data?.total ?? 0;

  const isResultEmpty = totalUsersListLength === 0;

  const searchButtonDisabled = isLoading && hasFilters;

  const filtersDrawerButtonDisabled =
    !filterCount && (isLoading || isResultEmpty);

  const isLoadingWithoutFilters = isLoading && !hasFilters;

  const isEmptyWithoutFilters = isResultEmpty && !hasFilters;

  const isErrorWithoutFilters = isError && !hasFilters;

  if (
    isLoadingWithoutFilters ||
    isErrorWithoutFilters ||
    isEmptyWithoutFilters
  ) {
    return null;
  }

  return (
    <SubHeader>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7} md={10}>
          <SearchField
            currentResultLength={totalUsersListLength}
            placeholder="Buscar nome do usuário, e-mail ou CPF"
            disableSearch={searchButtonDisabled}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={2}>
          <FiltersDrawerButton
            subtitle="Selecione como deseja filtrar os usuários"
            title="Filtrar usuários"
            disabled={filtersDrawerButtonDisabled}
          >
            <UserStatusDrawerFilter />
          </FiltersDrawerButton>
        </Grid>
      </Grid>
    </SubHeader>
  );
}
