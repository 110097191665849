import { createContext } from 'react';

import type {
  CompanyProps,
  CurrentUserProps,
} from '@services/AuthService/dtos';
import type { PartnerCompanyProps } from '@services/PartnerCompanyService/dtos';
import { UserRedirectsEnum } from '@services/UserService';

enum UserType {
  Backoffice = 'Backoffice',
  Company = 'Company',
  Agency = 'Agency',
}

interface OptionalRedirectsProps {
  [UserRedirectsEnum.MONTHLY_FEE_PREVIEW_REPORT]?: boolean;
  [UserRedirectsEnum.COPARTICIPATION_PREVIEW_REPORT]?: boolean;
  [UserRedirectsEnum.SUSPENDED_BENEFICIARY]?: boolean;
}

interface AccountProps {
  selectedCompany?: CompanyProps;
  selectedPartnerCompany?: PartnerCompanyProps;
  user?: CurrentUserProps;
  token?: string;
  refreshToken?: string;
  optionalRedirects?: OptionalRedirectsProps;
}

interface AuthContextData extends AccountProps {
  logIn: (account: AccountProps) => void;
  logOut(): void;
  updateAccount: (account: AccountProps) => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export {
  AccountProps,
  AuthContextData,
  AuthContext,
  UserType,
  OptionalRedirectsProps,
};
