import Typography from '@mui/material/Typography';
import { COLORS } from '../../themes/colors';
import Icon from '../Icon';
import { Container, TextWrapper } from './styles';
import type { ReactNode } from 'react';
import { Stack } from '@mui/material';

const ALERT_CARD_DATA = {
  success: {
    borderColor: COLORS.SECONDARY.GREEN['+1'],
    backgroundColor: 'rgba(82, 135, 44, 0.04)',
    titleColor: COLORS.SECONDARY.GREEN['+1'],
  },
  error: {
    borderColor: COLORS.SECONDARY.RED['+1'],
    backgroundColor: 'rgba(201, 54, 0, 0.04)',
    titleColor: COLORS.SECONDARY.RED['+1'],
  },
  info: {
    borderColor: COLORS.PRIMARY.BLUE['-2'],
    backgroundColor: 'rgba(0, 84, 184, 0.04)',
    titleColor: COLORS.PRIMARY.BLUE['+1'],
  },
  alert: {
    borderColor: COLORS.PRIMARY.ORANGE['+1'],
    backgroundColor: 'rgba(255, 153, 0, 0.04)',
    titleColor: COLORS.PRIMARY.ORANGE.MAIN,
  },
};

interface AlertCardProps {
  type: 'success' | 'error' | 'info' | 'alert';
  title?: string;
  content: string | React.JSX.Element;
  button?: ReactNode;
}

const AlertCard = ({ type, title, content, button }: AlertCardProps) => {
  return (
    <Container
      backgroundColor={ALERT_CARD_DATA[type].backgroundColor}
      borderColor={ALERT_CARD_DATA[type].borderColor}
      alignItems={title ? 'flex-start' : 'center'}
    >
      <Icon
        name={type === 'success' ? 'check' : 'alert'}
        size={30}
        color={ALERT_CARD_DATA[type].titleColor}
      />
      <Stack flexDirection="row" alignItems="center" width="100%" gap={9}>
        <TextWrapper marginBottom={title ? 0.75 : 0}>
          {title && (
            <Typography
              variant="subtitle1"
              color={ALERT_CARD_DATA[type].titleColor}
            >
              {title}
            </Typography>
          )}
          {typeof content === 'string' ? (
            <Typography color={COLORS.MONOCHROMATIC.GRAY4} variant="text">
              {content}
            </Typography>
          ) : (
            content
          )}
        </TextWrapper>
        {button}
      </Stack>
    </Container>
  );
};

export default AlertCard;
