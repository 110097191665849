import { Stack } from '@mui/material';
import { useEffect, useRef } from 'react';

import {
  FilterSummary,
  useDrawerFilter,
} from '@hapvida/hapvida-core-components';

import { BackofficeUserListCard } from './components';
import { useBackofficeUserState } from './hooks';
import { backofficeUserDrawerFilterValues } from './constants/backofficeUserDrawerFilterValues';

export function BackofficeUserListLayout() {
  const { backofficeUserState } = useBackofficeUserState();

  const totalUsersLength = backofficeUserState?.data
    ? backofficeUserState?.data?.total
    : 0;

  const filterSummaryLabel = `${totalUsersLength} ${
    totalUsersLength === 1 ? 'usuário' : 'usuários'
  }`;

  const { getInitialFilter, setActiveFilters } = useDrawerFilter();
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      const initialUsersFilters = getInitialFilter({
        filtersConfig: backofficeUserDrawerFilterValues,
      });

      setActiveFilters(initialUsersFilters);
    }
  }, []);

  return (
    <Stack paddingY={3} gap={3}>
      <FilterSummary totalLabel={filterSummaryLabel} />
      <BackofficeUserListCard />
    </Stack>
  );
}
