import { ErrorLine } from '../../ErrorLine';

import { MainRow, TableCellNew } from './styles';
import type { MainLineProps } from '../../../types';
import { ActionCell } from '../../ActionCell';

export function MainLine({
  item,
  collapsed,
  tableHeadColumns,
  onCollapse,
  onEdit,
  hasCellAction,
  rowColor,
}: Readonly<MainLineProps>) {
  const errorMessage =
    item.errorMessage && item.errorMessage.length > 0 ? true : false;

  const colSpan = hasCellAction
    ? tableHeadColumns.length
    : tableHeadColumns.length - 1;

  const { errorComponent } = item;

  const createCells = () => {
    const cells: any = [];

    tableHeadColumns.forEach((column, index) => {
      if (item.main.hasOwnProperty(column.name)) {
        const cell = (
          <TableCellNew key={Math.random() * (10 - index) + index}>
            {item.main[column.name as keyof typeof item.main]}
          </TableCellNew>
        );

        cells.push(cell);
      }
    });

    if (onEdit) {
      cells.push(
        <ActionCell
          itHasError={item.itHasError}
          showEditButton={item.showEditButton}
          itHasErrorMessage={errorMessage}
          key={
            Math.random() * (10 - tableHeadColumns.length) +
            tableHeadColumns.length
          }
          collapsed={collapsed}
          showCollapseButton={item.dependents.length > 0}
          onEdit={() => {
            onEdit(item.id.toString());
          }}
          onCollapse={onCollapse}
        />,
      );
    }

    return cells;
  };

  return (
    <>
      <MainRow
        key={item.id}
        collapsed={collapsed ? collapsed.toString() : 'false'}
        ithaserror={item.itHasError ? item.itHasError.toString() : 'false'}
        ithaserrormessage={errorMessage ? errorMessage.toString() : 'false'}
        sx={{ background: rowColor && rowColor }}
      >
        {createCells()}
      </MainRow>
      {errorMessage && (
        <ErrorLine colspan={colSpan} errorMessage={item.errorMessage || ''} />
      )}
      {errorComponent && (
        <ErrorLine colspan={colSpan}>{errorComponent}</ErrorLine>
      )}
    </>
  );
}
