import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import FinancialService, {
  type FileData,
  type InvoiceProps,
} from '@services/FinancialService';
import { UserRedirectsEnum } from '@services/UserService';

import { useScreenLoading, useAuth, useRedirectUser } from '@hooks';
import { useBill } from '@flows/private/financial/hooks';
import { BillTypeEnum } from '@flows/private/financial/constants/billtypeEnum';

import type { UseHandleDownloadProps } from './types';
import {
  downloadCopayReport,
  downloadDiscountLetter,
  downloadInvoice,
  downloadMonthlyReport,
  fetchAndDownloadCoparticipationReports,
  fetchAndDownloadMonthlyReports,
} from './utils';

export function useHandleDownload({ billsList }: UseHandleDownloadProps) {
  const { onScreenLoading } = useScreenLoading();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useAuth();
  const { getCoparticipationReportFiles } = useBill();
  const companyId = selectedCompany?.id ?? '';
  const { redirectUser } = useRedirectUser();

  const handleOpenFile = (file?: FileData | InvoiceProps) => {
    if (file?.path) window.open(file.path, '_blank');
  };

  const handleOpenFiles = (files?: FileData[]) => {
    files?.forEach(file => handleOpenFile(file));
  };

  const financialService = useMemo(() => new FinancialService(), []);

  const fetchDiscountLetters = async (invoiceNumber?: number) => {
    try {
      const data = await financialService.getDiscountLetters({
        companyId,
        invoiceNumber,
      });
      return data;
    } catch {
      return undefined;
    }
  };

  const handleRedirectBillLegacyPortal = (billingNumber: string) => {
    redirectUser(UserRedirectsEnum.BILLING, `?billing-number=${billingNumber}`);
  };

  const handleDownload = async (billId: string) => {
    const currentBill = billsList.bills.find(
      ({ billingNumber }) => `${billId}` === `${billingNumber}`,
    );

    if (currentBill) {
      const isCopayReport =
        currentBill.billType === BillTypeEnum.CARNET_COMPLEMENT;
      const isMonthlyReport =
        currentBill.billType === BillTypeEnum.HEALTH_PLAN_MONTHLY_FEE;

      onScreenLoading(true);

      // Nota fiscal
      downloadInvoice(currentBill.healthOperator, currentBill.invoice?.path);

      // Report copay
      if (isCopayReport) {
        await downloadCopayReport(
          currentBill.coparticipationAnalytical,
          currentBill,
          getCoparticipationReportFiles,
        );
      }

      // Carta de desconto
      if (currentBill.invoice?.number) {
        await downloadDiscountLetter(
          billId,
          currentBill.invoice?.number,
          fetchDiscountLetters,
        );
      }

      // Boleto
      handleRedirectBillLegacyPortal(currentBill.billingNumber);

      // Report de mensalidade (PDF, CSV, TXT)
      if (isMonthlyReport) {
        await downloadMonthlyReport(
          'PDF',
          currentBill.billingNumber,
          financialService,
        );
        await downloadMonthlyReport(
          'CSV',
          currentBill.billingNumber,
          financialService,
        );
        await downloadMonthlyReport(
          'TXT',
          currentBill.billingNumber,
          financialService,
        );
      }

      onScreenLoading(false);
    } else {
      enqueueSnackbar('Fatura não encontrada.', { variant: 'warning' });
    }
  };

  const handleDownloadAll = async (billListSelected: string[]) => {
    onScreenLoading(true);

    const selectedBills = billsList.bills.filter(({ billingNumber }) =>
      billListSelected.includes(billingNumber),
    );

    const personNumberResult = billsList.bills[0]?.personNumber;

    const service = new FinancialService();

    await fetchAndDownloadCoparticipationReports(
      selectedBills,
      service,
      personNumberResult,
      handleOpenFiles,
    );

    await fetchAndDownloadMonthlyReports(
      selectedBills,
      service,
      selectedCompany?.id,
      handleOpenFiles,
    );

    selectedBills.forEach(bill => {
      handleDownload(bill.billingNumber);
    });

    onScreenLoading(false);
  };

  return { handleDownload, handleDownloadAll };
}
