import type { IconName } from '@hapvida/hapvida-core-components/src/components/Icon/types';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

type SummaryItens = {
  label: string;
  color?: string;
  iconName?: IconName;
  key: string;
};

const SUMMARY_DATA: SummaryItens[] = [
  {
    color: COLORS.PRIMARY.GREEN['+1'],
    label: 'Inclusão de titular',
    iconName: 'account-plus',
    key: 'inclusion',
  },
  {
    color: COLORS.PRIMARY.GREEN['+1'],
    iconName: 'account-multiple-plus',
    label: 'Inclusão de dependente',
    key: 'dependent',
  },
  {
    color: COLORS.SECONDARY.RED['+1'],
    iconName: 'delete-filled',
    label: 'Exclusão',
    key: 'exclusion',
  },
  {
    color: COLORS.SECONDARY.BLUE['+1'],
    label: 'Alteração',
    iconName: 'edit-circle-outline',
    key: 'alteration',
  },
  {
    iconName: 'file-arrow-left-right-outline',
    label: 'Transferência',
    color: COLORS.PRIMARY.ORANGE['+2'],
    key: 'transfer',
  },
  /* REMINDER: tipos de movimentação comentados a pedido da área de negócios
  {
    label: 'Troca de plano',
    color: COLORS.SECONDARY.PURPLE.MAIN,
    iconName: 'swap-horizontal',
    key: 'plan',
  },
  {
    label: 'Reativação',
    color: COLORS.SECONDARY.YELLOW['-1'],
    iconName: 'refresh',
    key: 'reactivation',
  },
  {
    label: 'Cancelar exclusão',
    color: COLORS.SECONDARY.RED['+1'],
    iconName: 'delete-scratched',
    key: 'cancel-exclusion',
  },
  {
    label: 'Aposentado e/ou demitido',
    color: COLORS.MONOCHROMATIC.GRAY4,
    iconName: 'account-multiple-plus',
    key: 'demission',
  },

  {
    label: '2ª via de carteirinha',
    color: COLORS.PRIMARY.BLUE['+1'],
    iconName: 'card-account-details',
    key: 'card',
  },
  */
];

export { SUMMARY_DATA, SummaryItens };
