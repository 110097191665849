import { useQuery as query } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

import FinancialService from '@services/FinancialService';

import { toastifyApiErrors } from '@utils';
import { useFilterParams, useAuth } from '@hooks';

import type { BillsListStateContextData } from './types';

export const BillsListStateContext = createContext<BillsListStateContextData>(
  {} as BillsListStateContextData,
);

function BillsListStateProvider({ children }: Readonly<PropsWithChildren<{}>>) {
  const { tableOrder, searchParam, currentPage, mappedFilters } =
    useFilterParams();

  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany, selectedPartnerCompany } = useAuth();

  const companyId = useMemo(() => selectedCompany?.id ?? '', [selectedCompany]);
  const partnerCompany = useMemo(
    () => selectedPartnerCompany?.partnerCompany ?? '',
    [selectedPartnerCompany],
  );

  const financialService = useMemo(() => new FinancialService(), []);

  const billSummariesState = query({
    queryKey: [
      'billSummariesState',
      mappedFilters,
      searchParam,
      companyId,
      partnerCompany,
    ],
    queryFn: () =>
      financialService.getBillsSummary({
        companyId,
        ...mappedFilters,
        ...searchParam,
      }),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const billListState = query({
    queryKey: [
      'billListState',
      mappedFilters,
      searchParam,
      currentPage,
      tableOrder,
      companyId,
      partnerCompany,
    ],
    queryFn: () =>
      financialService.getBillsList({
        companyId,
        ...mappedFilters,
        ...searchParam,
        ...tableOrder,
        offset: 9 * (currentPage - 1),
        limit: 9,
      }),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const handleRefreshBillsStates = () => {
    billSummariesState.remove();
    billListState.remove();

    billSummariesState.refetch();
    billListState.refetch();
  };

  const valueBillsStateContextProvider = useMemo(
    () => ({
      handleRefreshBillsStates,
      billSummariesState,
      billListState,
    }),
    [handleRefreshBillsStates, billSummariesState, billListState],
  );

  return (
    <BillsListStateContext.Provider value={valueBillsStateContextProvider}>
      {children}
    </BillsListStateContext.Provider>
  );
}

function useBillsState() {
  const context = useContext(BillsListStateContext);

  if (!context) {
    throw new Error('useBillsState must be used within BillsStateProvider');
  }

  return context;
}

export { useBillsState, BillsListStateProvider };
