import { Icon } from '@hapvida/hapvida-core-components';

import type { IAppRoute } from 'routes/types';

import { MovementListPage } from './index';

export const MovementListRouter: IAppRoute = {
  Page: MovementListPage,
  path: '/movimentacoes',
  name: 'Via arquivo (layout)',
  isPrivate: true,
  Icon: <Icon name="clipboard-text-outline" />,
};
