import { styled as muiStyled } from '@mui/material';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { COLORS } from '../../themes/colors';

export const Container = muiStyled(Stack)(() => ({
  background: COLORS.WHITE,

  marginTop: '1.5rem',
  padding: '1.5rem',

  borderRadius: '8px',
}));

export const Title = muiStyled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: '1rem',
  lineHeight: '1.25rem',

  color: COLORS.MONOCHROMATIC.GRAY4,

  marginBottom: '1.5rem',
}));

export const Label = muiStyled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '0.875rem',
  lineHeight: '1.125rem',

  color: COLORS.PRIMARY.BLUE['-1'],
}));

export const Value = muiStyled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.125rem',

  color: COLORS.MONOCHROMATIC.GRAY4,
}));
