import { Grid, useTheme } from '@mui/material';

import HapvidaLogo from '@hapvida/hapvida-core-components/src/assets/images/hapvida-logo-new.svg';
import NdiLogo from '@hapvida/hapvida-core-components/src/assets/images/ndi-logo-new.svg';

import HapvidaLogoLight from '@hapvida/hapvida-core-components/src/assets/images/hapvida-logo-new-light.svg';
import NdiLogoLight from '@hapvida/hapvida-core-components/src/assets/images/ndi-logo-new-light.svg';

import { DrawerHeaderContainer, IconButton, StyledStack } from './styles';
import { useSidebar } from '../../hooks';
import { Icon } from '@hapvida/hapvida-core-components';
import type { SidebarHeaderProps } from './types';

export function SidebarHeader({
  isColorful = true,
  onClickLogo,
}: Readonly<SidebarHeaderProps>) {
  const { toggleDrawer, drawerOpen } = useSidebar();
  const theme = useTheme();

  return (
    <DrawerHeaderContainer>
      <Grid display={{ sm: 'none' }}>
        <IconButton
          onClick={toggleDrawer}
          disableRipple
          style={{ flex: drawerOpen ? 0 : 1 }}
        >
          <Icon
            color={theme.palette.action.primary.active}
            name="drawer"
            size={32}
          />
        </IconButton>
      </Grid>
      {drawerOpen && (
        <StyledStack
          paddingX={2}
          flexDirection="row"
          gap={2}
          onClick={onClickLogo}
        >
          <img
            src={isColorful ? HapvidaLogo : HapvidaLogoLight}
            alt="Hapvida"
            height={24}
          />
          <img
            src={isColorful ? NdiLogo : NdiLogoLight}
            height={26}
            alt="Ndi"
          />
        </StyledStack>
      )}
    </DrawerHeaderContainer>
  );
}
