import { Button } from '@mui/material';
import styled from 'styled-components';

const SvgContainer = styled.div`
  & div > span > svg {
    margin-left: -4px;
  }
`;

const StyledButton = styled(Button)`
  font-weight: 600;
`;

export { SvgContainer, StyledButton };
