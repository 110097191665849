import React, { createContext, useContext, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';

import BeneficiaryService, {
  type BeneficiariesProps,
} from '@services/BeneficiaryService';

import { useAuth, useFilterParams } from '@hooks';
import { toastifyApiErrors } from '@utils';

import type { LetterOfStayContextData } from './types';

const LetterOfStayContext = createContext<LetterOfStayContextData>(
  {} as LetterOfStayContextData,
);

export const StayLetterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { searchParam } = useFilterParams();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedCompany } = useAuth();
  const companyId = selectedCompany?.id ?? '';
  const beneficiaryService = useMemo(() => new BeneficiaryService(), []);

  const {
    data: beneficiaries,
    isError,
    isFetching: isLoadingBeneficiaries,
    refetch: refetchBeneficiaries,
    remove: removeBeneficiaries,
  } = useQuery<BeneficiariesProps>({
    queryKey: ['BeneficiaryState', searchParam, companyId],
    queryFn: () =>
      beneficiaryService.fetchBeneficiaries({
        companyId,
        offset: 0,
        limit: 1,
        ...searchParam,
      }),
    enabled: Boolean(searchParam.search),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const beneficiaryMemo = useMemo(
    () =>
      beneficiaries ? beneficiaries?.beneficiaries[0]?.beneficiary : undefined,
    [beneficiaries],
  );

  const {
    data: stayLetterFile = '',
    remove: removeLetter,
    refetch: refetchLetter,
    isFetching: isLoadingLetter,
  } = useQuery<string>({
    queryKey: ['StayLetterState', beneficiaryMemo],
    queryFn: () =>
      beneficiaryService.fetchStayLetterFile(beneficiaryMemo?.code ?? ''),
    enabled: Boolean(beneficiaryMemo),
  });

  const refreshBeneficiary = () => {
    removeBeneficiaries();
    removeLetter();

    refetchBeneficiaries();
    refetchLetter();
  };

  const valueContextProvider = useMemo(
    () => ({
      errorState: isError,
      loadingState: isLoadingBeneficiaries || isLoadingLetter,
      beneficiaryState: beneficiaryMemo,
      stayLetterFile,
      refreshBeneficiary,
    }),
    [
      isError,
      isLoadingBeneficiaries,
      isLoadingLetter,
      beneficiaryMemo,
      stayLetterFile,
    ],
  );

  return (
    <LetterOfStayContext.Provider value={valueContextProvider}>
      {children}
    </LetterOfStayContext.Provider>
  );
};

export function useStayLetter() {
  const context = useContext(LetterOfStayContext);

  if (!context) {
    throw new Error(
      'useLetterOfStay must be used within a LetterOfStayProvider',
    );
  }

  return context;
}
