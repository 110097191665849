import { Box, Stack, styled as muiStyled } from '@mui/material';

import Typography from '../Typography';
import { COLORS as COLORTHEME } from '../../themes/colors';
import Checkbox from '../Checkbox';

export const COLORS = [
  COLORTHEME.PRIMARY.BLUE.MAIN,
  COLORTHEME.PRIMARY.ORANGE.MAIN,
  COLORTHEME.PRIMARY.BLUE['-3'],
  COLORTHEME.MONOCHROMATIC.GRAY4,
  COLORTHEME.DISABLED.BACKGROUND,
];

export const Wrapper = muiStyled(Stack)(() => ({
  background: `${COLORTHEME.LIGHT.MAIN}`,
  padding: '24px 26px',
}));

export const GraphicWrapper = muiStyled(Stack)(() => ({
  flexDirection: 'column',
}));

export const GraphicTitle = muiStyled(Typography)(() => ({
  color: `${COLORTHEME.MONOCHROMATIC.GRAY4}`,
  fontSize: '16px',
  fontWeight: 900,
}));

export const GraphicContent = muiStyled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
}));

export const QuantityWrapper = muiStyled(Stack)(() => ({
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
}));

export const QuantityTitle = muiStyled(Typography)(() => ({
  color: `${COLORTHEME.DARK.MAIN}`,
  fontSize: '24px',
  fontWeight: 900,
}));

export const QuantityDescription = muiStyled(Typography)(() => ({
  color: `${COLORTHEME.MONOCHROMATIC.GRAY4}`,
  fontSize: '14px',
  fontWeight: 400,
}));

export const TooltipWrapper = muiStyled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px',
  backgroundColor: `${COLORTHEME.MONOCHROMATIC.GRAY1}`,
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06)',
  borderRadius: '4px',
  gap: '13px',
}));

export const TooltipValue = muiStyled(Typography)(() => ({
  color: `${COLORTHEME.BLACK}`,
  fontSize: '14px',
  fontWeight: 400,
}));

export const CompanyWrapper = muiStyled(Stack)(() => ({
  background: `${COLORTHEME.WHITE}`,
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06)',
  backdropFilter: 'blur(24px)',
  borderRadius: '8px',
  width: '100%',
  maxWidth: '292px',
  padding: '16px',
  height: '387px',
  overflowY: 'auto',
}));

export const ScrollWrapper = muiStyled(Stack)(() => ({
  overflowY: 'auto',
}));

export const CompanyTitleWrapper = muiStyled(Stack)(() => ({
  flexDirection: 'row',
  marginBottom: '21px',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const CompanyTitleGroup = muiStyled(Typography)(() => ({
  color: `${COLORTHEME.MONOCHROMATIC.GRAY4}`,
  fontSize: '12px',
  fontWeight: 400,
  width: '88px',
}));

export const CheckAllCompanyWrapper = muiStyled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
}));

export const CheckboxStyled = muiStyled(Checkbox)(() => ({
  '&.MuiCheckbox-indeterminate': {
    color: `${COLORTHEME.PRIMARY.ORANGE.MAIN}`,
  },
}));

export const CheckAllCompanyTitle = muiStyled(Typography)(() => ({
  color: `${COLORTHEME.BLACK}`,
  fontSize: '14px',
  fontWeight: 400,
}));

export const AccordionTitleWrapper = muiStyled(Stack)(() => ({}));

export const MatrizBox = muiStyled(Stack)(() => ({
  background: `${COLORTHEME.PRIMARY.BLUE.MAIN}`,
  borderRadius: '2px',
  width: '48px',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '4px',
}));

export const MatrizText = muiStyled(Typography)(() => ({
  color: `${COLORTHEME.WHITE}`,
  fontSize: '12px',
  fontWeight: 700,
  textTransform: 'uppercase',
}));

export const CompanyTitle = muiStyled(Box)(() => ({
  color: `${COLORTHEME.MONOCHROMATIC.GRAY4}`,
  fontSize: '12px',
  fontWeight: 700,

  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '164px',
}));

export const CompanyDescription = muiStyled(Typography)(() => ({
  color: `${COLORTHEME.MONOCHROMATIC.GRAY4}`,
  fontSize: '12px',
  fontWeight: 400,
}));

export const Content = muiStyled(Stack)(() => ({
  marginTop: '16px',
  flexDirection: 'column',
  padding: '0 16px',
}));

export const AmountWrapper = muiStyled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '16px',
}));

export const AmountTitle = muiStyled(Typography)(() => ({
  color: `${COLORTHEME.PRIMARY.BLUE['-1']}`,
  fontSize: '14px',
  fontWeight: 600,
}));

export const AmountValue = muiStyled(Box)(() => ({
  color: `${COLORTHEME.MONOCHROMATIC.GRAY4}`,
  fontSize: '14px',
  fontWeight: 400,

  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '146px',
}));

// loading
export const PulsarWrapper = muiStyled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minHeight: '387px',
}));

export const PulsarAnimation = muiStyled(Stack)(() => ({
  backgroundColor: COLORTHEME.PRIMARY.ORANGE['-2'],
  width: '70px',
  height: '70px',
  borderRadius: '50px',
  animation: 'pulsate 1s infinite ease',
  '@keyframes pulsate': {
    from: {
      opacity: 1,
      width: '35px',
      height: '35px',
    },
    to: {
      opacity: 0,
      width: '70px',
      height: '70px',
    },
  },

  ':after': {
    content: '""',
    display: 'block',
    backgroundColor: COLORTHEME.PRIMARY.ORANGE['-1'],
    width: '35px',
    height: '35px',
    borderRadius: '50px',
    margin: 'auto',
  },
}));

export const EmptyArea = muiStyled(Stack)(() => ({
  height: '240px',
  alignItems: 'center',
  width: '100%',
}));

export const EmptyText = muiStyled(Typography)(() => ({
  color: `${COLORTHEME.MONOCHROMATIC.GRAY4}`,
  fontSize: '24px',
  fontWeight: 900,
}));
