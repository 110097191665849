import { Stack } from '@mui/material';

import { SummaryCardNew } from '@hapvida/hapvida-core-components';

import { MovementListCard } from '../MovementListCard';
import { MovementsSummaryCard } from '../MovementsSummaryCard';
import type { SearchResultProps } from './types';

export const SearchResult = ({ movementList }: Readonly<SearchResultProps>) => {
  return (
    <Stack gap={3} paddingBottom={8}>
      <MovementsSummaryCard />

      <SummaryCardNew.Container>
        <SummaryCardNew.Header
          subtitle="Últimos 30 dias"
          title="Lista de movimentações"
        />
        <MovementListCard movementList={movementList} />
      </SummaryCardNew.Container>
    </Stack>
  );
};
