import { Button, Typography, styled as muiStyled } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

const SubtitleCallout = muiStyled(Typography)(() => ({
  color: `${COLORS.MONOCHROMATIC.GRAY4}`,
  fontSize: '14px',
}));

const RedirectButton = muiStyled(Button)(() => ({
  marginTop: '0',
  minWidth: '164px',
}));

export { RedirectButton, SubtitleCallout };
