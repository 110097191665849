import { Stack } from '@mui/material';

import { ErrorLoadingPage, Loading } from '@hapvida/hapvida-core-components';

import { organizeFilesByType } from '@utils';

import { RedirectAlert } from '../../components';
import { BillDetailsCard } from './components';
import { useBillState } from './hooks';

export function BillLayout() {
  const { coparticipationFilesState, refreshBillStates, billDetailsState } =
    useBillState();

  if (coparticipationFilesState.isFetching || billDetailsState.isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  const hasError =
    billDetailsState.isError || coparticipationFilesState.isError;

  if (hasError) {
    return <ErrorLoadingPage onReloadClick={refreshBillStates} />;
  }
  const reportFiles = organizeFilesByType([
    ...billDetailsState.data.analyticalStatements,
    ...(coparticipationFilesState.data ?? []),
  ]);

  return (
    <Stack paddingY={3} paddingX={1}>
      <RedirectAlert />
      <BillDetailsCard bill={billDetailsState.data} reportFiles={reportFiles} />
    </Stack>
  );
}
