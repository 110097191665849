import type { AnalyticalFile } from '../types';

export const downloadFiles = async (
  fileList: AnalyticalFile[],
  getReport: (item: AnalyticalFile) => Promise<string>,
  handleOpenFiles: (filesData: { path: string; fileName: string }[]) => void,
) => {
  const filesData = await Promise.all(
    fileList?.map(async item => {
      const data = await getReport(item);
      return {
        path: data,
        fileName: item?.fileName,
      };
    }),
  );
  handleOpenFiles(filesData);
};
