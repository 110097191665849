import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';
import { styled, Typography } from '@mui/material';

export const DescriptionHeader = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 700,
}));

export const getAccordionStyle = (selected: boolean): React.CSSProperties => ({
  backgroundColor: selected
    ? 'linear-gradient(0deg, rgba(245, 182, 127, 0.08), rgba(245, 182, 127, 0.08)), #FFFFFF'
    : 'transparent',
  border: selected
    ? `1px solid ${COLORS.PRIMARY.ORANGE.MAIN}`
    : `1px solid ${COLORS.MONOCHROMATIC.GRAY2}`,
  borderColor: selected
    ? `${COLORS.PRIMARY.ORANGE.MAIN} !important`
    : `${COLORS.MONOCHROMATIC.GRAY2} !important`,
  padding: '0 16px',
});
