import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';
import { Paper, Typography } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Paper)`
  background: white;
  padding: 24px;
  border-radius: 8px;
`;

export const Date = styled(Typography)`
  && {
    font-size: 12px;
    color: ${COLORS.MONOCHROMATIC.GRAY4};
  }
`;
