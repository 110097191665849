import { IAppRoute } from 'routes/types';
import { FEATURE_TOGGLE } from '@constants';

import { StartPasswordRecoveryPage } from '.';
import { OldPassworRecoveryPage } from '../OldStartPasswordRecovery';

const currentPage = FEATURE_TOGGLE.NEW_PASSWORD_RECOVERY_LAYOUT
  ? StartPasswordRecoveryPage
  : OldPassworRecoveryPage;

export const StartPasswordRecoveryRouter: IAppRoute = {
  Page: currentPage,
  path: '/recuperacao-de-senha',
  name: 'Recuperação de senha',
  Icon: <></>,
  disabled: false,
};
