import { styled as muiStyled } from '@mui/material';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { COLORS } from '../../themes/colors';

import Collapse from '@mui/material/Collapse';

export const Wrapper = muiStyled(Stack, {
  shouldForwardProp: prop => prop !== 'disabled',
})<{ disabled?: boolean }>(({ disabled }) => ({
  border: `1px solid ${COLORS.MONOCHROMATIC.GRAY2}`,
  borderRadius: '8px 8px 8px 8px',
  transition: 'background-color 0.3s ease-in-out',

  ...(disabled && {
    pointerEvents: 'none',
    opacity: 0.5,
  }),
}));

export const Header = muiStyled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  padding: '16px',
}));

export const SelectAll = muiStyled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  borderTop: `2px solid ${COLORS.MONOCHROMATIC.GRAY2}`,
  borderBottom: `1px solid ${COLORS.MONOCHROMATIC.GRAY2}`,
  paddingLeft: '4px',

  '& .MuiTypography-root': {
    fontWeight: 700,
    fontSize: '14px',
    color: COLORS.BLACK,
  },
}));

export const StyledCollapse = muiStyled(Collapse)(() => ({
  borderRadius: '0 0 8px 8px',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06)',
  background: COLORS.MONOCHROMATIC.GRAY1,
}));

export const FieldWrapper = muiStyled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const FilterButton = muiStyled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  background: COLORS.PRIMARY.BLUE.MAIN,
  border: `1px solid ${COLORS.PRIMARY.BLUE['-2']}`,
  borderRadius: '4px',
  padding: '6px',
  marginRight: '8px',
  cursor: 'default',

  '& .MuiTypography-root': {
    marginRight: '8px',
    fontWeight: 400,
    fontSize: '12px',
    color: COLORS.WHITE,
  },
}));
