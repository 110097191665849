import {
  FilterKeyEnum,
  FiltersConfigProps,
  Pill,
} from '@hapvida/hapvida-core-components';

import { COMPANY_USER_REGISTER_STATUS_DATA } from '@services/CompanySetupService/dtos/ListCompanyDTO';

import { COMPANY_STATUS_NAME, CompanyStatusEnum } from '@constants';

const companyStatusFilters = Object.entries(
  COMPANY_USER_REGISTER_STATUS_DATA,
).map(([key, value]) => {
  return {
    label: value.text,
    value: key,
    content: <Pill fitContent text={value.text} color={value.color} />,
  };
});

const statusCompanyFilterValues: FiltersConfigProps = {
  [FilterKeyEnum.USER_STATUS]: Object.values(CompanyStatusEnum).map(
    (statusType: CompanyStatusEnum) => {
      return {
        label: COMPANY_STATUS_NAME[statusType],
        value: statusType,
      };
    },
  ),
};

export { companyStatusFilters, statusCompanyFilterValues };
