import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { Container, Select } from './styles';
import ptBrLocale from 'date-fns/locale/pt-BR';

registerLocale('pt-BR', ptBrLocale);

interface DateRangePickerProps {
  startDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
  showYear?: boolean;
}

export const DateRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  showYear,
}: DateRangePickerProps) => {
  const today = new Date();
  const [year, setYear] = useState<number>(today.getFullYear());

  const onChange = useCallback((dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }, []);

  return (
    <Container>
      {showYear && (
        <Select onChange={e => setYear(Number(e.target.value))} value={year}>
          <option value={2022}>2022</option>
          <option value={2021}>2021</option>
          <option value={2020}>2020</option>
        </Select>
      )}

      <ReactDatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        maxDate={today}
        selectsRange
        inline
        monthsShown={2}
        locale="pt-BR"
      />
    </Container>
  );
};
