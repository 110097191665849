import { Stack, Typography } from '@mui/material';

import { AlertCard, useConfirmation } from '@hapvida/hapvida-core-components';

import { UserRedirectsEnum } from '@services/UserService';

import { MovementStatusEnum } from '@constants';
import { useRedirectUser } from '@hooks';

import { MOV_STATUS_DATA } from './movementStatusData';
import type { CallOutProps } from './types';
import { RedirectButton } from './styles';

export function CallOut({ status }: Readonly<CallOutProps>) {
  const { redirectUser } = useRedirectUser();
  const { showConfirmation } = useConfirmation();

  const handleRedirect = () => {
    redirectUser(UserRedirectsEnum?.REVIEWS_SCREEN_MOVEMENT);
  };

  const showConfirmExport = () => {
    showConfirmation({
      title: 'Lista de pendências',
      icon: 'alert',
      type: 'default',
      confirmText: 'Acessar portal',
      cancelText: 'Fechar',
      onConfirm: handleRedirect,
      content: (
        <Stack gap={2}>
          <Stack>
            <Typography variant="text">
              Para acessar a{' '}
              <Typography variant="text" fontWeight={700}>
                Lista de pendências
              </Typography>
              , clique em “Acessar portal” para ser direcionado para uma nova
              pagina.
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="text">
              Importante: você verá todas as pendências de movimentações por
              tela (digitação) e também via arquivo (layout).
            </Typography>
          </Stack>
        </Stack>
      ),
    });
  };

  const isProcessWithPendency =
    status === MovementStatusEnum?.PROCESS_FILE_WITH_PENDENCY;

  return (
    <>
      {status !== MovementStatusEnum?.PROCESS_FILE && (
        <AlertCard
          type={MOV_STATUS_DATA()[status]?.type as any}
          title={MOV_STATUS_DATA()[status]?.title}
          content={MOV_STATUS_DATA()[status]?.subtitle}
          button={
            isProcessWithPendency && (
              <RedirectButton
                variant="contained"
                color="secondary"
                size="large"
                onClick={showConfirmExport}
              >
                Lista de pendências
              </RedirectButton>
            )
          }
        />
      )}
    </>
  );
}
