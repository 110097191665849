import { Avatar as MuiAvatar } from '@mui/material';
import { useCallback } from 'react';
import type { AvatarProps } from './types';

export function Avatar({ name, src }: Readonly<AvatarProps>) {
  const renderUserInitials = useCallback(() => {
    if (name) {
      const [firstName, lastName] = name.split(' ');

      if (firstName && lastName) {
        return `${name[0]}${lastName[0]}`;
      }

      if (firstName) {
        return name[0];
      }
    }
  }, [name]);

  return (
    <MuiAvatar src={src} alt={name}>
      {renderUserInitials()}
    </MuiAvatar>
  );
}
