import { styled as muiStyled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';

import Typography from '../../../../Typography';
import { COLORS } from '../../../../../themes/colors';

export const ErrorRow = muiStyled(TableRow)(() => ({
  '&.MuiTableRow-root': {
    background:
      'linear-gradient(0deg, rgba(201, 54, 0, 0.04), rgba(201, 54, 0, 0.04)), #FFFFFF',

    borderBottom: `2px solid ${COLORS.SECONDARY.RED['-1']}`,
  },
}));

export const ErrorMessageBox = muiStyled(Box)(() => ({
  '&.MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',

    padding: '8px',
    borderRadius: '4px',
    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${COLORS.ERROR.MAIN}`,
  },
}));

export const Message = muiStyled(Typography)(() => ({
  '&.MuiTypography-root': {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    textTransform: 'uppercase',
  },
}));
