import React from 'react';
import { TableCellProps } from '@mui/material';
import { StyledTableCell } from './styles';

interface TableBodyCellProps extends TableCellProps {}

const TableBodyCell: React.FC<TableBodyCellProps> = ({
  children,
  ...props
}) => {
  return <StyledTableCell {...props}>{children}</StyledTableCell>;
};

export default TableBodyCell;
