import styled from 'styled-components';
import { COLORS } from '../../themes/colors';

export const RedirectCard = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 36px;
  margin-bottom: 36px;
  min-height: 240px;
  min-width: 266px;
  max-height: max-content;
`;

export const CardInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  img {
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
  }

  strong {
    color: ${COLORS.MONOCHROMATIC.GRAY4};
  }

  p {
    font-size: 14px;
  }
`;
