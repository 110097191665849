import { styled as muiStyled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { COLORS } from '../../../../themes/colors';

interface TextProps {
  uppercase?: boolean;
}

export const User = muiStyled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const Text = muiStyled(Typography, {
  shouldForwardProp: prop => prop !== 'uppercase',
})<TextProps>(({ uppercase }) => ({
  '&.MuiTypography-root': {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeigh: '0.9375rem',

    color: COLORS.MONOCHROMATIC.GRAY4,

    textTransform: uppercase ? 'uppercase' : 'none',
  },
}));

export const Dot = muiStyled(Box)(() => ({
  '&.MuiBox-root': {
    background: 'transparent',

    width: '1rem',
    height: '1rem',

    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',

    color: COLORS.MONOCHROMATIC.GRAY4,
  },
}));
