import React from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';

interface Props extends CheckboxProps {
  label?: string;
  innerRef?: any;
}

const Checkbox: React.FC<Props> = ({ innerRef, ref, ...props }) => {
  return (
    <MuiCheckbox disableRipple ref={innerRef ? innerRef : ref} {...props} />
  );
};

export default Checkbox;
