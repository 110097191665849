import { COLORS } from '../../../../../../themes/colors';
import { TableCell, TableRow, styled as muiStyled } from '@mui/material';
import type { MainRowProps } from './types';

const MainRow = muiStyled(TableRow)<MainRowProps>(
  ({ ithaserror, ithaserrormessage, collapsed }) => ({
    '&.MuiTableRow-root': {
      background:
        ithaserror === 'true'
          ? COLORS.LINEAR_GRADIENT.GRAY2
          : collapsed === 'true'
          ? COLORS.LINEAR_GRADIENT.GRAY3
          : '',

      borderBottom:
        ithaserror === 'true' && ithaserrormessage === 'true'
          ? '1px solid transparent'
          : ithaserror === 'true' && ithaserrormessage === 'false'
          ? `transparent`
          : collapsed === 'true'
          ? `1px solid ${COLORS.PRIMARY.ORANGE.MAIN}`
          : `1px solid ${COLORS.MONOCHROMATIC.GRAY2}`,
      borderTop:
        ithaserror === 'true' && ithaserrormessage === 'true'
          ? '1px solid transparent'
          : ithaserror === 'true' && ithaserrormessage === 'false'
          ? `1px solid ${COLORS.SECONDARY.RED['-1']}`
          : collapsed === 'true'
          ? `1px solid ${COLORS.PRIMARY.ORANGE.MAIN}`
          : `1px solid ${COLORS.MONOCHROMATIC.GRAY2}`,

      '&:hover': {
        background: ithaserror === 'false' && COLORS.LINEAR_GRADIENT.GRAY3,

        borderBottom:
          ithaserror === 'true' && ithaserrormessage === 'true'
            ? '1px solid transparent'
            : ithaserror === 'true' && ithaserrormessage === 'false'
            ? `transparent`
            : `1px solid ${COLORS.PRIMARY.ORANGE.MAIN}`,
      },
    },
  }),
);

const TableCellNew = muiStyled(TableCell)(() => ({
  borderBottom: 'none',
}));

export { TableCellNew, MainRow };
