import { Icon } from '@hapvida/hapvida-core-components';

import type { IAppRoute } from 'routes/types';

import { MovementByScreenPage } from './index';

export const MovementByScreenRouter: IAppRoute = {
  Page: MovementByScreenPage,
  path: '/movimentacoes-via-tela',
  name: 'Via tela (digitação)',
  isPrivate: true,
  Icon: <Icon name="clipboard-text-outline" />,
};
