import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  styled,
} from '@mui/material';
import { COLORS } from '../../themes/colors';

interface IAccordion extends AccordionProps {
  hasCheckbox?: boolean;
  checkSelected?: boolean;
  theme?: any;
}

interface ISummary extends AccordionSummaryProps {
  hasCheckbox?: boolean;
  checkSelected?: boolean;
}

interface IDetails extends AccordionDetailsProps {
  hasCheckbox?: boolean;
}

export const StyledAccordion = styled(
  ({ hasCheckbox, checkSelected, theme, ...props }: IAccordion) => (
    <Accordion {...props} />
  ),
)<IAccordion>(({ hasCheckbox, checkSelected, theme: { transitions } }) => ({
  transition: transitions.create('all'),
  background: checkSelected
    ? `linear-gradient(0deg, rgba(244, 158, 0, 0.08), rgba(244, 158, 0, 0.08)), ${COLORS.WHITE}`
    : `${COLORS.MONOCHROMATIC.GRAY1}`,
  '&.Mui-expanded': {
    background: checkSelected
      ? `linear-gradient(0deg, rgba(244, 158, 0, 0.08), rgba(244, 158, 0, 0.08)), ${COLORS.WHITE}`
      : `${COLORS.MONOCHROMATIC.GRAY1}`,
    borderColor: COLORS.PRIMARY.ORANGE.MAIN,
    margin: hasCheckbox ? '0 0 16px' : 'unset',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    alignSelf: 'flex-start',
    marginTop: 16,
  },
  border: hasCheckbox ? 'none' : 'inherit',

  '&:before': {
    background: hasCheckbox ? 'none' : 'inherit',
  },
  marginBottom: hasCheckbox ? '16px' : 'unset',
}));

export const StyledAccordionSummary = styled(
  ({ hasCheckbox, ...props }: ISummary) => <AccordionSummary {...props} />,
)<ISummary>(({ hasCheckbox }) => ({
  display: 'flex',
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '12px 0 14px',
  },
  padding: hasCheckbox ? '0px 10px' : '0',
}));

export const AccordionHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

export const AccordionTitle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const AccordionSubtitle = styled(Box)(() => ({
  marginTop: 16,
}));

export const AccordionDetailsStyled = styled(
  ({ hasCheckbox, ...props }: IDetails) => <AccordionDetails {...props} />,
)<IDetails>(({ hasCheckbox }) => ({
  padding: hasCheckbox ? '0 2px 16px' : '0',
}));
