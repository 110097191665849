import { PrintDiscountRouter } from '@flows/private/financial/pages/PrintDiscount/router';

export const downloadDiscountLetter = async (
  billId: string,
  invoiceNumber?: number,
  fetchDiscountLetters?: (invoiceNumber?: number) => Promise<any>,
) => {
  const handleNavigate = (letterNumber?: string, id?: string) => {
    return window.open(
      PrintDiscountRouter.dynamicPath({
        billNumber: id,
        letterNumber,
        invoiceNumber,
      }),
      '_blank',
    );
  };
  const letters = await fetchDiscountLetters?.(invoiceNumber);
  letters?.forEach((letter: any) => {
    handleNavigate(letter.letterNumber, billId);
  });
};
