import { Stack, Typography } from '@mui/material';

import { useSidebar } from '../../hooks';
import { RouteItemGroup, ExpandIcon } from './styles';
import { useState } from 'react';
import { RouteIcon } from '../../styles';
import { SidebarSubItem } from './components';
import type { SidebarItemGroupProps } from './types';

export function SidebarItemGroup({
  isSelected = false,
  disabled = false,
  label,
  iconName,
  iconComponent,
  items,
  ...stackProps
}: Readonly<SidebarItemGroupProps>) {
  const [expanded, setExpanded] = useState<boolean>(isSelected);
  const { drawerOpen } = useSidebar();

  const hasIconComponent = !iconName && iconComponent;

  const chevronIcon = expanded ? 'chevron-up' : 'chevron-down';

  const handleClick = () => {
    if (disabled) return;
    setExpanded(!expanded);
  };

  return (
    <Stack gap={0.5}>
      <RouteItemGroup
        onDoubleClick={e => e.preventDefault()}
        justifyContent={drawerOpen ? 'flex-start' : 'center'}
        direction={drawerOpen ? 'row' : 'column'}
        selected={isSelected}
        disabled={disabled}
        onClick={handleClick}
        {...stackProps}
      >
        <Stack gap={1} alignItems="center" flexDirection="row">
          {iconName && (
            <RouteIcon
              selected={isSelected}
              disabled={disabled}
              name={iconName}
              size={24}
            />
          )}
          {hasIconComponent && iconComponent}

          {drawerOpen && <Typography variant="body2">{label}</Typography>}
        </Stack>

        <ExpandIcon name={chevronIcon} size={24} />
      </RouteItemGroup>
      {expanded && (
        <Stack gap={1}>
          {items.map(item => (
            <SidebarSubItem key={item.label} {...item} />
          ))}
        </Stack>
      )}
    </Stack>
  );
}
