import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import type { CompanyProps } from '@services/AuthService/dtos';

import { useAuth } from '@hooks';
import { PartnerCompanyListRouter } from '@flows/private/partnerCompanies/pages/PartnerCompanyList/router';

export function useSelectCompany() {
  const { updateAccount } = useAuth();
  const navigate = useNavigate();

  const handleSelectCompany = useCallback(
    async (selectedCompany?: CompanyProps) => {
      const updatedAccount = {
        selectedCompany,
        selectedPartnerCompany: undefined,
      };

      updateAccount(updatedAccount);

      if (selectedCompany) {
        navigate(PartnerCompanyListRouter.path);
      }
    },
    [navigate],
  );

  return {
    handleSelectCompany,
  };
}
