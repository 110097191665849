import styled, { css } from 'styled-components';
import { COLORS } from '../../themes/colors';
import { textColorByBackground } from '../../utils/textColorByBackground';
import IcoMoon from 'react-icomoon';

export const MirrorContainer = styled.div`
  transform: scaleX(-1);
  width: fit-content;
`;

interface StyledIconProps {
  variant: 'outlined' | 'standart' | 'filled';
  color?: string;
}

export const StyledIcon = styled(IcoMoon)<StyledIconProps>`
  ${({ onClick, size }) => css`
    min-width: ${size}px;
    cursor: ${Boolean(onClick) ? 'pointer' : 'inherit'};
  `}

  ${({ variant, color }) =>
    variant === 'outlined' &&
    css`
      border: 1px solid ${color ?? COLORS.MONOCHROMATIC.GRAY3};
      color: ${color ?? COLORS.MONOCHROMATIC.GRAY3};
      padding: 4px;
      border-radius: 50%;
    `}

  ${({ variant, color }) =>
    variant === 'filled' &&
    css`
      background-color: ${color ? color : COLORS.MONOCHROMATIC.GRAY3};
      color: ${textColorByBackground(color)} !important;
      padding: 4px;
      border-radius: 50%;
    `}
`;
