import { useState } from 'react';

export function useModalStates() {
  const [reviewsScreenModalState, setReviewsScreenModalState] =
    useState<boolean>(false);

  const [movementStatusModalState, setMovementStatusModalState] =
    useState<boolean>(false);

  const [noReviewsScreenModalState, setNoReviewsScreenModalState] =
    useState<boolean>(false);

  const toggleReviewsScreenModal = () => {
    setReviewsScreenModalState(!reviewsScreenModalState);
  };

  const toggleNoReviewsScreenModal = () => {
    setNoReviewsScreenModalState(!noReviewsScreenModalState);
  };

  const toggleMovementStatusModal = () => {
    setMovementStatusModalState(!movementStatusModalState);
  };

  return {
    reviewsScreenModalState,
    movementStatusModalState,
    noReviewsScreenModalState,
    toggleReviewsScreenModal,
    toggleNoReviewsScreenModal,
    toggleMovementStatusModal,
  };
}
