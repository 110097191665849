import { Stack, Typography } from '@mui/material';
import { memo } from 'react';

import { Icon, COLORS } from '@hapvida/hapvida-core-components';

export const SelectionError = memo(() => (
  <Stack
    my={2}
    justifyContent="center"
    alignItems="center"
    flexDirection="row"
    gap={0.5}
  >
    <Icon name="alert-circle" size={20} color={COLORS.SECONDARY.RED['+1']} />
    <Typography variant="text">
      Não foi possível carregar os contratos.
    </Typography>
  </Stack>
));
