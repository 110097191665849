import { TableBody, Box } from '@mui/material';

import { COLORS } from '../../../themes/colors';

import BaseTable from '../../BaseTable';
import { PulsarAnimationLoading } from '../../PulsarAnimation';

import { Container, Message } from './styles';

import { CollapseProps } from './types';
import { Rows } from './components/Rows';

export function Collapse({
  data,
  TableHeadColumns,
  tableOrder,
  loading,
  loadingMessage = 'Loading...',
  noDataMessage = 'Sem dados',
  noDataImage,
  onOrderChange,
  onPageChange,
  baseTableProps,
  onEdit,
  onEditChild,
  from,
  to,
  count,
  width,
  hasCellAction,
  showFooter,
  rowColor,
  currentPage = 1,
}: Readonly<CollapseProps>) {
  return (
    <>
      {loading && (
        <Container>
          <PulsarAnimationLoading
            width="30px"
            height="30px"
            color={COLORS.PRIMARY.ORANGE.MAIN}
          />
          <br />
          <Message>{loadingMessage}</Message>
        </Container>
      )}

      {!loading && data.length === 0 && (
        <Container>
          <Box sx={{ marginBottom: 5 }}>
            <img src={noDataImage} alt="Sem dados" />
          </Box>
          <Message>{noDataMessage}</Message>
        </Container>
      )}

      {!loading && data.length > 0 && (
        <BaseTable
          {...baseTableProps}
          width={width}
          count={count ?? 0}
          from={from ?? 0}
          to={to ?? 0}
          currentPage={currentPage}
          tableOrder={tableOrder}
          onOrderChange={onOrderChange}
          onPageChange={onPageChange}
          TableHeadColumns={TableHeadColumns}
          hasCellAction={hasCellAction}
          showFooter={showFooter}
        >
          <TableBody>
            {data?.map(item => (
              <Rows
                key={item.id}
                item={item}
                TableHeadColumns={TableHeadColumns}
                onEdit={onEdit}
                onEditChild={onEditChild}
                hasCellAction={hasCellAction}
                rowColor={rowColor}
              />
            ))}
          </TableBody>
        </BaseTable>
      )}
    </>
  );
}
