import { Stack, StackProps } from '@mui/material';
import styled, { css } from 'styled-components';

interface ContainerProps extends StackProps {
  statusColor?: string;
}
export const Container = styled(({ statusColor, ...rest }: ContainerProps) => (
  <Stack {...rest} />
))<ContainerProps>`
  ${({ statusColor }) =>
    statusColor &&
    css`
      color: ${statusColor};
      .MuiTypography-root {
        color: ${statusColor};
      }
    `}
`;

// import { Grid } from "@mui/material";
// import styled from "styled-components";

// export const Container = styled(Grid)`
// 	padding: 24px;
// 	border-radius: 8px;
// `;
