import { Fragment, useCallback } from 'react';

import { Mask, useConfirmation } from '@hapvida/hapvida-core-components';

import { useAuth } from '@hooks';
import { MovementShippingReviewsContent } from '@flows/private/movements/components';

import { CustomTableCell, CustomTableRow, DateText } from './styles';
import MovimentType from '../MovimentType';
import type { TableRowProps } from './types';
import { TotalShippingReviews } from '../TotalShippingReviews';
import { EditButton } from '../EditButton';
import { StatusPill } from '../StatusPill';
import { MovimentationNumber } from '../MovimentationNumber';

export function TableRows({ movimentations }: Readonly<TableRowProps>) {
  const { showConfirmation } = useConfirmation();
  const { selectedCompany } = useAuth();

  const companyId = selectedCompany?.id ?? '';

  const handleOpenLoadValidation = useCallback((movementId: string) => {
    showConfirmation({
      title: 'Erros encontrados no arquivo',
      icon: 'alert',
      type: 'default',
      cancelText: 'Fechar',
      content: (
        <MovementShippingReviewsContent
          movementId={movementId}
          companyId={companyId}
        />
      ),
    });
  }, []);

  return (
    <>
      {movimentations?.map(movimentation => (
        <Fragment key={movimentation?.id}>
          <CustomTableRow
            totalShippingReviews={movimentation?.totalShippingReviews}
          >
            <MovimentationNumber
              selectedCompany={selectedCompany}
              number={movimentation?.number}
              uploadName={movimentation?.uploadName}
              fileName={movimentation?.fileName}
            />

            <CustomTableCell align="left">
              <MovimentType movimentType={movimentation?.type} />
            </CustomTableCell>

            <CustomTableCell align="left">
              <DateText>{Mask.date(movimentation?.createdAt, true)}</DateText>
            </CustomTableCell>

            <CustomTableCell align="left">
              <DateText>{Mask.date(movimentation?.updatedAt, true)}</DateText>
            </CustomTableCell>

            <StatusPill status={movimentation?.status} />

            <EditButton id={movimentation?.id} />
          </CustomTableRow>

          <TotalShippingReviews
            id={movimentation?.id}
            totalShippingReviews={movimentation?.totalShippingReviews}
            handleOpenLoadValidation={handleOpenLoadValidation}
          />
        </Fragment>
      ))}
    </>
  );
}
