import { Slider, styled as muiStyled } from '@mui/material';
import { COLORS } from '../../themes/colors';

export const SliderContainer = muiStyled('div')(() => ({
  height: 60,
  display: 'flex',
  alignItems: 'center',
  padding: '16px 16px 0 16px',
}));

export const StyledSlider = muiStyled(Slider)(() => ({
  height: 7,
  padding: 0,
  marginTop: 12,
  color: 'white',
  border: `1px solid ${COLORS.PRIMARY.ORANGE.MAIN}`,
  '.MuiSlider-thumb': {
    border: `1px solid ${COLORS.PRIMARY.ORANGE.MAIN}`,
    height: 30,
    width: 30,
    ':hover': {
      boxShadow: 'none',
    },
  },
  '.MuiSlider-markLabelActive': {
    fontWeight: 'bold',
  },

  '.Mui-focusVisible': {
    boxShadow: 'none',
  },
  '.MuiSlider-mark': {
    display: 'none !important',
  },
  '.MuiSlider-rail': {
    opacity: 0,
  },
  '.MuiSlider-track': {
    color: COLORS.PRIMARY.ORANGE.MAIN,
  },

  '.MuiSlider-markLabel': {
    bottom: '24px',
    top: 'unset',
  },
}));
