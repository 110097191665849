import React, { useRef } from 'react';
import { Grid, Stack } from '@mui/material';

import Typography from '../Typography';

import { Username, Container, LogoutButton, MobileHiddenStack } from './styles';
import Icon from '../Icon';
import { Avatar } from '../Avatar';
import type { UserMenuProps } from './types';

const UserMenu: React.FC<UserMenuProps> = ({
  username,
  role,
  url,
  onLogOut,
}) => {
  const chevronRef = useRef(null);

  return (
    <Container
      item
      container
      alignItems="center"
      justifyContent="flex-end"
      marginLeft={1}
    >
      <Avatar src={url} name={username} />
      <Grid item marginLeft={1}>
        <Stack ref={chevronRef} gap={2} direction="row" alignItems="center">
          <MobileHiddenStack>
            <Username variant="subtitle1">{username}</Username>
            <Typography variant="smallText">{role}</Typography>
          </MobileHiddenStack>
          <Stack>
            <LogoutButton
              variant="text"
              startIcon={<Icon name="side-bar-open" />}
              onClick={onLogOut}
            >
              Sair
            </LogoutButton>
          </Stack>
        </Stack>
      </Grid>
    </Container>
  );
};

export default UserMenu;
