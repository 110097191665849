import { UserRedirectsEnum } from '@services/UserService';

import type { IAppSubRoute } from 'routes/types';

import { BENEFICIARY_REDIRECTS } from '.';

const AuthenticateCertificateRouter: IAppSubRoute = {
  name: 'Autenticar atestado',
  path: UserRedirectsEnum.AUTHENTICATE_ATTESTATION,
  isPrivate: true,
  sidebarModalProps: BENEFICIARY_REDIRECTS.authenticateAttestation,
};

const OnlineCardRouter: IAppSubRoute = {
  name: 'Carteirinha Digital',
  path: UserRedirectsEnum.ONLINE_CARD,
  isPrivate: true,
  sidebarModalProps: BENEFICIARY_REDIRECTS.onlineCard,
};

const SuspendedBeneficiaryRouter: IAppSubRoute = {
  name: 'Beneficiários suspensos',
  path: UserRedirectsEnum.SUSPENDED_BENEFICIARY,
  isPrivate: true,
  sidebarModalProps: BENEFICIARY_REDIRECTS.suspendedBeneficiary,
  optionalRedirect: UserRedirectsEnum.SUSPENDED_BENEFICIARY,
};

export {
  AuthenticateCertificateRouter,
  OnlineCardRouter,
  SuspendedBeneficiaryRouter,
};
