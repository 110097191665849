import Box from '@mui/material/Box';

import { styled as muiStyled } from '@mui/material/styles';

import styled from 'styled-components';

import { Typography } from '@hapvida/hapvida-core-components';

import { COLORS } from '../../../../../themes/colors';

export const InfoContainer = styled(Box)`
  margin-top: 130px;

  display: flex;
  flex-direction: column;
`;

export const StyledTypography = muiStyled(Typography)(() => ({
  '&.MuiTypography-root': {
    color: COLORS.WHITE,
  },
}));
