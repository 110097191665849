import { Stack, Typography } from '@mui/material';

import { COLORS, Icon } from '@hapvida/hapvida-core-components';

export function CantEditMasterAlert() {
  return (
    <Stack
      paddingX={2}
      paddingY={1}
      gap={2}
      flexDirection="row"
      alignItems="center"
      bgcolor={COLORS.SECONDARY.BLUE['-2']}
    >
      <Icon name="alert-circle" color={COLORS.PRIMARY.BLUE.MAIN} />
      <Typography variant="smallText">
        Você não tem permissão para editar o usuário{' '}
        <Typography variant="smallText" fontWeight={700}>
          Master
        </Typography>
        . Você pode adicionar um novo perfil de usuário clicando no botão
        abaixo, e atribuir outros contratos para serem gerenciados por esse
        usuário.
      </Typography>
    </Stack>
  );
}
