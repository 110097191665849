import { styled as muiStyled, Stack } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const Circle = muiStyled(Stack)({
  backgroundColor: COLORS.PRIMARY.ORANGE.MAIN,
  minWidth: '8px',
  height: '8px',
  borderRadius: '50%',
});

export const StyledStack = muiStyled(Stack)({
  overflow: 'auto',
  maxHeight: '250px',
  borderRadius: '4px',
  border: `1px solid${COLORS.MONOCHROMATIC.GRAY2}`,
  padding: '8px',
});
