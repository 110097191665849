import {
  FilterKeyEnum,
  SearchParamEnum,
} from '@hapvida/hapvida-core-components';

import { HealthOperatorEnum } from '@constants';

import type {
  CoparticipationReportProps,
  InvoiceProps,
  FileData,
} from './BillDetailsDTO';

interface Bill {
  coparticipationAnalytical: CoparticipationReportProps[];
  healthOperator: HealthOperatorEnum;
  analyticalStatements: FileData[];
  verificationCode?: string;
  companyDocument?: string;
  partnerCompany: string;
  invoice?: InvoiceProps;
  billingNumber: string;
  personNumber: number;
  companyId: string;
  billType: string;
  dueDate: string;
  amount: number;
  status: string;
  number: string;
  month: string;
  year: string;
}

interface GetMonthlyAnalyticalReport {
  createdAt?: string;
  path: string;
  fileName: string;
  size?: string;
  uploadName?: string;
}

interface GetBillListRequestDTO {
  companyId: string;
  limit: number;
  offset: number;
  [SearchParamEnum.ORDER_BY]?: string;
  [SearchParamEnum.SORT_BY]?: string;
  search?: string;
  [FilterKeyEnum.START_DATE]?: string;
  [FilterKeyEnum.END_DATE]?: string;
  [FilterKeyEnum.HEALTH_OPERATOR]?: string;
  [FilterKeyEnum.ORGANIZATIONAL_GROUP]?: string;
}

interface BillListProps {
  totalPerPage: number;
  bills: Bill[];
  total: number;
}

interface GetBillListResponseDTO {
  content: BillListProps;
  timestamp: number;
  traceId: string;
  notifications: any[];
}

const BillType: { [x: string]: string } = {
  OperationalCost: 'Custo Operacional',
  SingleOperatingCost: 'Custo Operacional Avulso',
  MonthlyPayment: 'Mensalidade',
  SingleMonthlyFee: 'Mensalidade Avulso',
  Participation: 'Participação',
  SingleParticipation: 'Participação Avulso',
  Prepayment: 'Pré-Pagamento',
  SinglePrepayment: 'Pré-Pagamento Avulso',
  RetroactiveReadjustment: 'Reajuste Retroativo',
  ClaimRecovery: 'Recuperação de Sinistro',
  SingleClaimRecovery: 'Recuperação de Sinistro Avulso',
  OccupationalHealth: 'Saúde Ocupacional',
  ValuesCooperatedAct: 'Valores Ato Cooperado',
  CarnetComplement: 'Carnet Complementar-coparticipação',
  HealthPlanMonthlyFee: 'Mensalidade Plano Saúde',
  ExtraInvoice: 'Fatura extra',
  FirstMonthlyHealthPlan: 'Primeira Mensalidade Plano Saúde',
  SpecialCommission: 'Comissão Especial',
  NfCommissionAdvance: 'Nf Adiantamento De Comissão',
  CltCommission: 'Comissão Clt',
  SalesCommission: 'Comissão De Vendas',
  CommissionAdvance: 'Adiantamento De Comissão',
  CustomerRefunc: 'Reembolso Cliente',
  SpecialPendingAdvance: 'Adiantamento De Pendencia Especial',
  PaymentOfMedicalPayroll: 'Pagamento De Folha Medica',
  DuplicateDentalService: 'Duplicata Serviço Odontologia',
  TradeDuplicate: 'Duplicata Mercantil',
  PromissoryNotes: 'Notas Promissória',
  InvoiceNote: 'Nota De Seguro',
  Receipt: 'Recibo',
  BillOfExchange: 'Letra De Cambio',
  Leasing: 'Leasing',
  Cheeck: 'Cheque',
  Contract: 'Contrato',
  ServiceDuplicate: 'Duplicata De Serviço',
  DebitNote: 'Nota De Debito',
  BankingTitle: 'Titulo Bancário',
  Request: 'Pedido',
  Invoice: 'Nota Fiscal',
  HealthPlanMembershipFee: 'Taxa De Adesão Plano Saúde',
  PlanChange: 'Mudança De Plano',
  DependeentInclusion: 'Inclusão De Dependente',
  DentalAdhesionFeee: 'Taxa Adesão Odontologia',
  PlanCancellation: 'Cancelamento Do Plano',
  Others: 'Outros',
  ProceedureCapitalization: 'Capitalização De Procedimento',
  FriendSellerCommission: 'Comissão Vendedor Amigo',
  PersonnelExpenses: 'Despesas De Pessoal',
  Tax: 'Impostos',
  Advancee: 'Adiantamento',
  Rpa: 'Rpa',
  ExpensesRefund: 'Reembolso De Despesas',
  JuridicalProcesses: 'Processos Jurídicos',
  OnlineSales: 'Vendas Online',
  SupplementaryInvoice: 'Fatura Complementar',
};

export {
  Bill,
  GetMonthlyAnalyticalReport,
  GetBillListRequestDTO,
  BillListProps,
  GetBillListResponseDTO,
  BillType,
};
