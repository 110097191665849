import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import IDragAndDropFile from '@hapvida/hapvida-core-components/src/components/DragAndDrop/dtos/IDragDropFile';

import MovimentationService from '@services/MovimentationService';

import { toastifyApiErrors } from '@utils';
import { useAuth, useScreenLoading } from '@hooks';
import { MovimentationStepRouter } from '@flows/private/movements/pages/MovimentationSteps/router';
import { MOVIMENTATION_STEPS_KEYS } from 'constants/private/movements/newMovementSteps';

export const useSubmitMovimentation = () => {
  const { onScreenLoading } = useScreenLoading();
  const { selectedCompany } = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const navigateTo = useNavigate();

  const companyId = selectedCompany?.id;

  const handleSubmitMovimentation = useCallback(
    async (file?: IDragAndDropFile) => {
      try {
        if (!file || !companyId) {
          throw new Error('Não foi possível enviar a movimentação');
        }
        onScreenLoading(true);

        const movimentationService = new MovimentationService();

        const response = await movimentationService.createMovimentation({
          ...file,
          companyId,
        });

        if (response) {
          navigateTo(
            MovimentationStepRouter.dynamicPath({
              movimentationStep: MOVIMENTATION_STEPS_KEYS.CONFIRMATION,
            }),
          );
        }
      } catch (error) {
        toastifyApiErrors(error, enqueueSnackbar);
      } finally {
        onScreenLoading(false);
      }
    },
    [navigateTo, enqueueSnackbar],
  );

  return {
    handleSubmitMovimentation,
  };
};
