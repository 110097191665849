import { Loading, SummaryCardNew } from '@hapvida/hapvida-core-components';

import {
  MOVEMENT_SUMMARY_ITEMS,
  BeneficiaryMovementTypeEnum,
} from '@constants';

import { useMovementsState } from '../../hooks';

export function MovementsSummaryCard() {
  const { movementsSummaryState } = useMovementsState();

  if (movementsSummaryState?.isLoading) {
    return (
      <SummaryCardNew.Container>
        <Loading minHeight={200} alignItems="center" justifyContent="center" />
      </SummaryCardNew.Container>
    );
  }

  if (movementsSummaryState.isError) {
    return null;
  }

  const summaryValues = movementsSummaryState.data.reduce(
    (acc, movementSummary) => {
      if (
        Object.values(BeneficiaryMovementTypeEnum).includes(
          movementSummary.code,
        )
      ) {
        acc[movementSummary.code] = movementSummary.count;
      }
      return acc;
    },
    {} as Record<BeneficiaryMovementTypeEnum, number>,
  );

  return (
    <SummaryCardNew.Container>
      <SummaryCardNew.Header title="Resumo de movimentações" />
      <SummaryCardNew.Content
        summary={MOVEMENT_SUMMARY_ITEMS}
        summaryValues={summaryValues}
        defaultValue="-"
        perLine={5}
      />
    </SummaryCardNew.Container>
  );
}
