import { Loading, SummaryCardNew } from '@hapvida/hapvida-core-components';

import { useBillsState } from '../../hooks/useBillsState';
import { Values } from './components';

export function BillsSummaryCard() {
  const { billSummariesState } = useBillsState();

  if (billSummariesState?.isLoading) {
    return (
      <SummaryCardNew.Container>
        <Loading minHeight={200} alignItems="center" justifyContent="center" />
      </SummaryCardNew.Container>
    );
  }

  if (billSummariesState?.isError) {
    return null;
  }

  const totalPaidOperators =
    billSummariesState.data?.reduce(
      (accumulator, currentValue) => accumulator + currentValue.paidTotal,
      0,
    ) ?? 0;

  const totalRemainingOperators =
    billSummariesState.data?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.outstandingTotal,
      0,
    ) ?? 0;

  return (
    <Values
      totalRemaining={totalRemainingOperators}
      totalPaid={totalPaidOperators}
      title="Resumo de faturas"
    />
  );
}
