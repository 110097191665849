import { Typography } from '@mui/material';

import { Modal } from '@hapvida/hapvida-core-components';

import { UserRedirectsEnum } from '@services/UserService';

import { useRedirectUser } from '@hooks';

import type { ReviewsScreenModalProps } from './types';

export function ReviewsScreenModal({
  modalState,
  toggleModal,
}: Readonly<ReviewsScreenModalProps>) {
  const { redirectUser } = useRedirectUser();

  const handleRedirect = () => {
    redirectUser(UserRedirectsEnum.REVIEWS_SCREEN_MOVEMENT);
    toggleModal();
  };

  return (
    <Modal
      open={modalState}
      icon="alert"
      type="default"
      handleCloseModal={toggleModal}
      title="Críticas de Movimentação"
      mainButtonText="Acessar portal"
      secondaryButtonText="Fechar"
      mainAction={handleRedirect}
    >
      <Typography variant="text" mb={3}>
        Para acessar todas as{' '}
        <Typography variant="text" fontWeight={700}>
          Críticas de Movimentação
        </Typography>
        , clique em “Acessar portal” para ser direcionado para uma nova pagina.{' '}
      </Typography>
      <Typography variant="text">
        Importante: Caso existam, você verá todas as críticas de movimentações
        por tela (digitação) e também via layout.
      </Typography>
    </Modal>
  );
}
