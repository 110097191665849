import { Typography, Grid } from '@mui/material';
import { useState } from 'react';

import { Button } from '@hapvida/hapvida-core-components';

import { SubHeader } from '@components';

import { MovementRedirectModal } from './components/MovementRedirectModal';

export function MovementByScreenSubHeader() {
  const [movementByScreenModalState, setMovementByScreenModalState] =
    useState<boolean>(false);

  const toggleMovementByScreenModal = () => {
    setMovementByScreenModalState(!movementByScreenModalState);
  };

  return (
    <SubHeader>
      <Grid container spacing={2}>
        <Grid alignContent="center" item md={10}>
          <Typography variant="h5">
            Movimentação por tela (digitação)
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Button
            onClick={toggleMovementByScreenModal}
            variant="contained"
            color="success"
            disableRipple
            fullWidth
          >
            Nova movimentação
          </Button>
        </Grid>
      </Grid>
      <MovementRedirectModal
        modalState={movementByScreenModalState}
        toggleModal={toggleMovementByScreenModal}
      />
    </SubHeader>
  );
}
