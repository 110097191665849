import { Typography } from '@mui/material';

import { ListGridColumnProps } from 'components/ListGrid/types';

enum PartnerCompanyListColumnNameEnum {
  PARTNER_COMPANY = 'partnerCompany',
  MAIN_PARTNER_COMPANY = 'mainPartnerCompany',
  CONTRACT_TYPE = 'contractType',
  PROFILE = 'profile',
  ACCESS_ACTION = 'accessAction',
}

const partnerCompanyListColumns: ListGridColumnProps[] = [
  {
    id: PartnerCompanyListColumnNameEnum.PARTNER_COMPANY,
    item: <Typography variant="text">Contrato</Typography>,
    gridSize: 4,
  },
  {
    id: PartnerCompanyListColumnNameEnum.MAIN_PARTNER_COMPANY,
    item: <Typography variant="text">Contrato mãe</Typography>,
    gridSize: 3,
  },
  {
    id: PartnerCompanyListColumnNameEnum.CONTRACT_TYPE,
    item: <Typography variant="text">Tipo de plano</Typography>,
    gridSize: 2,
  },
  {
    id: PartnerCompanyListColumnNameEnum.PROFILE,
    item: <Typography variant="text">Perfil de acesso</Typography>,
    gridSize: 2,
  },
  {
    id: PartnerCompanyListColumnNameEnum.ACCESS_ACTION,
    item: <></>,
  },
];

export { partnerCompanyListColumns, PartnerCompanyListColumnNameEnum };
