import { Button, Link } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from '../../themes/colors';

export const StyledLink = styled(Link)`
  flex: 1;
  text-decoration: none;
  color: ${COLORS.MONOCHROMATIC.GRAY4};
  padding: 8px 12px;
  background: ${COLORS.MONOCHROMATIC.GRAY1};
  border: 1px solid ${COLORS.PRIMARY.BLUE['-1']};
  width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledButton = styled(Button)(() => ({
  padding: '8px 24px',
  height: '40px',
}));
