import { Grid, styled as muiStyled, Typography } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Grid)`
  padding: 24px;
  border-radius: 8px;
`;

export const StatusTitle = muiStyled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '18px',
}));
