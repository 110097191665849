import { Stack, Typography } from '@mui/material';
import { useCallback } from 'react';

import { CountDown } from '@hapvida/hapvida-core-components';

import type { ResendEmailProps } from './types';
import { StyledButton, SvgContainer } from './styles';

export function ResendEmail({
  handleSubmit,
  handleStartPasswordRecovery,
  loadingState,
}: Readonly<ResendEmailProps>) {
  const handleGoToLoginPage = useCallback(() => {
    window.location.replace(process.env.REACT_APP_LOGIN_URL);
  }, []);

  return (
    <Stack gap={2}>
      <Typography variant="subtitle1" fontWeight={400}>
        Não recebeu o e-mail?
      </Typography>
      <SvgContainer>
        <CountDown
          unavaible={{
            loadingText: 'Aguarde',
            loadingInfo: 'para reenviar.',
          }}
          available={{
            clickText: loadingState ? 'Carregando' : 'Tentar novamente',
          }}
          onClick={handleSubmit(handleStartPasswordRecovery)}
          loading={loadingState}
          successMessage="E-mail enviado"
          fullWidth
          timer={600}
        />
      </SvgContainer>
      <StyledButton
        color="secondary"
        onClick={handleGoToLoginPage}
        variant="text"
        disableRipple
        fullWidth
      >
        Ir para o Login
      </StyledButton>
    </Stack>
  );
}
