import { StartPasswordRecoveryStepsEnum } from '@flows/public/pages/PasswordRecovery/StartPasswordRecovery/constants/steps';

export function getSteps(currentStep: StartPasswordRecoveryStepsEnum) {
  return [
    {
      label:
        'Digite seu e-mail: Enviaremos um link para você redefinir sua senha.',
      stepNumber: StartPasswordRecoveryStepsEnum.TYPE_EMAIL,
      completed: currentStep > StartPasswordRecoveryStepsEnum.TYPE_EMAIL,
    },
    {
      label:
        'Abra o e-mail: Clique no link para acessar a página de redefinição.',
      stepNumber: StartPasswordRecoveryStepsEnum.SEND_EMAIL,
      completed: currentStep > StartPasswordRecoveryStepsEnum.SEND_EMAIL,
    },
    {
      label:
        'Após acessar o link, defina uma nova senha e acesse o portal com as credenciais atualizadas.',
      stepNumber: StartPasswordRecoveryStepsEnum.SENDED_EMAIL,
    },
  ];
}
