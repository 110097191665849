import ConfirmationStep from './steps/Confirmation/step';
import MovimentationFileStep from './steps/MovimentationFile/step';
import type { IMovimentationStep } from './types';

const steps: Array<IMovimentationStep> = [
  MovimentationFileStep,
  ConfirmationStep,
];

export { steps, IMovimentationStep };
