import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { UserService } from '@services/UserService';

import { BackofficeUserProfileEnum } from 'constants/private/backofficeUsers/backofficeUserProfile';
import { BackofficeUserQueryKeysEnum } from '@constants';
import { toastifyApiErrors } from '@utils';
import { useCurrentUserState } from '@hooks';
import { UserActionsOptionsEnum } from '@flows/private/backofficeUser/pages/BackofficeUserDetails/components/BackofficeUserEditForm/constants/userActionsOptions';
import type { BackofficeUserDetailsFormProps } from '@flows/private/backofficeUser/pages/BackofficeUserDetails/components/BackofficeUserEditForm/types';
import { useUserActionsState } from '@flows/private/backofficeUser/pages/BackofficeUserDetails/components/BackofficeUserEditForm/hooks';

import type { EditUserProps } from './types';

export function useEditBackofficeUser({
  formState: { dirtyFields },
}: Readonly<EditUserProps>) {
  const [updatedSuccessfullyOpen, setUpdatedSuccessfullyOpen] = useState(false);
  const { refreshCurrentUserState } = useCurrentUserState({});
  const { changeCurrentAction } = useUserActionsState();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const handleCloseUpdatedSuccessfully = () =>
    setUpdatedSuccessfullyOpen(false);

  const handleUpdateUser = useCallback(
    async (data: BackofficeUserDetailsFormProps) => {
      try {
        changeCurrentAction(UserActionsOptionsEnum.EDIT);
        const values = {
          profile: BackofficeUserProfileEnum.ADM,
          document: data.document,
          email: data.email,
          phone: data.phone,
          name: data.name,
        };

        if (id) {
          const userService = new UserService();

          await userService.updateBackofficeUser(id, values);

          const usersQueryKey = [BackofficeUserQueryKeysEnum.USERS_STATE];
          queryClient.removeQueries({ queryKey: usersQueryKey, exact: true });
          await queryClient.refetchQueries({
            queryKey: usersQueryKey,
          });

          const userDetailsQueryKey = [
            BackofficeUserQueryKeysEnum.USER_DETAILS_STATE,
          ];
          queryClient.removeQueries({
            queryKey: userDetailsQueryKey,
            exact: true,
          });
          await queryClient.refetchQueries({
            queryKey: userDetailsQueryKey,
          });

          refreshCurrentUserState();

          if (dirtyFields.email) setUpdatedSuccessfullyOpen(true);

          return true;
        }
      } catch (error) {
        toastifyApiErrors(error, enqueueSnackbar);
      } finally {
        changeCurrentAction(undefined);
      }
      return false;
    },
    [enqueueSnackbar, id, changeCurrentAction, dirtyFields],
  );

  return {
    handleCloseUpdatedSuccessfully,
    updatedSuccessfullyOpen,
    handleUpdateUser,
  };
}
