import { SnackbarProvider } from 'notistack';
import Toasty from '../Toasty';

const CustomSnackbarProvider: React.FC = ({ children, ...props }) => {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      content={(key, message) => {
        return <Toasty snackbarKey={key} alertMessage={message} />;
      }}
      {...props}
    >
      {children}
    </SnackbarProvider>
  );
};

export default CustomSnackbarProvider;
