import { parseISO, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

export default function formatDateISO(
  date: string,
  formatString: string = "dd 'de' MMMM 'de' yyyy",
): string {
  const parsedDate = parseISO(date);

  return format(parsedDate, formatString, {
    locale: ptBR,
  });
}
