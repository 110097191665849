import { styled as muiStyled } from '@mui/material/styles';

import TableRow from '@mui/material/TableRow';

import { COLORS } from '../../../../../../themes/colors';

interface DependentRowProps {
  ithaserror?: string;
  ithaserrormessage?: string;
}
const DependentRow = muiStyled(TableRow)<DependentRowProps>(
  ({ ithaserror, ithaserrormessage }) => ({
    '&.MuiTableRow-root': {
      borderBottom:
        ithaserror === 'true' && ithaserrormessage === 'true'
          ? '2px solid transparent'
          : ithaserror === 'true' && ithaserrormessage === 'false'
          ? `2px solid ${COLORS.SECONDARY.RED['-1']}`
          : `2px solid ${COLORS.MONOCHROMATIC.GRAY2}`,

      background:
        ithaserror === 'true'
          ? COLORS.LINEAR_GRADIENT.GRAY2
          : COLORS.MONOCHROMATIC.GRAY8,

      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);

const EmptyRow = muiStyled(TableRow)(() => ({
  '&.MuiTableRow-root': {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: `2px solid ${COLORS.PRIMARY.ORANGE.MAIN}`,
  },
}));

export { EmptyRow, DependentRow };
