import { Icon } from '@hapvida/hapvida-core-components';

import type { IAppRoute } from 'routes/types';

import { BeneficiaryListRouter } from './pages/BeneficiaryList/router';
import { LetterOfStayRouter } from './pages/LetterOfStay/router';
import {
  AuthenticateCertificateRouter,
  OnlineCardRouter,
  SuspendedBeneficiaryRouter,
} from './redirects/router';

export const BeneficiaryGroupRouter: IAppRoute = {
  Page: BeneficiaryListRouter.Page,
  path: BeneficiaryListRouter.path,
  name: 'Beneficiários',
  isPrivate: true,
  Icon: <Icon name="clipboard-pulse" />,
  subRoutes: [
    BeneficiaryListRouter,
    SuspendedBeneficiaryRouter,
    LetterOfStayRouter,
    OnlineCardRouter,
    AuthenticateCertificateRouter,
  ],
};
