import {
  Box,
  Grid,
  GridProps,
  styled as muiStyled,
  Typography,
} from '@mui/material';
import { COLORS } from '../../themes/colors';

interface Props {
  uppercase?: boolean;
}
interface SummaryProps {
  hasGraphic?: boolean;
  success?: boolean;
}

interface IGrid extends GridProps {
  hasGraphic?: boolean;
}

export const Summary = muiStyled(
  ({ hasGraphic, success, ...props }: SummaryProps) => <Box {...props} />,
)<SummaryProps>(({ hasGraphic, success }) => ({
  padding: hasGraphic ? '2rem 0 0' : '24px',

  borderRadius: '0.5rem',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  background: success
    ? 'linear-gradient(0deg, rgba(82, 135, 44, 0.04), rgba(82, 135, 44, 0.04)), #FFFFFF'
    : COLORS.WHITE,

  border: success ? `1px solid ${COLORS.SECONDARY.GREEN['+1']}` : 'none',
}));

export const Header = muiStyled(Box)(() => ({
  marginBottom: '2.4rem',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const Identifier = muiStyled(Box)(() => ({}));

export const Title = muiStyled(Typography)<Props>(
  ({ uppercase: mustUpperCase }) => ({
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '1rem',
    lineHeight: '1.25rem',

    textTransform: mustUpperCase ? 'uppercase' : 'none',
  }),
);

export const Subtitle = muiStyled(Typography)<Props>(
  ({ uppercase: mustUpperCase }) => ({
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',

    textTransform: mustUpperCase ? 'uppercase' : 'none',
  }),
);

export const SummaryItem = muiStyled(Box)(() => ({
  padding: '0.2rem',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Value = muiStyled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: '900',
  fontSize: '1.25rem',
  lineHeight: '1.5625rem',
}));

export const Info = muiStyled(Box)(() => ({
  width: '100%',
  marginTop: '1rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Label = muiStyled(Typography)(() => ({
  marginLeft: '0.5rem',
  fontSize: '0.75rem',
}));

export const GridStyled = muiStyled(({ hasGraphic, ...props }: IGrid) => (
  <Grid {...props} />
))<IGrid>(({ hasGraphic }) => ({
  '&.MuiGrid-item': {
    padding: hasGraphic && '16px 16px 0',
  },
}));
