import { Stack, StackProps, styled as muiStyled } from '@mui/material';
import Icon from '../Icon';
import type { IndicationProps } from './types';

export const RouteItem = muiStyled(({ ...props }) => (
  <Stack component="li" {...props} />
))<StackProps & IndicationProps>(
  ({ selected, disabled, theme, noInteraction }) => ({
    borderRadius: '8px',
    alignItems: 'center',
    padding: 16,
    gap: 8,

    '.MuiTypography-root': {
      color: theme.palette.action.secondary.contrastText,
    },

    ':hover': {
      cursor: disabled ? 'not-allowed' : noInteraction ? 'inherit' : 'pointer',
      background:
        disabled || noInteraction
          ? 'transparent'
          : theme.palette.action.secondary.activeBackground,
    },

    ...(disabled && {
      opacity: 0.5,
      background: 'transparent',
      '.MuiTypography-root': {
        color: theme.palette.action.secondary.disabled,
      },
    }),

    ...(selected && {
      cursor: disabled ? 'not-allowed' : noInteraction ? 'inherit' : 'pointer',
      background: theme.palette.action.secondary.activeBackground,
      '.MuiTypography-root': {
        fontWeight: 600,
        color: theme.palette.action.secondary.contrastText,
      },
      ':hover': {
        background: theme.palette.action.secondary.activeBackground,
      },
    }),
  }),
);

export const RouteIcon = muiStyled(Icon)<IndicationProps>(
  ({ theme, disabled, selected }) => ({
    color: theme.palette.action.secondary.activeIcon,
    ...(disabled && {
      color: theme.palette.action.secondary.disabled,
    }),
    ...(selected && {
      color: theme.palette.action.secondary.selectedIcon,
    }),
  }),
);
