import {
  Grid,
  Drawer,
  GridProps,
  Container,
  DrawerProps,
  styled as muiStyled,
} from '@mui/material';

import styled from 'styled-components';

import { COLORS } from '../../../themes/colors';

interface StyledDrawerProps extends DrawerProps {
  drawerType: string;
}

interface DrawerContainerProps extends GridProps {
  open?: boolean;
}

export const DrawerContainer = styled(Grid)<DrawerContainerProps>``;

export const LayoutContainer = muiStyled(Container)(() => ({
  padding: '0px !important',
}));

export const StyledDrawer = muiStyled(
  ({ drawerType, ...props }: StyledDrawerProps) => <Drawer {...props} />,
)<StyledDrawerProps>(({ drawerType }) => ({
  borderTopRightRadius: 32,
  backdropFilter: 'blur(24px)',
  height: '100%',
  minHeight: '100vh',
  width: '100%',
  zIndex: 10000,

  transition: 'all 0.15s',

  '& .MuiDrawer-paper': {
    transition: 'all 0.15s',
    background:
      drawerType === 'step' ? COLORS.WHITE : COLORS.PRIMARY.ORANGE.MAIN,
  },

  '.MuiPaper-root': {
    borderRight: 'none',
    width: '100%',
  },
}));

export const StyledMain = styled(Grid)`
  padding: 24px 40px 0px 24px;
  margin-top: 100px;
`;
