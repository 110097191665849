import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

import { COLORS } from '@hapvida/hapvida-core-components';

import HapvidaLogoLight from '@hapvida/hapvida-core-components/src/assets/images/hapvida-logo-new-light.svg';
import NdiLogoLight from '@hapvida/hapvida-core-components/src/assets/images/ndi-logo-new-light.svg';

import { Container, StepItem } from './styles';
import type { StartPasswordRecoveryStepsProps } from './types';
import { getSteps } from './utils/getSteps';
import { StepIcon } from './components/StepIcon';

export function StartPasswordRecoverySteps({
  currentStep,
}: Readonly<StartPasswordRecoveryStepsProps>) {
  const steps = useMemo(() => getSteps(currentStep), [currentStep]);

  return (
    <Container>
      <Stack gap={4.75} flex={0.9} justifyContent="center">
        <Stack paddingLeft="15px">
          <Typography variant="h5" fontWeight={700} color={COLORS.WHITE}>
            Redefinir senha
          </Typography>
          <Typography variant="h6" fontWeight={400} color={COLORS.WHITE}>
            Esqueceu sua senha? Sem problemas! Vamos te ajudar a criar uma nova.
          </Typography>
        </Stack>

        <Stack gap={1}>
          {steps.map(({ label, stepNumber, completed }) => {
            const isSelected = stepNumber === currentStep;
            return (
              <StepItem selected={isSelected} key={stepNumber}>
                <StepIcon
                  isSelected={isSelected}
                  stepNumber={stepNumber}
                  isCompleted={Boolean(completed)}
                />
                <Typography variant="body2" color={COLORS.WHITE}>
                  {label}
                </Typography>
              </StepItem>
            );
          })}
        </Stack>
      </Stack>
      <Stack flex={0.1} justifyContent="center" flexDirection="row" gap={2.625}>
        <img src={HapvidaLogoLight} alt="Hapvida" height={32} />
        <img src={NdiLogoLight} height={42} alt="Ndi" />
      </Stack>
    </Container>
  );
}
