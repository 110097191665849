import { useState } from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { Button, Stack, Typography } from '@mui/material';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';
import Icon from '../Icon';

import { StyledTypography, TimerContent } from './styles';
import { CircularProgress } from '../CircularProgress';
import type { CountDownProps } from './types';

export const CountDown: React.FC<CountDownProps> = ({
  available = {
    info: '',
    clickText: 'Reenviar agora',
  },
  unavaible = {
    loadingText: 'Aguarde',
    loadingInfo: '',
  },
  disabled,
  timer = 60,
  onClick,
  loading = false,
  successMessage,
  fullWidth = false,
}: CountDownProps) => {
  const [isClickedState, setIsClickedState] = useState(false);
  const [isLoadingState, setIsLoadingState] = useState(false);

  const countDownDateCalc = Date.now() + timer * 1000;

  const renderDecimal = (time: number): string => {
    return time.toString().padStart(2, '0');
  };

  const rendererConditional = ({
    minutes,
    seconds,
    completed,
  }: CountdownRenderProps) => {
    if (completed) {
      setIsClickedState(false);
      return null;
    }

    return (
      <span>
        {renderDecimal(minutes)}:{renderDecimal(seconds)}
      </span>
    );
  };

  const handleTimerClick = async () => {
    if (disabled || isLoadingState) {
      return;
    }

    setIsLoadingState(true);

    if (onClick) {
      await onClick();
    }

    setIsClickedState(true);
    setIsLoadingState(false);
  };

  return (
    <Stack direction="row">
      {!isClickedState ? (
        <>
          <Typography variant="text">{available.info}</Typography>
          <Button
            variant="contained"
            disabled={loading || isLoadingState}
            onClick={handleTimerClick}
            color="secondary"
            endIcon={isLoadingState && <CircularProgress size={16} />}
            fullWidth={fullWidth}
          >
            {available.clickText}
          </Button>
        </>
      ) : (
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <StyledTypography variant="text" color="secondary" fontWeight={700}>
            <Icon name="check" size={20} color={COLORS.SECONDARY.GREEN.MAIN} />
            {successMessage}
          </StyledTypography>

          <Stack flexDirection="row" alignItems="center" gap={0.5}>
            <StyledTypography variant="text">
              {unavaible.loadingText}
            </StyledTypography>
            <TimerContent>
              <Typography
                variant="text"
                color={COLORS.MONOCHROMATIC.GRAY4}
                sx={{ fontWeight: 700 }}
              >
                <Countdown
                  date={countDownDateCalc}
                  renderer={rendererConditional}
                />
              </Typography>
            </TimerContent>
            <Typography variant="text">{unavaible.loadingInfo}</Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
