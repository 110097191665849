import { styled } from '@mui/material';

export const GridViewContainer = styled('div')<{ listsize?: number | string }>(
  ({ theme, listsize = 600 }) => ({
    maxHeight: typeof listsize === 'string' ? listsize : `${listsize}px`,

    overflow: 'auto',
    paddingRight: '16px',

    [theme.breakpoints.down('sm')]: {
      padding: '48px 16px 32px 0',
    },
  }),
);
