import { styled as muiStyled, TableRow } from '@mui/material';

import { COLORS } from '../../../themes/colors';

export const StyledTableRow = muiStyled(TableRow)(({ theme }) => ({
  borderRadius: 8,
  borderLeft: `1px solid ${COLORS.MONOCHROMATIC.GRAY3}`,
  borderRight: `1px solid ${COLORS.MONOCHROMATIC.GRAY3}`,
  'td:first-of-type': {
    borderLeft: `1px solid ${COLORS.MONOCHROMATIC.GRAY3}`,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  'td:last-of-type': {
    borderRight: `1px solid ${COLORS.MONOCHROMATIC.GRAY3}`,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },

  background: COLORS.WHITE,
}));
