import { Stack, Typography } from '@mui/material';

import {
  PLAN_TYPE_COLOR,
  PLAN_TYPE_NAME,
  PlanTypeEnum,
} from '@flows/private/partnerCompanies/pages/PartnerCompanyList/constants/contractTypeEnum';

export const mappedPlanType = (planType: PlanTypeEnum) => {
  const content = (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Typography
        fontWeight={700}
        color={PLAN_TYPE_COLOR[planType]}
        variant="text"
      >
        {PLAN_TYPE_NAME[planType]}
      </Typography>
    </Stack>
  );

  return {
    label: PLAN_TYPE_NAME[planType],
    value: planType,
    content,
  };
};
