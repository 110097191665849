import { Link } from 'react-router-dom';

import { COLORS } from "../../themes/colors";

import Button from '../Button';

import { CardInformation, RedirectCard } from "./styles";

interface DisclaimerCardProps {
  button: {
    text: string;
    redirectTo: string;
  };
  image: string;
  title?: string;
  imageAlt: string;
  description: string;
  imageWidth?: number | string;
  imageHeight?: number | string;
  customStyles?: React.CSSProperties;
}

const DisclaimerCard: React.FC<DisclaimerCardProps> = ({
  image,
  title,
  button,
  imageWidth,
  imageHeight,
  description,
  customStyles,
  ...props
}) => {
  return (
    <RedirectCard {...props} style={customStyles}>
      <CardInformation>
        <img
          src={image}
          alt={title}
          style={{ width: imageWidth, height: imageHeight }}
        />
        {title && <strong>{title}</strong>}
        <p>{description}</p>
      </CardInformation>
      <Link to={button.redirectTo}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: COLORS.PRIMARY.BLUE.MAIN,
          }}
        >
          {button.text}
        </Button>
      </Link>
    </RedirectCard>
  );
};

export default DisclaimerCard;
