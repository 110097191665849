import { Stack, Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  COLORS,
  Icon,
  Mask,
  Pill,
  SummaryCardNew,
  useConfirmation,
} from '@hapvida/hapvida-core-components';

import { useGetFile, useAuth } from '@hooks';
import { MOVEMENT_STATUS_COLOR, MOVEMENT_STATUS_NAME } from '@constants';
import {
  LinkRedirect,
  LoadValidationAlert,
} from '@flows/private/movements/pages/MovementList/components/MovementListCard/components/TableRows/styles';
import { MovementShippingReviewsContent } from '@flows/private/movements/components';
import { singularTreatmentShippingReviews } from '@flows/private/movements/utils';

import { DateText, FieldsWrapper, FileDiv } from './styles';
import { useMovementDetailsState } from '../../hooks';
import { DataField } from './components';

export function MovementInfoCard() {
  const { movementInfoState } = useMovementDetailsState();
  const { selectedCompany } = useAuth();
  const { movementId = '' } = useParams();
  const { showConfirmation } = useConfirmation();

  const companyId = selectedCompany?.id ?? '';

  if (!movementInfoState.isSuccess) {
    return null;
  }

  const { movementDetails } = movementInfoState.data.content;

  const handleOpenLoadValidation = () => {
    showConfirmation({
      title: 'Erros encontrados no arquivo',
      icon: 'alert',
      type: 'default',
      cancelText: 'Fechar',
      content: (
        <MovementShippingReviewsContent
          movementId={movementId}
          companyId={companyId}
        />
      ),
    });
  };

  const fileName = movementDetails?.fileName ?? movementDetails?.uploadName;

  return (
    <SummaryCardNew.Container>
      <Typography variant="subtitle1">Informações da movimentação</Typography>
      <FieldsWrapper>
        <DataField
          label="Cód. Movimentação"
          value={movementDetails?.number}
          width="25%"
        />
        <DataField
          label="Data da carga"
          value={<DateText>{Mask.date(movementDetails?.createdAt)}</DateText>}
          width="25%"
        />
        <DataField
          label="Data da efetivação"
          value={<DateText>{Mask.date(movementDetails?.updatedAt)}</DateText>}
          width="25%"
        />
        <DataField
          label="Status"
          value={
            <Pill
              color={MOVEMENT_STATUS_COLOR[movementDetails?.status]}
              text={MOVEMENT_STATUS_NAME[movementDetails?.status]}
              fitContent
            />
          }
          width="25%"
        />
      </FieldsWrapper>
      <FieldsWrapper>
        <DataField
          label="Arquivo"
          value={
            <>
              {fileName !== undefined ? (
                <FileDiv>
                  <Icon name="file-check" size={18} />
                  <Tooltip title={fileName} arrow>
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      onClick={() =>
                        useGetFile(
                          `companies/${selectedCompany?.id}`,
                          movementDetails?.uploadName,
                        )
                      }
                    >
                      {fileName}
                    </Typography>
                  </Tooltip>
                </FileDiv>
              ) : (
                <Typography>-</Typography>
              )}
            </>
          }
          width="100%"
        />
      </FieldsWrapper>
      {movementDetails?.totalShippingReviews > 0 && (
        <FieldsWrapper>
          <LoadValidationAlert colSpan={6}>
            <Stack flexDirection="row" gap={2} alignItems="center">
              <Icon name="alert" size={24} color={COLORS.PRIMARY.BLUE['+1']} />
              <Typography variant="text" fontWeight={700}>
                {singularTreatmentShippingReviews(
                  movementDetails?.totalShippingReviews,
                )}
                <LinkRedirect variant="text" onClick={handleOpenLoadValidation}>
                  mostrar detalhes
                </LinkRedirect>
              </Typography>
            </Stack>
          </LoadValidationAlert>
        </FieldsWrapper>
      )}
    </SummaryCardNew.Container>
  );
}
