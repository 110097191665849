import { useNewCompanyListState } from './useNewCompanyListState';
import { NewCompanyModalLayout } from './layout';

export function NewCompanyModal() {
  const {
    fetchNextPage,
    hasNextPage,
    isError,
    isLoading,
    newCompanyListPages,
  } = useNewCompanyListState();

  if (isLoading || isError || !newCompanyListPages) {
    return null;
  }

  const { pages } = newCompanyListPages;

  const newCompanies = pages?.map(page => page.newCompanies).flat() ?? [];

  return (
    <NewCompanyModalLayout
      newCompanies={newCompanies}
      hasNextPage={Boolean(hasNextPage)}
      fetchNextPage={fetchNextPage}
    />
  );
}
