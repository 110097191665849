import { Typography } from '@mui/material';

import { Icon } from '@hapvida/hapvida-core-components';

import { IconDiv } from './styles';
import type { MovementActionTypeProps } from './types';
import { MovementTypeValues } from './constants';

export function MovementActionType({
  operationCode,
  movementType,
}: Readonly<MovementActionTypeProps>) {
  return (
    <>
      {movementType && movementType !== 'None' && operationCode ? (
        <IconDiv color={MovementTypeValues[movementType]?.color}>
          <Icon name={MovementTypeValues[movementType]?.icon} size={16} />
          <Typography variant="caption" fontWeight={600}>
            {operationCode?.replace(/^0+/, '')} -{' '}
            {MovementTypeValues[movementType]?.value}
          </Typography>
        </IconDiv>
      ) : (
        <Typography variant="caption" fontWeight={700}>
          {operationCode ? operationCode.replace(/^0+/, '') : '-'}
        </Typography>
      )}
    </>
  );
}
