import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';

export const StyledInputLabel = styled(InputLabel)`
  font-size: 14px;
  color: ${COLORS.PRIMARY.BLUE.MAIN} !important;
`;

export const StyledFormControl = styled(FormControl)`
  width: 100%;

  .MuiInputBase-formControl {
    margin-top: 0;
  }

  .MuiInput-root {
    :after {
      display: none;
    }

    :before {
      display: none;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  min-height: 42px;
  border-bottom: 1px solid ${COLORS.MONOCHROMATIC.GRAY3};
`;

export const StyledSelect = muiStyled(Select)(({ value }) => ({
  background: COLORS.MONOCHROMATIC.GRAY1,
  height: '40px',
  color: value === '' ? COLORS.MONOCHROMATIC.GRAY3 : COLORS.MONOCHROMATIC.GRAY4,

  '& fieldset': {
    border: 0,
    borderRadius: 0,
    borderBottom: `1px solid ${COLORS.MONOCHROMATIC.GRAY4}`,
  },

  '&.Mui-focused': {
    '& fieldset': {
      border: `1px solid ${COLORS.PRIMARY.BLUE['-1']}`,
      borderWidth: '1px !important',
    },
  },
}));

export const useStyles = makeStyles({
  select: {
    border: `1px solid ${COLORS.PRIMARY.BLUE['-1']}`,
    borderRadius: '0px',

    '& ul': {
      padding: '0px',
    },
  },
});
