import FinancialService from '@services/FinancialService';

export const downloadMonthlyReport = async (
  fileType: string,
  billingNumber: string,
  financialService: FinancialService,
) => {
  try {
    const data = await financialService.reportMonthly({
      billingNumber,
      fileType,
    });

    return window.open(data?.content?.link, '_blank');
  } catch {
    return undefined;
  }
};
