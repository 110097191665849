import { Stack } from '@mui/material';
import { useEffect, useRef } from 'react';

import {
  ErrorLoadingPage,
  FilterSummary,
  Loading,
  NoSearchResults,
  useDrawerFilter,
} from '@hapvida/hapvida-core-components';

import { EmptyResult } from '@components';
import { useAuth, useFilterParams } from '@hooks';
import { UserType } from '@contexts';
import { statusCompanyFilterValues } from 'components/filters/CompanyStatusDrawerFilter/constants';

import { useCompanySetupListState, useSelectCompany } from './hooks';
import { CompanySetupListViewList, NewCompanyModal } from './components';

export function CompanySetupListLayout() {
  const { handleSelectCompany } = useSelectCompany();
  const { hasFilters } = useFilterParams();
  const { companySetupListState, refreshCompanySetupListStates } =
    useCompanySetupListState();
  const { user } = useAuth();

  const userName = user?.name ?? '';

  const isBackoffice = user?.companyType === UserType.Backoffice;

  const { isLoading, data, isError } = companySetupListState;

  const { getInitialFilter, setActiveFilters } = useDrawerFilter();
  const initialRender = useRef<boolean>(true);

  useEffect(() => {
    handleSelectCompany();

    if (initialRender.current) {
      initialRender.current = false;
      const initialUsersFilters = getInitialFilter({
        filtersConfig: statusCompanyFilterValues,
      });

      setActiveFilters(initialUsersFilters);
    }
  }, []);
  if (isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  if (isError) {
    return <ErrorLoadingPage onReloadClick={refreshCompanySetupListStates} />;
  }

  const totalCompanySetupListLength = companySetupListState.data.total ?? 0;

  const isEmpty = totalCompanySetupListLength === 0 && !hasFilters;

  if (isEmpty) {
    return (
      <EmptyResult
        message={`${userName}, nenhuma empresa está disponível para você.`}
      />
    );
  }

  const isSearchResultEmpty = totalCompanySetupListLength === 0 && hasFilters;

  const filterSummaryLabel = `${totalCompanySetupListLength} ${
    totalCompanySetupListLength === 1 ? 'empresa' : 'empresas'
  }`;

  return (
    <Stack minHeight="100%" gap={3}>
      <Stack py={3} flex={1}>
        <FilterSummary totalLabel={filterSummaryLabel} alwaysShowTitle />

        {isSearchResultEmpty && <NoSearchResults padding={5} />}

        {!isSearchResultEmpty && (
          <CompanySetupListViewList companySetupListData={data} />
        )}

        {!isBackoffice && <NewCompanyModal />}
      </Stack>
    </Stack>
  );
}
