import FinancialService, { type Bill } from '@services/FinancialService';

import { downloadFiles } from './downloadFiles';

export const fetchAndDownloadCoparticipationReports = async (
  selectedBills: Bill[],
  service: FinancialService,
  personNumberResult: number | undefined,
  handleOpenFiles: (filesData: { path: string; fileName: string }[]) => void,
) => {
  const filterCoparticipationAnalyticalResult = selectedBills?.flatMap(
    ({ coparticipationAnalytical }) => coparticipationAnalytical || [],
  );

  if (personNumberResult) {
    await downloadFiles?.(
      filterCoparticipationAnalyticalResult,
      async item =>
        service?.getCoPaymentReport({
          personNumber: personNumberResult,
          billingExtractId: item?.billingExtractId!,
          fileName: item?.fileName,
        }),
      handleOpenFiles,
    );
  }
};
