import { StackProps, Stack, Typography } from '@mui/material';

import styled, { css } from 'styled-components';

import { COLORS } from '../../themes/colors';

interface StyledStackProps extends StackProps {
  isActive: boolean;
  background?: string;
}

export const StyledStack = styled(
  ({ isActive, ...props }: StyledStackProps) => <Stack {...props} />,
)<StyledStackProps>`
  width: 100%;

  margin-top: 10px;

  border: 1px solid ${COLORS.MONOCHROMATIC.GRAY3};
  border-radius: 4px;

  ${({ background }) =>
    background &&
    css`
      background: ${background};
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid ${COLORS.PRIMARY.ORANGE.MAIN};
      background: #fffbf5;
    `}
`;

export const CardContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  &:hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 12px;
`;

export const Title = styled(Typography).withConfig<{
  active?: boolean;
}>({ shouldForwardProp: key => key !== 'active' })`
  font-weight: ${({ active }) => (active ? 600 : 400)};
  font-size: 14px;
  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const IconWrapper = styled.div`
  color: ${COLORS.PRIMARY.ORANGE.MAIN};
`;
