import { BillDetailStateProvider } from './hooks';
import { BillLayout } from './layout';

export function BillDetail() {
  return (
    <BillDetailStateProvider>
      <BillLayout />
    </BillDetailStateProvider>
  );
}
