import { styled as muiStyled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';
import { COLORS } from '../../../themes/colors';

interface StyledRowProps {
  isselected: string;
}

export const Title = muiStyled(Typography)({
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: '1rem',
  lineHeight: '1.25rem',
});

export const StyledRow = muiStyled(TableRow)<StyledRowProps>(
  ({ isselected }) => ({
    '&.MuiTableRow-root': {
      background:
        isselected === 'true'
          ? 'linear-gradient(0deg, rgba(245, 182, 127, 0.04), rgba(245, 182, 127, 0.04)), #FFFFFF'
          : '',

      borderBottom:
        isselected === 'true'
          ? `2px solid ${COLORS.PRIMARY.ORANGE.MAIN}`
          : `2px solid ${COLORS.MONOCHROMATIC.GRAY2}`,

      '&:hover': {
        background:
          'linear-gradient(0deg, rgba(245, 182, 127, 0.04), rgba(245, 182, 127, 0.04)), #FFFFFF',
      },
    },
  }),
);

export const StyledActionCell = muiStyled(TableCell)(() => ({
  '&.MuiTableCell-root': {
    width: '10px',
  },

  background: COLORS.MONOCHROMATIC.GRAY1,

  position: 'sticky',
  right: 0,

  '&:hover': {
    background:
      'linear-gradient(0deg, rgba(245, 182, 127, 0.16), rgba(245, 182, 127, 0.16)), #FFFFFF',
  },
}));

export const ActionBox = muiStyled(Box)(() => ({
  '&.MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    gap: '10px',
  },
}));

export const EditButton = muiStyled(IconButton)(() => ({
  '&.MuiIconButton-root': {
    border: `1px solid ${COLORS.MONOCHROMATIC.GRAY4}`,

    width: '32px',
    height: '32px',

    '&:hover': {
      backgroundColor: `${COLORS.WHITE}`,
      border: `1px solid ${COLORS.PRIMARY.ORANGE.MAIN}`,
      color: COLORS.PRIMARY.ORANGE.MAIN,
      cursor: 'pointer',
    },
  },
}));
