import { Divider, FormGroup, Stack } from '@mui/material';
import { useState } from 'react';

import { AccordionNew, Loading } from '@hapvida/hapvida-core-components';

import { UserProfileEnum } from '@constants';
import { useAccessManagement } from 'components/ProfileSelectionCard/hooks';

import { usePartnerCompanyList, useProfileCard } from '../../hooks';
import {
  Empty,
  SelectionError,
  MainPartnerCompanyAlert,
  PartnerCompaniesList,
  SearchPartnerCompany,
} from './components';
import { ANIMATION_PROPS } from './constants';
import { PartnerCompaniesStack } from './styles';

export function PartnerCompaniesSelection() {
  const [searchParam, setSearchParam] = useState('');
  const { selectedProfile } = useProfileCard();
  const { hasError } = useAccessManagement();
  const { initialPartnerCompaniesState, initialMainPartnerCompaniesState } =
    usePartnerCompanyList({
      searchParam,
    });

  const isMaster = selectedProfile?.name === UserProfileEnum.MASTER;
  const partnerCompaniesState = isMaster
    ? initialMainPartnerCompaniesState
    : initialPartnerCompaniesState;

  if (partnerCompaniesState.isError || !selectedProfile) {
    return null;
  }

  const {
    data,
    fetchNextPage,
    isFetching: isFetchingState,
    isLoading,
    hasNextPage = false,
  } = partnerCompaniesState;

  const initialPartnerCompanies =
    data?.pages?.map(({ partnerCompanies }) => partnerCompanies.itens).flat() ??
    [];

  const isEmpty = initialPartnerCompanies.length === 0;
  const isFetching = isFetchingState || isLoading;
  const showCircularProgress = isEmpty && isFetching && !hasError;
  const showEmpty = isEmpty && !isFetching;
  const showPartnerCompaniesList = !hasError && !showCircularProgress;

  return (
    <AccordionNew
      title="Lista de contratos"
      /* REMINDER: código comentado abaixo pois backend não irá retornar a quantidade de contratos selecionados por enquanto
       subtitle={<Subtitle />} */
    >
      {isMaster && (
        <>
          <MainPartnerCompanyAlert />
          <Divider />
        </>
      )}
      <FormGroup>
        {!hasError && (
          <SearchPartnerCompany
            placeholder="Buscar contrato"
            setSearchParam={search => setSearchParam(search)}
          />
        )}

        <Divider />
        <PartnerCompaniesStack id={selectedProfile.name}>
          {showCircularProgress && (
            <Stack my={2} justifyContent="center" alignItems="center">
              <Loading animationProps={ANIMATION_PROPS} />
            </Stack>
          )}

          {hasError && <SelectionError />}

          {showEmpty && <Empty />}

          {showPartnerCompaniesList && (
            <PartnerCompaniesList
              fetchNextPage={fetchNextPage}
              isFetching={isFetching}
              hasNextPage={hasNextPage}
              partnerCompanies={initialPartnerCompanies}
              selectedProfile={selectedProfile}
            />
          )}
        </PartnerCompaniesStack>
      </FormGroup>
    </AccordionNew>
  );
}
