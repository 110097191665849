import { ClipboardEvent } from 'react';

const cleanCNPJ = (input: string) => {
  return input
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\d]/g, '');
};

const getSelectionText = () => {
  const selection = document.getSelection()?.toString() ?? '';
  if (selection && selection.length > 0) return selection;

  const activeElement = document.activeElement as HTMLInputElement;

  const activeElementIsEmpty =
    !activeElement ||
    Number.isNaN(activeElement.selectionStart) ||
    Number.isNaN(activeElement.selectionEnd);

  if (activeElementIsEmpty) {
    return '';
  }

  const selectedText = activeElement?.value?.substring(
    activeElement.selectionStart ?? 0,
    activeElement.selectionEnd ?? 0,
  );

  return selectedText;
};

export function handleCopyCleanCNPJToClipboard(
  event: ClipboardEvent<HTMLDivElement>,
) {
  const string = getSelectionText();

  if (!string) {
    return;
  }

  const cnpjMatches = Array.from(
    string.matchAll(/\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/g),
  );

  if (!cnpjMatches || cnpjMatches.length === 0) {
    return;
  }

  const replacedClipboard = cnpjMatches
    .map(cnpjMatch => cnpjMatch[0])
    .reduce((acc, cnpjMatch) => {
      const cnpj = cnpjMatch;
      const cleanCnpj = cleanCNPJ(cnpj);
      return acc.replace(cnpj, cleanCnpj);
    }, string);

  event.preventDefault();
  event.clipboardData.setData('text/plain', replacedClipboard);
}
