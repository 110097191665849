import { Chip, styled } from '@mui/material';

import type { StyledChipProps } from './types';

export const StyledChip = styled(Chip)<StyledChipProps>(
  ({ bgColor, textColor, fontSize }) => ({
    backgroundColor: bgColor,
    color: textColor,
    fontWeight: 700,
    fontSize,
  }),
);
