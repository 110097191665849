import { Typography } from '@mui/material';

import { Modal } from '@hapvida/hapvida-core-components';

import { UserRedirectsEnum } from '@services/UserService';

import { useRedirectUser } from '@hooks';

import type { MovementStatusModalProps } from './types';

export function MovementStatusModal({
  modalState,
  toggleModal,
}: Readonly<MovementStatusModalProps>) {
  const { redirectUser } = useRedirectUser();

  const handleRedirect = () => {
    redirectUser(UserRedirectsEnum.MOVEMENT_STATUS);
    toggleModal();
  };

  return (
    <Modal
      open={modalState}
      icon="alert"
      type="default"
      handleCloseModal={toggleModal}
      title="Status de movimentação"
      mainButtonText="Acessar portal"
      secondaryButtonText="Fechar"
      mainAction={handleRedirect}
    >
      <Typography variant="text" mb={3}>
        Para acessar o{' '}
        <Typography variant="text" fontWeight={700}>
          Status de Movimentação
        </Typography>
        , clique em “Acessar portal” para ser direcionado para uma nova pagina.{' '}
      </Typography>
      <Typography variant="text">
        Importante: Caso existam, você verá o status de todas as movimentações
        por tela (digitação) e também via arquivo (layout).
      </Typography>
    </Modal>
  );
}
