import { useQuery as query } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import {
  createContext,
  useContext,
  useMemo,
  type PropsWithChildren,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import FinancialService, {
  type FileData,
  type ReportMonthlyProps,
} from '@services/FinancialService';

import { BillListRouter } from '@flows/private/financial/pages/List/router';
import { useAuth } from '@hooks';
import { toastifyApiErrors } from '@utils';
import { BillTypeEnum } from '@flows/private/financial/constants/billtypeEnum';

import type { BillStateContextData } from './types';

export const BillDetailStateContext = createContext<BillStateContextData>(
  {} as BillStateContextData,
);

function BillDetailStateProvider({
  children,
}: Readonly<PropsWithChildren<{}>>) {
  const { enqueueSnackbar } = useSnackbar();
  const [params] = useSearchParams();
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { selectedCompany, selectedPartnerCompany } = useAuth();

  const financialService = useMemo(() => new FinancialService(), []);

  const year = useMemo(
    () => params.get('year') ?? new Date().getFullYear().toString(),
    [],
  );

  const companyId = selectedCompany?.id ?? '';
  const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';

  const isEnabled =
    Boolean(id) &&
    Boolean(companyId) &&
    Boolean(year) &&
    Boolean(partnerCompany);

  const billDetailsState = query({
    queryKey: ['billDetailsState', id, companyId, partnerCompany],
    queryFn: () =>
      financialService.getBill({
        billNumber: id,
        companyId,
        year,
      }),
    enabled: isEnabled,
    retry: 2,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
      navigate(BillListRouter.path);
    },
  });

  const isCarnetComplement =
    billDetailsState.data?.billType === BillTypeEnum.CARNET_COMPLEMENT;

  const coparticipationFilesState = query<FileData[]>({
    queryKey: ['coparticipationFilesState', id],
    queryFn: async () => {
      if (!billDetailsState.data) return [];

      try {
        return await Promise.all(
          billDetailsState.data.coparticipationAnalytical.map(async item => {
            const path = await financialService.getCoPaymentReport({
              personNumber: billDetailsState.data.personNumber,
              billingExtractId: item.billingExtractId,
              fileName: item.fileName,
            });

            return {
              fileName: item.fileName,
              path,
            };
          }),
        );
      } catch (error) {
        return [];
      }
    },
    enabled:
      billDetailsState.isSuccess &&
      billDetailsState.data.coparticipationAnalytical.length > 0 &&
      isCarnetComplement,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const discountLettersState = query({
    queryKey: ['discountLettersState', id],
    queryFn: () =>
      financialService.getDiscountLetters({
        companyId,
        invoiceNumber: billDetailsState.data?.invoice?.number,
      }),
    enabled: billDetailsState.isSuccess,
  });

  const refreshBillStates = () => {
    coparticipationFilesState.remove();
    billDetailsState.remove();
    discountLettersState.remove();

    coparticipationFilesState.refetch();
    billDetailsState.refetch();
    discountLettersState.refetch();
  };

  const fetchReportWithFileType = (fileType: string, billingNumber: string) => {
    return query<ReportMonthlyProps>({
      queryKey: ['reportMonthlyState', id, companyId, partnerCompany, fileType],
      cacheTime: 0,
      queryFn: () =>
        financialService.reportMonthly({
          billingNumber,
          fileType,
        }),
      enabled: isEnabled && !isCarnetComplement,
      retry: 2,
    });
  };

  const valueBillStateContextProvider = useMemo(
    () => ({
      coparticipationFilesState,
      refreshBillStates,
      billDetailsState,
      discountLettersState,
      fetchReportWithFileType,
    }),
    [
      coparticipationFilesState,
      refreshBillStates,
      billDetailsState,
      discountLettersState,
      fetchReportWithFileType,
    ],
  );

  return (
    <BillDetailStateContext.Provider value={valueBillStateContextProvider}>
      {children}
    </BillDetailStateContext.Provider>
  );
}

function useBillState() {
  const context = useContext(BillDetailStateContext);

  if (!context) {
    throw new Error('useBillState must be used within BillStateProvider');
  }

  return context;
}

export { useBillState, BillDetailStateProvider };
