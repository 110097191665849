import { PropsWithChildren } from 'react';
import { COLORS } from '../../../../../themes/colors';

import Icon from '../../../../Icon';

import { ErrorRow, ErrorMessageBox, Message, TableCellNew } from './styles';
import type { ErrorLineProps } from './types';

export const ErrorLine = ({
  colspan,
  errorMessage,
  children,
}: PropsWithChildren<ErrorLineProps>) => {
  const hasErrorMessage = Boolean(errorMessage && !children);
  return (
    <ErrorRow>
      <TableCellNew colSpan={colspan + 1}>
        <ErrorMessageBox>
          <Icon name="alert-circle" color={COLORS.ERROR.MAIN} size={24} />
          {hasErrorMessage && <Message>{errorMessage}</Message>}
          {children}
        </ErrorMessageBox>
      </TableCellNew>
    </ErrorRow>
  );
};
