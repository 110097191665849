import type {
  FileData,
  CoparticipationReportProps,
  Bill,
} from '@services/FinancialService';

import { BillFileProps } from '@flows/private/financial/hooks';

export const downloadCopayReport = async (
  coparticipationAnalytical: CoparticipationReportProps[],
  currentBill: Bill,
  getCoparticipationReportFiles: (
    currentBill: any,
  ) => Promise<BillFileProps[] | undefined>,
) => {
  const handleOpenFile = (file?: FileData) => {
    if (file?.path) window.open(file.path, '_blank');
  };

  const handleOpenFiles = (files?: FileData[]) => {
    files?.forEach(file => handleOpenFile(file));
  };

  if (coparticipationAnalytical.length > 0) {
    const coparticipationReportFiles = await getCoparticipationReportFiles(
      currentBill,
    );

    if (coparticipationReportFiles) {
      const fileDataList: FileData[] = coparticipationReportFiles?.map(
        report => ({
          fileName: report.fileName,
          path: report.path,
        }),
      );

      handleOpenFiles(fileDataList);
    }
  }
};
