import { Stack, styled } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

import type { StepItemProps } from './types';

const Container = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  padding: '0px 32px',
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    padding: '0px 48px',
  },
  [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
    padding: '0px 65px',
  },
}));

const StepItem = styled(Stack)<StepItemProps>(({ selected }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  padding: 16,
  gap: 8,

  ...(selected && {
    background: COLORS.SELECTED.BACKGROUND,
    '.MuiTypography-root': {
      fontWeight: 700,
    },
  }),
}));

export { Container, StepItem };
