import { Grid, Stack } from '@mui/material';
import React from 'react';
import Typography from '../Typography';

interface FormProps {
  title?: string;
  subtitle?: string;
}

const Form: React.FC<FormProps> = ({ children, title, subtitle }) => {
  return (
    <Stack gap={2}>
      <Grid display="flex" alignItems="center" justifyContent="space-between">
        {title && <Typography variant="h5">{title}</Typography>}
        {subtitle && (
          <Typography color="secondary" variant="subtitle2">
            {subtitle}
          </Typography>
        )}
      </Grid>
      {children}
    </Stack>
  );
};

export default Form;
