import { FC, forwardRef } from 'react';

import {
  AlertText,
  ClosedStyled,
  ClosedWrapper,
  IconStyled,
  IconWrapper,
  MessageWrapper,
} from './styles';
import { SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';

interface IToasty {
  alertMessage: SnackbarMessage;
  snackbarKey: SnackbarKey;
}

const Toasty: FC<IToasty> = forwardRef(
  ({ alertMessage, snackbarKey }, ref: any) => {
    const { closeSnackbar } = useSnackbar();

    if (typeof alertMessage === 'string') {
      return (
        <MessageWrapper ref={ref}>
          <IconWrapper>
            <IconStyled name="alert" />
          </IconWrapper>
          <AlertText component="span">{alertMessage}</AlertText>
          <ClosedWrapper>
            <ClosedStyled
              name="close"
              onClick={() => {
                closeSnackbar(snackbarKey);
              }}
            />
          </ClosedWrapper>
        </MessageWrapper>
      );
    } else {
      const { variant, message } = alertMessage as any;

      return (
        <MessageWrapper ref={ref}>
          <IconWrapper>
            <IconStyled name={variant === 'success' ? 'check' : 'alert'} />
          </IconWrapper>
          <AlertText component="span">{message}</AlertText>
          <ClosedWrapper>
            <ClosedStyled
              name="close"
              onClick={() => {
                closeSnackbar(snackbarKey);
              }}
            />
          </ClosedWrapper>
        </MessageWrapper>
      );
    }
  },
);

export default Toasty;
