import { GenderEnum } from '../infra/services/BeneficiaryService/dtos/BeneficiaryDetailDTO';

const handleGender = (gender?: GenderEnum, defaultReturn: string = '') => {
  const genders: Record<GenderEnum, string> = {
    F: 'Feminino',
    M: 'Masculino',
  };

  return gender ? genders[gender] : defaultReturn;
};

export default handleGender;
