import { DrawerFilterProvider } from '@hapvida/hapvida-core-components';

import { BackofficeUserListLayout } from './layout';
import { UserBackofficeListSubHeader } from './components/UserBackofficeListSubHeader';

export function BackofficeUserListPage() {
  return (
    <DrawerFilterProvider>
      <UserBackofficeListSubHeader />
      <BackofficeUserListLayout />
    </DrawerFilterProvider>
  );
}
