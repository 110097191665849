import FinancialService, { type Bill } from '@services/FinancialService';

import { downloadFiles } from './downloadFiles';

export const fetchAndDownloadMonthlyReports = async (
  selectedBills: Bill[],
  service: FinancialService,
  companyId: string | undefined,
  handleOpenFiles: (filesData: { path: string; fileName: string }[]) => void,
) => {
  const filterMonthlyAnalyticalReportResult = selectedBills?.flatMap(
    ({ analyticalStatements }) => analyticalStatements || [],
  );

  if (filterMonthlyAnalyticalReportResult?.length) {
    await downloadFiles?.(
      filterMonthlyAnalyticalReportResult,
      async item =>
        service.getNdiFile?.({
          companyId,
          fileName: item?.fileName,
        }),
      handleOpenFiles,
    );
  }
};
