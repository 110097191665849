import { styled as muiStyled } from '@mui/material/styles';

import Stack from '@mui/material/Stack';

interface PulsarAnimationProps {
  width: string;
  height: string;
  color: string;
}

export const PulsarAnimation = muiStyled(Stack)<PulsarAnimationProps>(
  ({ width, height, color }) => ({
    backgroundColor: color,

    width: width,
    height: height,

    borderRadius: '50px',
    animation: 'pulsate 1s infinite ease',

    '@keyframes pulsate': {
      from: {
        opacity: 1,

        width: width,
        height: height,
      },
      to: {
        opacity: 0,

        width: width,
        height: height,
      },
    },

    ':after': {
      content: '""',
      display: 'block',

      backgroundColor: color,
      borderRadius: '50px',

      width: width,
      height: height,
    },
  }),
);
