import { Stack, Typography } from '@mui/material';

import {
  Button,
  CircularProgress,
  TextInput,
} from '@hapvida/hapvida-core-components';

import { Container } from './styles';
import type { StartPasswordRecoveryFieldsProps } from './types';
import { ResendEmail } from './components/ResendEmail';

export function StartPasswordRecoveryFields({
  control,
  errors,
  handleSubmit,
  handleStartPasswordRecovery,
  loadingState,
  successState,
}: Readonly<StartPasswordRecoveryFieldsProps>) {
  const subtitle = successState
    ? 'Verifique sua caixa de entrada e spam, enviamos as instruções de como recuperar sua senha.'
    : 'Digite seu e-mail, enviaremos um link para definir uma nova senha.';

  return (
    <Container>
      <Stack flexGrow={1} gap={successState ? 4 : 6}>
        <Stack gap={4}>
          <Stack gap={2}>
            <Typography variant="h6">Recuperação de senha</Typography>
            <Typography variant="subtitle1" fontWeight={400}>
              {subtitle}
            </Typography>
          </Stack>
          {!successState && (
            <Stack gap={1}>
              <TextInput
                error={Boolean(errors?.email?.message)}
                errorMessage={errors?.email?.message}
                label="E-mail"
                placeholder="Digite seu e-mail"
                control={control}
                variant="filled"
                name="email"
                fullWidth
                type="email"
                required
              />
            </Stack>
          )}
        </Stack>
        {successState ? (
          <ResendEmail
            handleSubmit={handleSubmit}
            handleStartPasswordRecovery={handleStartPasswordRecovery}
            loadingState={loadingState}
          />
        ) : (
          <Button
            onClick={handleSubmit(handleStartPasswordRecovery)}
            variant="contained"
            color="success"
            disableRipple
            fullWidth
            disabled={loadingState}
          >
            <Stack alignItems="center" flexDirection="row" gap={0.5}>
              {loadingState ? (
                <>
                  Carregando <CircularProgress size={14} />
                </>
              ) : (
                'Recuperar senha'
              )}
            </Stack>
          </Button>
        )}
      </Stack>
    </Container>
  );
}
