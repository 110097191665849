import { BrowserRouter } from 'react-router-dom';

import { AppProviders } from '@providers';
import { AppRoutes } from '@routes';
import { handleCopyCleanCNPJToClipboard } from 'utils/handleCopyCleanCNPJToClipboard';

function App() {
  return (
    <div onCopy={handleCopyCleanCNPJToClipboard}>
      <BrowserRouter>
        <AppProviders>
          <AppRoutes />
        </AppProviders>
      </BrowserRouter>
    </div>
  );
}

export default App;
