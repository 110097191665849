import { COLORS } from '../../themes/colors';
import { Box, Tab, Typography } from '@mui/material';
import styled from 'styled-components';

export const Date = styled(Typography)`
  && {
    font-size: 12px;
    color: ${COLORS.MONOCHROMATIC.GRAY4};
  }
`;

export const StyledBox = styled(Box)`
  && {
    box-shadow: inset 0px 16px 16px rgba(0, 0, 0, 0.04);
    background: white;
    height: 300px;
    overflow: auto;
    padding: 24px;
    padding-top: 16px;
  }
`;

export const StyledTab = styled(Tab)`
  && {
    text-transform: none;
    color: ${COLORS.MONOCHROMATIC.GRAY4};
    align-self: flex-end;

    &.Mui-selected {
      p {
        color: ${COLORS.PRIMARY.BLUE.MAIN};
        font-weight: 700;
        align-self: flex-end;
      }
    }
  }
`;

export const QuantityFiles = styled(Typography)`
  && {
    background: ${COLORS.MONOCHROMATIC.GRAY1};
    padding: 2px 4px;
    border-radius: 2px;
    color: ${COLORS.MONOCHROMATIC.GRAY3};
  }
`;
