import styled from 'styled-components';
import { COLORS } from '../../themes/colors';

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
  background: ${COLORS.MONOCHROMATIC.GRAY1};
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 38px;
  box-sizing: border-box;
  background: ${COLORS.WHITE};
`;

export const Title = styled.h1`
  margin: 16px auto;
  font-size: 24px;
  color: ${COLORS.MONOCHROMATIC.GRAY4};
`;

export const Description = styled.p`
  margin: 0px;
  color: ${COLORS.MONOCHROMATIC.GRAY4};
`;
