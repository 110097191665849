import { MovementByScreenLayout } from './layout';
import { MovementByScreenSubHeader } from './components/MovementsSubHeader';

export function MovementByScreenPage() {
  return (
    <>
      <MovementByScreenSubHeader />
      <MovementByScreenLayout />
    </>
  );
}
