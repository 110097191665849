import { Stack, styled, TableCell } from '@mui/material';

export const StyledTableCell = styled(TableCell)`
  border-bottom: none;
`;

export const ColumnNameStack = styled(Stack)(({ onClick }) => ({
  width: 'fit-content',
  userSelect: 'none',
  cursor: !!onClick ? 'pointer' : 'default',
}));
