import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';

import { Loading, SummaryCardNew } from '@hapvida/hapvida-core-components';

import { Bill, BillListProps } from '@services/FinancialService';

import { useHandleDownload } from '@flows/private/financial/pages/List/hooks/useHandleDownload';
import { BillListRouter } from '@flows/private/financial/pages/List/router';

import { BillRow, EmptyBills } from './components';
import { useBillsSummaryState } from './hooks';

export function BillsSummary() {
  const { billsSummaryState } = useBillsSummaryState();
  const navigate = useNavigate();

  const emptyBillList: BillListProps = {
    bills: [],
    totalPerPage: 0,
    total: 0,
  };

  const { handleDownload } = useHandleDownload({
    billsList: billsSummaryState?.data ?? emptyBillList,
  });

  if (billsSummaryState?.isLoading) {
    return (
      <SummaryCardNew.Container>
        <Loading minHeight={200} alignItems="center" justifyContent="center" />
      </SummaryCardNew.Container>
    );
  }

  if (billsSummaryState?.isError) {
    return null;
  }

  const handleGoToBillList = () => {
    navigate(BillListRouter.path);
  };

  const summaryDate = new Date().toLocaleDateString('pt-BR', {
    year: 'numeric',
    month: 'short',
  });

  const { bills = [] } = billsSummaryState?.data ?? {};

  const isSummaryEmpty = bills.length === 0;

  return (
    <SummaryCardNew.Container>
      <SummaryCardNew.Header
        buttonProps={{
          content: 'Ver todas as faturas',
          onClick: handleGoToBillList,
          variant: 'outlined',
        }}
        subtitle={summaryDate.toUpperCase()}
        title="Resumo de faturas"
      />

      {isSummaryEmpty && <EmptyBills />}

      {!isSummaryEmpty && (
        <Stack gap={1.25}>
          {bills.map((bill: Bill) => (
            <BillRow
              key={bill.number}
              bill={bill}
              onDownloadBill={handleDownload}
            />
          ))}
        </Stack>
      )}
    </SummaryCardNew.Container>
  );
}
