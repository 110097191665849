import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';

import AuthService from '@services/AuthService';

import { toastifyApiErrors } from '@utils';

import { startPasswordRecoverySchema } from './validation';
import type { StartPasswordRecoveryFormProps } from './types';

export function useStartPasswordRecovery() {
  const { enqueueSnackbar } = useSnackbar();
  const { formState, control, handleSubmit } =
    useForm<StartPasswordRecoveryFormProps>({
      defaultValues: {
        email: '',
      },
      resolver: yupResolver(startPasswordRecoverySchema),
      mode: 'all',
    });

  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [successState, setSuccessState] = useState<boolean>(false);

  const authService = new AuthService();

  const handleStartPasswordRecovery = useCallback(
    async (formData: StartPasswordRecoveryFormProps) => {
      try {
        setLoadingState(true);
        const { email } = formData;

        await authService.requestPasswordRecovery({ email });

        setSuccessState(true);
      } catch (error) {
        toastifyApiErrors(error, enqueueSnackbar);
        setSuccessState(false);
      } finally {
        setLoadingState(false);
      }
    },
    [],
  );

  return {
    formState,
    control,
    handleSubmit,
    handleStartPasswordRecovery,
    loadingState,
    successState,
  };
}
