import chroma from 'chroma-js';
import styled, { css } from 'styled-components';
import { COLORS } from '../../themes/colors';
import Icon, { IconProps } from '../Icon';

export const Container = styled.div`
  width: 100%;
  border: 1px solid ${COLORS.PRIMARY.BLUE.MAIN};
  height: 48px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

interface ViewIconProps extends IconProps {
  activate: boolean;
}

export const ViewIcon = styled(({ activate, ...rest }: ViewIconProps) => (
  <Icon {...rest} />
))<ViewIconProps>`
  &:hover {
    cursor: pointer;
  }

  transition: color 0.25s;

  ${({ activate }) =>
    activate
      ? css`
          color: ${COLORS.PRIMARY.BLUE.MAIN};
          &:hover {
            cursor: default;
          }
        `
      : css`
          color: ${COLORS.MONOCHROMATIC.GRAY3};

          &:hover {
            color: ${chroma(COLORS.MONOCHROMATIC.GRAY3).darken().hex()};
          }
        `};
`;
