import type { TableHeadParams } from '@hapvida/hapvida-core-components/src/components/BaseTable/types';

enum CompanyListColumnNameEnum {
  COMPANY = 'company',
  CONTRACT_NUMBER = 'contractNumber',
  UNIT_CODE = 'unitCode',
  CUTOFF_DATE = 'cutoffDate',
  HEALTH_OPERATOR = 'healthOperator',
}

const companyListTableHeadColumns: TableHeadParams[] = [
  {
    id: 1,
    name: CompanyListColumnNameEnum.COMPANY,
    label: 'Nome da empresa/CNPJ',
    sortable: false,
  },
  {
    id: 2,
    name: CompanyListColumnNameEnum.CONTRACT_NUMBER,
    label: 'Nº Contrato',
    sortable: false,
  },
  {
    id: 3,
    name: CompanyListColumnNameEnum.UNIT_CODE,
    label: 'Cod Unidade',
    sortable: false,
  },
  {
    id: 4,
    name: CompanyListColumnNameEnum.CUTOFF_DATE,
    label: 'Data Corte',
    sortable: false,
  },
  {
    id: 5,
    name: CompanyListColumnNameEnum.HEALTH_OPERATOR,
    label: 'Operadora',
    sortable: false,
  },
];

export { companyListTableHeadColumns, CompanyListColumnNameEnum };
