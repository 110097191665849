import { styled as muiStyled } from '@mui/material/styles';

import Box from '@mui/material/Box';

import Typography from '../../Typography';

import { COLORS } from '../../../themes/colors';

export const Container = muiStyled(Box)({
  margin: '0px',
  padding: '60px 16px ',

  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',

  backgroundColor: COLORS.WHITE,

  borderRadius: '8px',
});

export const Message = muiStyled(Typography)(() => ({
  '&.MuiTypography-root': {
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '24px',
    lineHeight: '30px',
    textAlign: 'center',
    color: COLORS.MONOCHROMATIC.GRAY4,
  },
}));
