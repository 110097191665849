import { Stack, styled } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

const MainContent = styled(Stack)(() => ({
  width: '100vw',
  height: '100vh',
  flexDirection: 'row',
}));

const RightPanel = styled(Stack)(() => ({
  flex: 0.42,
}));

const LeftPanel = styled(Stack)(() => ({
  backgroundColor: COLORS.PRIMARY.BLUE['+2'],
  height: '100%',
  flex: 0.58,
}));

export { MainContent, RightPanel, LeftPanel };
