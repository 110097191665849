import { Box, styled as muiStyled, Stack, Typography } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

const Wrapper = muiStyled(Stack)({
  background: COLORS.WHITE,
  borderRadius: '8px',
  padding: '24px',
});

const Value = muiStyled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: '1rem',
  lineHeight: '1.5625rem',
}));

const Label = muiStyled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '0.750rem',
  lineHeight: '1.125rem',
}));

const IconWrapper = muiStyled(Box)(({ color }) => ({
  backgroundColor:
    color === 'red' ? 'rgba(150, 40, 0, 0.04)' : 'rgba(82, 135, 44, 0.04)',
  padding: 4,
  width: 32,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
}));

const Image = muiStyled('img')(() => ({
  width: 24,
}));

export { Wrapper, Value, Label, IconWrapper, Image };
