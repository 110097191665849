import React, { useEffect, useState } from 'react';
import { COLORS } from '../../themes/colors';

interface OrderColumnProps {
  order?: 'asc' | 'desc' | null;
}

const OrderColumn: React.FC<OrderColumnProps> = ({ order }) => {
  const [arrowColors, setArrowColors] = useState({
    up: COLORS.MONOCHROMATIC.GRAY3,
    down: COLORS.MONOCHROMATIC.GRAY3,
  });

  useEffect(() => {
    if (order === 'asc') {
      setArrowColors({
        down: COLORS.PRIMARY.ORANGE.MAIN,
        up: COLORS.MONOCHROMATIC.GRAY2,
      });
    } else if (order === 'desc') {
      setArrowColors({
        down: COLORS.MONOCHROMATIC.GRAY2,
        up: COLORS.PRIMARY.ORANGE.MAIN,
      });
    } else {
      setArrowColors({
        down: COLORS.MONOCHROMATIC.GRAY3,
        up: COLORS.MONOCHROMATIC.GRAY3,
      });
    }
  }, [order]);

  return (
    <svg
      style={{
        minHeight: 24,
        minWidth: 24,
      }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 13L12 18L17 13H7Z" fill={arrowColors.up} />
      <path d="M17 11L12 6L7 11L17 11Z" fill={arrowColors.down} />
    </svg>
  );
};

export default OrderColumn;
