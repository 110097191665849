import { Grid, Stack, styled as muiStyled } from '@mui/material';
import { COLORS } from '../../../themes/colors';

export const SideBar = muiStyled(Grid)(() => ({
  background: COLORS.PRIMARY.ORANGE.MAIN,
}));

export const SideBarContent = muiStyled(Stack)(() => ({
  padding: '16px',
  width: '100%',
}));
