import { useNavigate } from 'react-router-dom';

import { Sidebar as SidebarCore } from '@hapvida/hapvida-core-components';

import { useAuth } from '@hooks';
import { BackofficeUserListRouter } from '@flows/private/backofficeUser/pages/BackofficeUserList/router';
import { HomeRouter } from '@flows/private/home/router';
import { MovimentationStepRouter } from '@flows/private/movements/pages/MovimentationSteps/router';
import { MOVIMENTATION_STEPS_KEYS } from 'constants/private/movements/newMovementSteps';

import { ContractSelectionSteps } from '../ContractSelectionSteps';
import { SidebarRouteList } from '../SidebarRouteList';
import { MovementFlowSteps } from '../MovementFlowSteps';

export function SidebarContent() {
  const navigate = useNavigate();
  const { selectedCompany, selectedPartnerCompany } = useAuth();

  const isCompanyAndPartnerCompanySelected =
    Boolean(selectedCompany) && Boolean(selectedPartnerCompany);

  if (!isCompanyAndPartnerCompanySelected) {
    return <ContractSelectionSteps />;
  }

  const currentPath = window.location.pathname;

  const isFileMovementPath = Boolean(
    currentPath?.includes(
      MovimentationStepRouter.dynamicPath({
        movimentationStep: MOVIMENTATION_STEPS_KEYS.MOVIMENTATION_FILE,
      }),
    ),
  );
  const isConfirmationMovementPath = Boolean(
    currentPath?.includes(
      MovimentationStepRouter.dynamicPath({
        movimentationStep: MOVIMENTATION_STEPS_KEYS.CONFIRMATION,
      }),
    ),
  );

  const isNewMovementFlow = isFileMovementPath || isConfirmationMovementPath;

  if (isNewMovementFlow) {
    return <MovementFlowSteps />;
  }

  const routeIncludesBackofficeUsers = Boolean(
    currentPath?.includes(BackofficeUserListRouter.path),
  );

  const showBackToHome =
    routeIncludesBackofficeUsers && isCompanyAndPartnerCompanySelected;

  const navigateToHome = () => {
    navigate(HomeRouter.path);
  };

  if (showBackToHome) {
    return (
      <SidebarCore.Item
        iconName="chevron-left"
        onClick={navigateToHome}
        label="Voltar para Matriz"
      />
    );
  }

  return <SidebarRouteList />;
}
