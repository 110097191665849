import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Collapse } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { useFilterParams } from '@hooks';
import { BeneficiaryDetailRouter } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/router';
import { useBeneficiaryDetailsState } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/hooks';
import { PARAMS_ID } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/constants';

import { TableContainer, TableTitle } from './styles';
import { dependentsAggregatedListTableHeadColumns } from './constants';
import { getBeneficiaryDependentsAggregatedListTableRows } from './utils';

export function BeneficiaryDependentsAggregatedCard() {
  const { beneficiaryDependentsState } = useBeneficiaryDetailsState();

  const beneficiaryDependents = beneficiaryDependentsState.data;

  const { handleTableOrderChange, tableOrder } = useFilterParams({
    paramsId: PARAMS_ID.dependents,
  });

  const navigate = useNavigate();

  const handleEdit = useCallback(
    (id: string) => {
      navigate(
        BeneficiaryDetailRouter.dynamicPath({ beneficiaryId: id } as any),
      );
    },
    [navigate],
  );

  if (!beneficiaryDependentsState.isSuccess) {
    return null;
  }

  if (!beneficiaryDependents || beneficiaryDependents.length === 0) {
    return null;
  }

  const beneficiaryDependentsAggregatedListTableRows =
    getBeneficiaryDependentsAggregatedListTableRows(beneficiaryDependents);

  return (
    <TableContainer>
      <TableTitle>Dependentes e agregados</TableTitle>
      <Collapse
        showFooter={false}
        data={beneficiaryDependentsAggregatedListTableRows}
        TableHeadColumns={dependentsAggregatedListTableHeadColumns}
        onEdit={handleEdit}
        onOrderChange={handleTableOrderChange}
        tableOrder={tableOrder}
        rowColor={COLORS.MONOCHROMATIC.GRAY1}
      />
    </TableContainer>
  );
}
