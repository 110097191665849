import { DrawerFilter } from '@hapvida/hapvida-core-components';

import { companyStatusFilters } from './constants';

export function CompanyStatusDrawerFilter() {
  return (
    <DrawerFilter
      filterKey="status"
      label="status"
      title="Status da empresa"
      filters={companyStatusFilters}
      isMultiple={false}
    />
  );
}
