import { styled as muiStyled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { COLORS } from '../../themes/colors';

import Handle from './assets/handle.svg';

interface TextProps {
  uppercase?: boolean;
}

interface InnerStepsButtonProps {
  showHandle?: boolean;
}

interface InnerButtonContentProps {
  showHandle?: boolean;
}

export const StepperContainer = muiStyled(Box)(() => ({
  background: 'transparent',

  padding: '10px 20px 20px',

  display: 'flex',
  flexDirection: 'column',
}));

export const User = muiStyled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const Text = muiStyled(Typography)<TextProps>(({ uppercase }) => ({
  '&.MuiTypography-root': {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeigh: '0.9375rem',

    color: COLORS.MONOCHROMATIC.GRAY4,

    textTransform: uppercase ? 'uppercase' : 'none',
  },
}));

export const InnerStepsButton = muiStyled(Button, {
  shouldForwardProp: prop => prop !== 'showHandle',
})<InnerStepsButtonProps>(({ showHandle }) => ({
  '&.MuiButton-root': {
    fontSize: '0.875rem',
    background: 'transparent',

    '&::before': {
      content: showHandle ? 'url("' + Handle + '")' : '""',
      display: 'block',
      marginLeft: '-37px',
    },
  },
}));

export const InnerButtonContent = muiStyled(Box, {
  shouldForwardProp: prop => prop !== 'showHandle',
})<InnerButtonContentProps>(({ showHandle }) => ({
  fontWeight: 600,

  marginLeft: showHandle ? '8px' : '33px',
  marginTop: '7px',

  display: 'flex',
  alignItems: 'center',
}));

export const Dot = muiStyled(Box)(() => ({
  '&.MuiBox-root': {
    background: 'transparent',

    width: '1rem',
    height: '1rem',

    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',

    color: COLORS.MONOCHROMATIC.GRAY4,
  },
}));

interface DotIconProps {
  loading?: boolean;
}

export const DotIcon = muiStyled(Box)<DotIconProps>(({ loading }) => ({
  '&.MuiBox-root': {
    marginRight: '8px',

    background: COLORS.PRIMARY.BLUE.MAIN,

    width: '1.125rem',
    height: '1.125rem',

    borderRadius: '50%',

    display: 'flex',
    alignItems: loading ? 'center' : 'end',

    justifyContent: 'center',

    color: COLORS.WHITE,
  },
}));
