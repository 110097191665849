import { COLORS } from '../../../themes/colors';
import { Typography, styled as muiStyled, Box } from '@mui/material';

const Container = muiStyled(Box)({
  margin: '0px',
  padding: '60px 16px ',

  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',

  backgroundColor: COLORS.WHITE,

  borderRadius: '8px',
});

const Message = muiStyled(Typography)(() => ({
  '&.MuiTypography-root': {
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '24px',
    lineHeight: '30px',
    textAlign: 'center',
    color: COLORS.MONOCHROMATIC.GRAY4,
  },
}));

export { Message, Container };
