import { Icon } from '@hapvida/hapvida-core-components';

import type { IAppRoute } from 'routes/types';

import { MovementListRouter } from './pages/MovementList/router';
import { MovementByScreenRouter } from './pages/MovementByScreen/router';

export const MovementGroupRouter: IAppRoute = {
  Page: MovementListRouter.Page,
  path: MovementListRouter.path,
  name: 'Movimentações',
  isPrivate: true,
  Icon: <Icon name="clipboard-text-outline" />,
  subRoutes: [MovementByScreenRouter, MovementListRouter],
};
