import { TableBody } from '@mui/material';

import { BaseTable } from '@hapvida/hapvida-core-components';

import { useFilterParams } from '@hooks';

import { Columns } from '../../constants/columns';
import { TableRows } from './components';
import type { MovementListCardProps } from './types';

const COUNT_LENGTH_TABLE = 10;

export function MovementListCard({
  movementList,
}: Readonly<MovementListCardProps>) {
  const { handleTableOrderChange, handlePageChange, currentPage, tableOrder } =
    useFilterParams();

  const handleChangePage = (_event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  return (
    <BaseTable
      from={
        COUNT_LENGTH_TABLE * (currentPage - 1) + movementList.movements.length
      }
      count={Math.ceil(movementList.total / COUNT_LENGTH_TABLE)}
      onOrderChange={handleTableOrderChange}
      onPageChange={handleChangePage}
      tableContainerHeight="100%"
      TableHeadColumns={Columns}
      currentPage={currentPage}
      tableOrder={tableOrder}
      to={movementList.total}
      hasCellAction
      sx={{ padding: 0 }}
    >
      <TableBody>
        <TableRows movimentations={movementList.movements} />
      </TableBody>
    </BaseTable>
  );
}
