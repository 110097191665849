import { Button, Typography } from '@mui/material';

import {
  Icon,
  COLORS,
  useConfirmation,
} from '@hapvida/hapvida-core-components';

import { CircularProgress } from '@hapvida/hapvida-core-components/src/components/CircularProgress';

import { ReportStatusEnum } from '@flows/private/movements/pages/MovementDetails/components/MovementDetailsCard/constants/ReportStatusEnum';
import { MovementStatusEnum } from '@constants';

import { useExportMovementFile } from './hooks/useExportMovementFile';
import type { ExportButtonProps } from './types';

export function ExportButton({ movementDetail }: Readonly<ExportButtonProps>) {
  const initialReportStatus = movementDetail?.movementDetails?.reportStatus;

  const {
    isExportingMovement,
    handleExportMovementFile,
    reportStatusState,
    isReadyToDownloadState,
    loadingState,
  } = useExportMovementFile(initialReportStatus);

  const { showConfirmation } = useConfirmation();

  const isProcessingReport = reportStatusState === ReportStatusEnum?.PROCESSING;

  const showConfirmExport = () => {
    showConfirmation({
      title: 'Deseja exportar o relatório de movimentação?',
      icon: 'alert',
      type: 'default',
      confirmText: 'Exportar relatório',
      cancelText: 'Cancelar',
      onConfirm: handleExportMovementFile,
      content: (
        <Typography variant="text">
          Por causa do status dessa movimentação, ao exportar o relatório, ele
          pode ficar desatualizado, caso algum status de solicitação mude
          durante o processo de geração do relatório para download. Deseja
          continuar?
        </Typography>
      ),
    });
  };

  const exportButtonText = (() => {
    if (isExportingMovement || loadingState) {
      return 'Exportando';
    }

    if (isReadyToDownloadState) {
      return 'Baixar relatório';
    }

    return 'Exportar relatório';
  })();

  const buttonVariant = isReadyToDownloadState ? 'contained' : 'outlined';
  const buttonColor = isReadyToDownloadState ? 'success' : 'primary';
  const isProcessingStatus =
    movementDetail?.movementDetails?.status ===
    MovementStatusEnum?.PROCESS_FILE;

  const isLoading = isExportingMovement || isProcessingReport || loadingState;

  return (
    <Button
      onClick={
        isProcessingStatus && !isReadyToDownloadState
          ? showConfirmExport
          : handleExportMovementFile
      }
      disabled={isLoading}
      variant={buttonVariant}
      color={buttonColor}
      fullWidth
      endIcon={
        isLoading ? (
          <CircularProgress
            progressColor={COLORS?.MONOCHROMATIC?.GRAY4}
            size={16}
          />
        ) : (
          <Icon name="file-check" size={20} />
        )
      }
    >
      {exportButtonText}
    </Button>
  );
}
