import { Outlet } from 'react-router-dom';
import { Stack, ThemeProvider } from '@mui/material';

import { useCurrentThemeState } from '@hooks';

import { Header, Sidebar, SidebarModal } from './components';
import type { PrivateTemplateProps } from './types';

export function PrivateTemplate({
  hideSidebar,
  hideHeader,
}: Readonly<PrivateTemplateProps>) {
  const { currentTheme } = useCurrentThemeState();
  return (
    <Stack direction="row">
      <ThemeProvider theme={currentTheme}>
        {!hideSidebar && <Sidebar />}
      </ThemeProvider>
      <Stack overflow="auto" flex={1} component="main">
        {hideHeader ? <Outlet /> : <Header mainContent={<Outlet />} />}
        <SidebarModal />
      </Stack>
    </Stack>
  );
}
