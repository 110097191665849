import { useNavigate } from 'react-router-dom';

import type { IconName } from '@hapvida/hapvida-core-components';
import { SummaryCardNew, Loading } from '@hapvida/hapvida-core-components';

import { MovementListRouter } from '@flows/private/movements/pages/MovementList/router';
import {
  MOVEMENT_SUMMARY_ITEMS,
  BeneficiaryMovementTypeEnum,
} from '@constants';

import { EmptyMovements } from './components';
import { useMovementsSummaryState } from './hooks';

export type SummaryItens = {
  key: number | string;
  iconName?: IconName;
  color?: string;
  label: string;
};

export function MovementsSummary() {
  const { homeMovementsSummaryState } = useMovementsSummaryState();
  const navigate = useNavigate();

  if (homeMovementsSummaryState?.isLoading) {
    return (
      <SummaryCardNew.Container>
        <Loading minHeight={200} alignItems="center" justifyContent="center" />
      </SummaryCardNew.Container>
    );
  }

  if (homeMovementsSummaryState?.isError) {
    return null;
  }

  const handleGoToMovements = () => {
    navigate(MovementListRouter.path);
  };

  const summaryDate = new Date().toLocaleDateString('pt-BR', {
    month: 'short',
    year: 'numeric',
  });

  const summaryValues =
    homeMovementsSummaryState?.data.reduce((acc, movementSummary) => {
      if (
        Object.values(BeneficiaryMovementTypeEnum).includes(
          movementSummary.code,
        )
      ) {
        acc[movementSummary.code] = movementSummary.count;
      }
      return acc;
    }, {} as Record<BeneficiaryMovementTypeEnum, number>) ?? {};

  const isSummaryEmpty = Object.keys(summaryValues).length === 0;

  return (
    <SummaryCardNew.Container>
      <SummaryCardNew.Header
        buttonProps={{
          content: 'Ver todas as movimentações',
          onClick: handleGoToMovements,
        }}
        subtitle={summaryDate.toUpperCase()}
        title="Resumo de movimentações"
      />

      {isSummaryEmpty && <EmptyMovements />}

      {!isSummaryEmpty && (
        <SummaryCardNew.Content
          summary={MOVEMENT_SUMMARY_ITEMS}
          summaryValues={summaryValues}
          defaultValue="-"
          perLine={5}
        />
      )}
    </SummaryCardNew.Container>
  );
}
