const MOVIMENTATION_STEPS_KEYS = {
  MOVIMENTATION_FILE: 'arquivo',
  CONFIRMATION: 'confirmacao',
};

const MOVIMENTATION_STEPS_VALUES = {
  [MOVIMENTATION_STEPS_KEYS.MOVIMENTATION_FILE]: 0,
  [MOVIMENTATION_STEPS_KEYS.CONFIRMATION]: 1,
};

const MOVIMENTATION_STEPS_ORDER = [
  MOVIMENTATION_STEPS_KEYS.MOVIMENTATION_FILE,
  MOVIMENTATION_STEPS_KEYS.CONFIRMATION,
];

export {
  MOVIMENTATION_STEPS_KEYS,
  MOVIMENTATION_STEPS_VALUES,
  MOVIMENTATION_STEPS_ORDER,
};
