import Icon from '../../../../Icon';
import { COLORS } from '../../../../../themes/colors';

import { Container, ActionBox } from './styles';
import type { ActionCellProps } from './types';

export const ActionCellChild = ({ onEdit }: ActionCellProps) => {
  return (
    <Container>
      <ActionBox>
        <Icon
          onClick={onEdit}
          name="chevron-right"
          color={COLORS.MONOCHROMATIC.GRAY4}
          size={24}
        />
      </ActionBox>
    </Container>
  );
};
