import { COLORS } from '../../../../../themes/colors';
import type { StyledActionCellProps } from './types';
import {
  Box,
  IconButton,
  TableCell,
  Button,
  styled as muiStyled,
} from '@mui/material';

const StyledActionCell = muiStyled(TableCell)<StyledActionCellProps>(
  ({ collapsed, ithaserror, ithaserrormessage, showEditButton }) => ({
    background:
      collapsed === 'true' && ithaserror === 'false'
        ? COLORS.LINEAR_GRADIENT.GRAY1
        : ithaserror === 'true'
        ? COLORS.LINEAR_GRADIENT.GRAY2
        : showEditButton
        ? COLORS.MONOCHROMATIC.GRAY1
        : 'transparent',

    position: 'sticky',
    right: 0,
    padding: 0,

    borderBottom:
      ithaserror === 'true' && ithaserrormessage === 'false'
        ? `1px solid ${COLORS.MONOCHROMATIC.GRAY7}`
        : ithaserror === 'true' && ithaserrormessage === 'true'
        ? `2px solid ${COLORS.MONOCHROMATIC.GRAY7}`
        : `1px solid ${COLORS.MONOCHROMATIC.GRAY2}`,

    '&:hover': {
      background:
        ithaserror === 'false' &&
        showEditButton &&
        COLORS.LINEAR_GRADIENT.GRAY1,

      borderBottom:
        ithaserror === 'false' && `1px solid ${COLORS.PRIMARY.ORANGE.MAIN}`,
    },
  }),
);

const ActionBox = muiStyled(Box)(() => ({
  '&.MuiBox-root': {
    paddingRight: '20px',
    paddingLeft: '20px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
  },
}));

const CollapsedButton = muiStyled(Button)(() => ({
  '&.MuiButton-root': {
    width: '32px',
    height: '32px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const EditButton = muiStyled(IconButton)(() => ({
  '&.MuiIconButton-root': {
    border: `1px solid ${COLORS.MONOCHROMATIC.GRAY4}`,
    width: '32px',
    height: '32px',
    '&:hover': {
      backgroundColor: 'transparent',
      border: `1px solid ${COLORS.PRIMARY.ORANGE.MAIN}`,
      color: COLORS.PRIMARY.ORANGE.MAIN,
      cursor: 'pointer',
    },
  },
}));

export { EditButton, CollapsedButton, ActionBox, StyledActionCell };
