import { Stack, styled } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

import type { StepProps } from './types';

const StyledIcon = styled(Stack)<StepProps>(({ active }) => ({
  width: 24,
  height: 24,
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${COLORS.WHITE}`,

  ...(active && {
    background: COLORS.PRIMARY.ORANGE['-3'],
    border: `1px solid ${COLORS.PRIMARY.ORANGE['-3']}`,
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
  }),
}));

const StyledBox = styled(Stack)<StepProps>`
  & svg {
    color: ${COLORS.SECONDARY.GREEN.MAIN};
  }
`;

export { StyledIcon, StyledBox };
